import { Flex, Text } from '@mantine/core'
import ArrowDown from 'assets/icons/arrow_down.svg'
import ArrowUp from 'assets/icons/arrow_up.svg'
import { MaterialsFormGlobal } from 'components/Resources/Materials/Materials.types'
import { BrandsFormGlobal } from 'components/Resources/Materials/NestedPages/Brands/Brands.types'
import { MRT_SortingState } from 'mantine-react-table'
import React from 'react'
import { ArrElement, AutocompleteField, AutocompleteSubmitData, ExtractObjectCapitalizedKeys, FilterParam, FormBasedSliceName, MultiselectField, MultiselectFieldData, MultiselectFilterParam, SortParam } from 'types/inputs'
import { FilterRuleValue } from 'types/rules'

export const getFilterParam = (key: string, rule: FilterRuleValue, value?: string | boolean): FilterParam => {
    // const param = key.toLowerCase()
    const param = key
    return {
        param,
        rule,
        value,
    }
}

const isMultiselectFilterParam =
    (filter: FilterParam | MultiselectFilterParam): filter is MultiselectFilterParam => !!Array.isArray(filter.value)

export const getFilterParamString = ({ param, rule, value }: FilterParam | MultiselectFilterParam): string => {
    let result = ''

    if (isMultiselectFilterParam({ param, rule, value })) {
        (value as Array<string>).forEach((val, i) => {
            if (i === 0) {
                result += `${ param }:${ rule }:${ val }`
            } else {
                result += `&filter=${ param }:${ rule }:${ val }`
            }
        })
    } else {
        // SPECIAL CASE FOR TIME INTERVALS
        // if(rule === 'gte') {
        //     result += `${ param }:${ rule }:${ value?.toString()  }:AND`
        // } else {
        //     result = `${ param }:${ rule }:${ value?.toString() }`
        // }
        result = `${ param }:${ rule }:${ value?.toString() }`
    }

    return result
}

export const transformMRTSortDataToStorable = (sortArr: MRT_SortingState): SortParam[] => sortArr.map(s => ({
    param: s.id,
    rule: s.desc ? 'desc' : 'asc',
}))

export const getSortParamString = ({ param, rule }: SortParam) => `${ param }:${ rule }`

export const getFileNamesArray = (file: File[] | File | undefined) => Array.isArray(file) ? file.map(f => f.name) : file ? [file.name] : []

// TODO arrange autocomplete & multiselect fields to union type (to exclude difficulties in understanding)
export const autocompleteFieldDefault: AutocompleteField = {
    name: '',
    data: {
        id: '',
        name: '',
    },
}
export const multiselectFieldDefault: MultiselectField = {
    name: '',
    ids: [],
    alreadySelected: [],
}

export const autocompleteSubmitArg = (data: AutocompleteSubmitData) => ({
    name: data.value,
    data: { id: data.id, name: data.value },
})
export const autocompleteChangeArg = (label: string) => ({ name: label, data: { id: '', name: '' } })

export const returnUniqueMultiselectDataMap = (prev: MultiselectFieldData[], next: MultiselectFieldData[]) => [...new Map([...prev, ...next].map(item => [item.value, item])).values()]

const AutocompleteRightIcon = (isDropdownOpened: boolean) => isDropdownOpened ? <ArrowUp/> : <ArrowDown/>

export const comboboxRightSectionIconProps = ({ isOpened, onClose, onOpen }: {
    isOpened: boolean;
    onClose: () => void;
    onOpen: () => void
}) => (
    {
        rightSection: AutocompleteRightIcon(isOpened),
        onDropdownClose: onClose,
        onDropdownOpen: onOpen,
    }
)

export const
    InputViewParagraph = (fieldName: string, value: string | number | undefined | null, index: number, isKeyHidden?: boolean) => {
        const key = `${ index }_${ Math.random() }`

        return (
            <React.Fragment key={ key }>
                { !isKeyHidden &&
                    <>
                        { fieldName ?
                            <Flex direction={ 'column' } gap={ 2 }>
                                <Text sx={ (theme) => ({
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '16px',
                                    color: theme.colors.natural[5],
                                }) }>{ fieldName }</Text>
                                <Text sx={ (theme) => ({
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    color: theme.colors.natural[8],
                                }) }>
                                    { value || '-' }
                                </Text>
                            </Flex>
                            : <></> }
                    </>
                }
            </React.Fragment>
        )
    }

// *
// DEPRECATED OR NOT USED
// *

function getObjectUppercaseKeys<T extends Record<any, any>>(existingObject: T) {
    return Object.keys(existingObject).map((key) => `${ key[0].toUpperCase() }${ key.substring(1) }` as keyof ExtractObjectCapitalizedKeys<T>)
}

export const getGlobalFilterParamFunc = (sliceName: FormBasedSliceName) => {
    if (sliceName === 'materials') {
        const object: Partial<MaterialsFormGlobal> = {}
        const keys = getObjectUppercaseKeys(object)
        type Param = ArrElement<typeof keys>

        return (param: Param, rule: FilterRuleValue, value?: string): FilterParam => {
            const singularSliceName = sliceName.endsWith('s') ? sliceName.slice(0, -1) : sliceName
            // const paramResult = `${singularSliceName}.${param.toLowerCase()}`
            const paramResult = `${ singularSliceName }.${ param }`
            return {
                param: paramResult,
                rule,
                value,
            }
        }
    }
    if (sliceName === 'brands') {
        const object: Partial<BrandsFormGlobal> = {}
        const keys = getObjectUppercaseKeys(object)
        type Param = ArrElement<typeof keys>

        return (param: Param, rule: FilterRuleValue, value?: string): FilterParam => {
            const singularSliceName = sliceName.endsWith('s') ? sliceName.slice(0, -1) : sliceName
            // const paramResult = `${singularSliceName}.${param.toLowerCase()}`
            const paramResult = `${ singularSliceName }.${ param }`
            return {
                param: paramResult,
                rule,
                value,
            }
        }
    } else {
        console.error('not processed slice name (filters)')
    }
}
