import { UserProfileProps } from 'components/Worker/Profile/Profile.types.ts'
import { UserProfile } from 'components/AbstractComponents/UserProfile/UserProfile';
import { userDataType } from 'components/AbstractComponents/UserProfile/UserProfile.types';

export const UserAccount = ({ user }: UserProfileProps) => {

    const userData: userDataType = {
        name: user.name,
        phone: user?.phone || null,
        email: user.email,
        avatarId: user.avatar?.id,
        organizations: user.organizations.length ?
            user.organizations.map((organization) => organization.name) : null,
        department: user.department?.name,
        specializations: user.specializations.length ?
            user.specializations.map((specialization) => specialization.name) : null,
        type: user.type,
        compensationPercent: user.compensationPercent,
        compensationPercentUpdatedAt: user.compensationPercentUpdatedAt
    }

    return <UserProfile userId={user.id} isUserAccount={true} userData={userData}/>
}