import { autocompleteFieldDefault } from 'utils/inputs.tsx'
import { EditWorkerTaskStepDtoCustom, FindWorkerTaskResponseDtoCustom, TaskTabs, TaskTabsPath, WorkerTaskFormBody, WorkerTaskFormHeader, WorkerTasksFormGlobal } from './WorkerTask.types.ts'
import { FindProjectTaskResponseDto } from 'store/api/orderRoom.tsx'
import { UpdateProjectTaskRequestDto } from 'store/api/projectTasks.tsx'
import { CustomProjectSnippet } from './PageBody/Steps/WorkerSnippets.types.ts'
import { ProjectTaskStepsFindByTaskIdApiResponse } from 'store/api/projectTaskSteps.tsx'
import { convertWorkerTaskStepDtoToFormBody, isResultedStep } from './PageBody/Steps/WorkerSteps.utils.ts'
import { useLazyProjectTaskStepResultsFindQuery } from 'store/api/projectTaskStepResults.tsx'
import { useLazyProjectTaskSnippetsFindByStepIdQuery } from 'store/api/projectTaskSnippets.tsx'


// *
// DATA CONVERT FUNCS
// *

export const convertWorkerTaskDtoToFormBody = (task: FindWorkerTaskResponseDtoCustom): WorkerTaskFormBody => ({
    previous: task?.previous && { name: task.previous?.name, data: { name: task.previous?.name, id: task.previous?.id } } || workerTaskFormBodyInitialState.previous,
    workTime: task?.workTime || workerTaskFormBodyInitialState.workTime,
    status: task.status,
    // specialization: task?.specialization && { name: task.specialization?.name, data: { name: task.specialization?.name, id: task.specialization?.id } } || workerTaskFormBodyInitialState.specialization,
    work: task?.work && { name: task.work?.name, data: { name: task.work?.name, id: task.work?.id } } || workerTaskFormBodyInitialState.work,
    workInfo: task?.work && {
        from: task.work.from,
        to: task.work.to,
        performer: task.work.performer,
        performers: task.work.performers,
    } || undefined,
    steps: task?.steps || workerTaskFormBodyInitialState.steps,
    links: task?.links || workerTaskFormBodyInitialState.links,
    documents: task?.documents || workerTaskFormBodyInitialState.documents,
    // copiedTaskId: task.copiedTaskId || workerTaskFormBodyInitialState.copiedTaskId,
    // copiedMaterialsIds: task.copiedMaterialsIds || workerTaskFormBodyInitialState.copiedMaterialsIds,
    description: task?.description || workerTaskFormBodyInitialState.description,
})
export const convertWorkerTaskDtoToFormHeader = (task: FindProjectTaskResponseDto): WorkerTaskFormHeader => ({
    name: task?.name || workerTaskFormHeaderInitialState.name,
})
export const convertWorkerTaskFormToRequestDto = (
    data: Partial<WorkerTasksFormGlobal>
): UpdateProjectTaskRequestDto => ({
    name: data?.name || workerTaskFormHeaderInitialState.name,
    workTime: data?.workTime ? data?.workTime : 0,
    description: data?.description || workerTaskFormBodyInitialState.description,
    previousId:
            data?.previous?.data?.id ||
            data?.previous?.name ||
            workerTaskFormBodyInitialState.previous.data?.id,
    // specializationId:
    //         data?.specialization?.data?.id ||
    //         data?.specialization?.name ||
    //         taskFormBodyInitialState.specialization.data?.id,
    workId:
            data?.work?.data?.id ||
            data?.work?.name ||
            workerTaskFormBodyInitialState.work.data?.id,
})

export const workerTaskUtils = ({ snippet, stepId }: { snippet: CustomProjectSnippet, stepId: string }): CustomProjectSnippet & { stepId: string } =>
    ({
        ...snippet,
        errorMessage: snippet.errorMessage,
        stepId,
    })


// *
// TABS FUNCS
// *

export const taskTabs: TaskTabs = {
    steps: 'Шаги',
    comments: 'Комментарии',
    info: 'Общая информация',
    materials: 'Материалы',
}
export const getTaskActiveTab = (pathname: string): TaskTabsPath => pathname.includes('/steps')
    ? 'steps'
    :pathname.includes('/comments')
        ? 'comments'
        :pathname.includes('/info')
            ? 'info'
            :pathname.includes('/materials')
                ? 'materials'
                :'steps'


// *
// STORE
// *

export const workerTaskFormGlobalDefaultValues: WorkerTasksFormGlobal = {
    name: '',
    previous: autocompleteFieldDefault,
    // specialization: autocompleteFieldDefault,
    work: autocompleteFieldDefault,
    workTime: 0,
    steps: [],
    // copiedTaskId: null,
    // copiedMaterialsIds: [],
    description: '',
}
export const workerTaskFormHeaderInitialState: WorkerTaskFormHeader = {
    name: '',
}
export const workerTaskFormBodyInitialState: WorkerTaskFormBody = {
    previous: autocompleteFieldDefault,
    // specialization: autocompleteFieldDefault,
    work: autocompleteFieldDefault,
    workTime: 0,
    steps: [],
    // copiedTaskId: null,
    // copiedMaterialsIds: [],
    description: '',
}


// *
// REUSABLE HOOKS
// *

export const useFilledStepsWithSnippets = () => {
    const [
        findTaskStepResults,
        // {
        //     // data: stepResultsResponse,
        //     // isSuccess: isFindStepResultsSuccess,
        //     // isLoading: isFindStepResultsLoading,
        //     // isFetching: isFindStepResultsFetching,
        // },
    ] = useLazyProjectTaskStepResultsFindQuery()

    const [
        findSnippetsByStepId,
        // {
        //     // data: snippetsResponse,
        //     // isSuccess: isFindSnippetsSuccess,
        //     // isLoading: isFindSnippetsLoading,
        //     // isFetching: isFindSnippetsFetching,
        // },
    ] = useLazyProjectTaskSnippetsFindByStepIdQuery()

    const fillStepsWithSnippets = async (steps: ProjectTaskStepsFindByTaskIdApiResponse['data']): Promise<EditWorkerTaskStepDtoCustom[]> => {
        const customSteps: EditWorkerTaskStepDtoCustom[] =  [...steps]


        for (const [i, step] of steps.entries()) {
            await findSnippetsByStepId({ stepId: step.id })
                .unwrap()
                .then((snippetsResponse) => {
                    const customSnippets = snippetsResponse?.data
                        ?.map((snippet) =>
                            workerTaskUtils({
                                snippet,
                                stepId: step.id,
                            })
                        )
                        .sort((a, b) => a.sort - b.sort)
                    customSteps[i] = convertWorkerTaskStepDtoToFormBody({
                        ...step,
                        snippets: customSnippets,
                    })
                })
                .catch(console.error)
        }
      

        for (const [i, step] of customSteps.entries()) {
            if (isResultedStep(step.status)) {
                await findTaskStepResults(step.id)
                    .unwrap()
                    .then((findTaskStepResultsResponse) => {
                        if (step.snippets) {
                            const snippetsCopy = [...step.snippets]

                            snippetsCopy.forEach((s, i) => {
                                const lastResultIndex =
                                    findTaskStepResultsResponse.findIndex(
                                        (el) => el.snippet.id === s.id
                                    )

                                if (
                                    typeof lastResultIndex === 'number' &&
                                    lastResultIndex > -1
                                ) {
                                    if (s.type === 'PROJECT_CHECKBOX') {
                                        const lastResult =
                                            findTaskStepResultsResponse[lastResultIndex]
                                        const newSnippet = {
                                            ...lastResult.snippet,
                                            variants: s.variants
                                                ? [...s.variants].map((v) => {
                                                    if (
                                                        (lastResult.value as string[]).includes(
                                                            v.id
                                                        )
                                                    ) {
                                                        return { ...v, isSelected: true }
                                                    } else {
                                                        return v
                                                    }
                                                })
                                                : [],
                                        }
                                        snippetsCopy.splice(i, 1, newSnippet)
                                    }

                                    if (s.type === 'PROJECT_RADIO_BUTTON') {
                                        const lastResult =
                                            findTaskStepResultsResponse[lastResultIndex]
                                        const newSnippet = {
                                            ...lastResult.snippet,
                                            variants: s.variants
                                                ? [...s.variants].map((v) => {
                                                    if ((lastResult.value as string) === v.id) {
                                                        return { ...v, isSelected: true }
                                                    } else {
                                                        return v
                                                    }
                                                })
                                                : [],
                                        }
                                        snippetsCopy.splice(i, 1, newSnippet)
                                    }

                                    if (s.type === 'PROJECT_INPUT') {
                                        const lastResult =
                                            findTaskStepResultsResponse[lastResultIndex]
                                        const newSnippet = {
                                            ...lastResult.snippet,
                                            text: lastResult.value as string,
                                        }
                                        snippetsCopy.splice(i, 1, newSnippet)
                                    }

                                    if (s.type === 'PROJECT_FILES') {
                                        const lastResult =
                                            findTaskStepResultsResponse[lastResultIndex]
                                        const newSnippet: CustomProjectSnippet = {
                                            ...lastResult.snippet,
                                            filesIds: lastResult.value as string[],
                                            files: lastResult.files,
                                        }
                                        snippetsCopy.splice(i, 1, newSnippet)
                                    }
                                }
                            })

                            customSteps.splice(i, 1, {
                                ...customSteps[i],
                                snippets: snippetsCopy,
                            })
                        }
                    })
                    .catch(console.error)
            }
        }
        return [...customSteps].sort((a, b) => a?.sort - b?.sort)
    }

    return [fillStepsWithSnippets]
}


export const useGetIsBasicMobileLayout = (url: string) => {

    let res = true

    if (url.includes('materials')) {
        const regex = /materials([^\/].*)?$/
        res  =  regex.test(url)
    }

    return res

}