import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    DocumentWriteOffSliceInitialState,
} from 'components/Supply/DocumentWriteOff/DocumentWriteOff.types.ts';
import { createFormBasedSliceReducers } from 'utils/redux.ts';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { FindDocumentWriteOffResponseDto } from 'store/api/documentWriteOff.tsx';

const documentWriteOffSliceInitialState: DocumentWriteOffSliceInitialState = {
    ...defaultInitialState,
    documentWriteOffData: undefined,
    sortParamsArray: [
        {
            param: 'writeOff.createdAt',
            rule: 'desc',
        },
    ],
}

const reducers = createFormBasedSliceReducers<any>()

export const documentWriteOffSlice = createSlice({
    name: 'documentWriteOff',
    initialState: documentWriteOffSliceInitialState,
    reducers: {
        ...reducers,
        setDocumentWriteOffData:  (state: Draft<DocumentWriteOffSliceInitialState>, action: PayloadAction<FindDocumentWriteOffResponseDto>) => {
            state.documentWriteOffData = action.payload
        },
    }
}
)

export default documentWriteOffSlice.reducer