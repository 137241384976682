import { Flex, MantineTheme, Text } from '@mantine/core';
import { PerformerFinanceProjectSummary, PerformerFinanceSummary } from 'store/api/performer.tsx';
import {
    IconCheck,
    IconRefresh,
    IconInfoTriangle,
    IconPlayerPlay,
    IconClock,
    IconCurrencyRubel
} from '@tabler/icons-react'
import { Surface, Symbols } from 'recharts';

// *
// UI
// *

export type MoneyTabsPath =
    'projects'| 'charts'
export type MoneyTabs = { [key in MoneyTabsPath]: string }

// *
// TABS FUNCS
// *

export const moneyTabs: MoneyTabs = {
    projects: 'Проекты',
    charts: 'Статистика'
}

// *
// MONEY FORMATTER
// *

export const formatMoneyValue = (value: number ) => `${(Math.round(value * 100 + Number.EPSILON)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`

// *
// SUMMARY STATUS
// *

type ProjectSummaryStatus = 'PAYED' | 'PAY_TODAY' | 'NOT_CLOSED'

export type SummaryStatus = 'SUMMARY' | 'COMPENSATION' | 'BONUS' | 'PAYED' | 'PAY_TODAY' | 'NOT_CLOSED'

export const summaryStatusColor = (summaryStatus: SummaryStatus | ProjectSummaryStatus, theme: MantineTheme): string => {
    switch (summaryStatus) {
        case 'SUMMARY' : return theme.colors.natural[9]
        case 'COMPENSATION': return theme.colors.pink[0]
        case 'BONUS': return theme.colors.data[1]
        case 'PAYED': return theme.colors.success[5]
        case 'PAY_TODAY': return theme.colors.info[5]
        case 'NOT_CLOSED': return theme.colors.primary[5]
        default: return theme.colors.natural[5]
    }
}

// *
// WORK STATUS
// *

// workStatusColorsFinance is for profile's finance project list
// workStatusColors is used in many places through project, so it should not be here at all

export type WorkStatus = | 'PREPARATION' | 'WAIT' | 'DONE' | 'PROBLEM' | 'CONTROL' | 'REVISION' | 'PAID' | 'FINISHED' | null | undefined

export const workStatusColorsFinance = (workStatus: WorkStatus, theme: MantineTheme): { color: string, background: string } => {
    switch (workStatus) {
        case 'PREPARATION' : return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'WAIT': return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'DONE': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        case 'PROBLEM': return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'CONTROL': return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'REVISION': return{ color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'PAID': return { color: theme.colors.success[5], background:theme.fn.rgba(theme.colors.success[5], 0.2) }
        case 'FINISHED': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        default: return { color: theme.colors.natural[6], background:theme.fn.rgba(theme.colors.natural[6], 0.2) }
    }
}

export const workStatusColors = (workStatus: WorkStatus, theme: MantineTheme): { color: string, background: string } => {
    switch (workStatus) {
        case 'PREPARATION' : return { color: theme.colors.natural[6], background:theme.fn.rgba(theme.colors.natural[6], 0.2) }
        case 'WAIT': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        case 'CONTROL': return { color: theme.colors.warning[5], background:theme.fn.rgba(theme.colors.primary[3], 0.2) }
        case 'REVISION': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        case 'PROBLEM': return { color: theme.colors.red[5], background:theme.fn.rgba(theme.colors.red[5], 0.2) }
        case 'DONE': return { color: theme.colors.success[5], background:theme.fn.rgba(theme.colors.success[5], 0.2) }
        case 'PAID': return { color: theme.colors.warning[5], background:theme.fn.rgba(theme.colors.warning[5], 0.2) }
        case 'FINISHED': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        default: return { color: theme.colors.natural[6], background:theme.fn.rgba(theme.colors.natural[6], 0.2) }
    }
}

// *
// DATA CONVERSION
// *

export type GetSummaryDataReturnType = {
    id: SummaryStatus,
    title: string,
    data: {
        value: number,
        best?: number,
        compensation?: number,
        bonus?: number,
        bestBonus?: number,
        showCompensationInfo?: boolean,
    },
    popoverText?: string
    hide?: boolean,
    order: number,
}[]

export const getSummaryData = (data: PerformerFinanceSummary, showCompensationInfo: boolean): GetSummaryDataReturnType => [
    {
        id: 'SUMMARY',
        title: 'Целевой заработок',
        data: {
            value: data.summary,
        },
        order: 0,
    },
    {
        id: 'COMPENSATION',
        title: 'Амортизоция',
        data: {
            value: data.compensation,
        },
        popoverText: 'Надбавка за использование собственных инструментов. рассчитывается в виде процента от сметной (чистой) стоимости работ.',
        hide: !showCompensationInfo,
        order: 1,
    },
    {
        id: 'BONUS',
        title: 'Премия',
        data: {
            value: data.bonus,
            best: data.bestBonus,
        },
        popoverText: 'Надбавка за сдачу и приемку работ. Задержка в пределах 2 часов - хорошо. В пределах 24 часов - допустимо.',
        order: 2,
    },
    {
        id: 'PAYED',
        title: 'Оплачено',
        data: {
            value: data.payed,
            compensation: data.payedCompensation,
            bonus: data.payedBonus,
            bestBonus: data.payedBestBonus,
            showCompensationInfo: showCompensationInfo,
        },
        popoverText: 'Общая стоимость оплаченных работ, которые были сданы работником и приняты прорабом в этом месяце.',
        order: 3,
    },
    {
        id: 'PAY_TODAY',
        title: 'К выплате',
        data: {
            value: data.payToday,
            compensation: data.payTodayCompensation,
            bonus: data.payTodayBonus,
            bestBonus: data.payTodayBestBonus,
            showCompensationInfo: showCompensationInfo,
        },
        popoverText: 'Общая стоимость работ, которые были сданы работником и приняты прорабом в этом месяце. Они будут оплачены при расчете за этот месяц.',
        order: 4,
    },
    {
        id: 'NOT_CLOSED',
        title: 'Не завершено',
        data: {
            value: data.notClosed,
            compensation: data.notClosedCompensation,
            bestBonus: data.notClosedBestBonus,
            showCompensationInfo: showCompensationInfo,
        },
        popoverText: 'Общая стоимость работ, которые еще не выполнены или не приняты, но запланированы на этот месяц.',
        order: 5,
    }
]

export type GetProjectSummaryType = {
    id: ProjectSummaryStatus,
    title: string,
    data: number,
    order: number
}[]

export const getProjectSummary = (data: PerformerFinanceProjectSummary): GetProjectSummaryType => [
    {
        id: 'PAYED',
        title: 'Оплачено',
        data: data.payed,
        order: 0
    },
    {
        id: 'PAY_TODAY',
        title: 'К выплате ',
        data: data.payToday,
        order: 1
    },
    {
        id: 'NOT_CLOSED',
        title: 'Не завершено',
        data: data.notClosed,
        order: 2
    }
]

export const getWorkStatusDataFinance = (status: WorkStatus) => {
    switch(status) {
        case 'PREPARATION': return { title: 'На подготовке', icon: <IconClock size={ '12px' }/> }
        case 'FINISHED': return { title: 'Завершена', icon: <IconCheck size={ '12px' }/> }
        case 'WAIT': return { title: 'Ждёт выполнения', icon: <IconPlayerPlay size={ '12px' }/> }
        case 'CONTROL': return { title: 'Ждёт контроля', icon: <IconRefresh size={ '12px' }/> }
        case 'REVISION': return { title: 'На доработке', icon: <IconRefresh size={ '12px' }/> }
        case 'PROBLEM': return { title: 'Инцидент', icon: <IconInfoTriangle size={ '12px' }/> }
        case 'DONE': return { title: 'Завершена', icon: <IconCheck size={ '12px' }/> }
        case 'PAID': return { title: 'Оплаченно', icon: <IconCurrencyRubel size={ '12px' }/> }
        default: return { title: 'На подготовке', icon: <IconClock size={ '12px' }/> }
    }
}

export const renderCustomLabel = (props: any) => {
    const { payload } = props
    const filteredPayload = payload.filter((item: any) => item.value !== '' && item.value !== 'noData')
    return <Flex gap={4} justify={filteredPayload.length === 1 ? 'center' : 'space-between'}>
        {
            filteredPayload.map((entry: any, index: number) =>
                <Flex key={`section-${index}`} direction="column" align="center" justify={'end'}>
                    <Text color="natural.5" sx={() => ({ textAlign: 'center' })}>{entry.value}</Text>
                    <Flex align="center" justify="center">
                        <Surface width={16} height={16}>
                            <Symbols cx={8} cy={8} type="circle" size={50} fill={entry.color}/>
                        </Surface>
                        <Text color="natural.6"
                            fs="lg">{`${Math.round(entry.payload.percent * 100 + Number.EPSILON)}%`}</Text>
                    </Flex>
                </Flex>
            )
        }
    </Flex>
}

export const renderCustomizedPieSliceLabel = (props: any) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius - 15) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent < 0.01 || percent > 0.99) return ''
    else return <text key={`profit-pie-slice-text-${index}`} fontSize={10} x={x} y={y} fill="white"
        textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
    </text>
}
        