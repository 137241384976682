import { autocompleteFieldDefault } from 'utils/inputs.tsx';
import {
    MaterialsAnalogueFormBody, MaterialsAnalogueFormHeader
} from 'components/Resources/Materials/PageBody/Analogues/MaterialsAnalogue.types.ts';

export const materialAnalogueFormHeaderInitialState: MaterialsAnalogueFormHeader = {
    name: '',
}

export const materialAnalogueFormBodyInitialState: MaterialsAnalogueFormBody = {
    sku: '',
    store: autocompleteFieldDefault,
    inStock: 0
}
