import { Navigate,  RouteObject } from 'react-router-dom';

export const FINANCE_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={ 'works_contractors' } />,
    },
    {
        id: 'works_contractors',
        path: 'works_contractors',
        loader: () => ({
            name: 'Работы и подрядчики',
            path: '/works_contractors'
        }),
        children: [
            {
                index: true,
                async lazy() {
                    const { ContractorsPage } = await import('components/Finance/Contractors/ContractorsPage.tsx')
                    return { element: <ContractorsPage/> }
                },
            },
        ]
    },
]
