import { MaterialsPacksFindByIdApiResponse } from 'store/api/materialPacks'
import { MaterialsParametersFindByMaterialIdApiResponse } from 'store/api/materialParameters'
import { CreateMaterialRequestDto, FindMaterialResponseDto } from 'store/api/materials'
import { AutocompleteField, AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import {
    MaterialBodyInfoAutocompleteKey,
    MaterialFormBody,
    MaterialFormBodyInfo,
    MaterialFormHeader,
    MaterialsAutocompleteKey,
    MaterialsFormGlobal,
    MaterialTabs,
    MaterialTabsPath,
} from './Materials.types'
import { convertMaterialParamDtoToFormGlobal } from 'components/Resources/Materials/PageBody/FormParts/Params/MaterialParam.utils.ts'
import { MaterialParamFormGlobal } from './PageBody/FormParts/Params/MaterialParam.types'


// *
// DATA CONVERT FUNCS
// *

export const convertMaterialDtoToFormHeader = (material: FindMaterialResponseDto): MaterialFormHeader => ({
    name: material?.name || materialFormHeaderInitialState.name,
})
export const convertMaterialDtoToFormBody = (material: FindMaterialResponseDto & {
    params?: MaterialsParametersFindByMaterialIdApiResponse['data'],
    packs?: MaterialsPacksFindByIdApiResponse['data'],
}): {
    images: string[];
    package: AutocompleteField;
    isAccountable: boolean | undefined;
    discountPrice: number | null | undefined;
    description: string | undefined;
    store: AutocompleteField;
    type: AutocompleteField;
    params: Partial<MaterialParamFormGlobal>[] | undefined;
    isChecked: boolean | undefined;
    url: string | undefined;
    price: number | undefined;
    category: AutocompleteField;
    sku: string | null | undefined;
    brand: AutocompleteField;
    updatedAt: any
} => ({
    brand: { name: material?.brand?.name, data: { id: material?.brand?.id, name: material?.brand?.name } },
    category: { name: material?.category?.name, data: { id: material?.category?.id, name: material?.category?.name } },
    type: { name: material?.type?.name, data: { id: material?.type?.id, name: material?.type?.name } },
    package: { name: material?.package?.name, data: { id: material?.package?.id, name: material?.package?.name } },
    description: material?.description,
    price: material?.price,
    discountPrice: material?.discountPrice,
    images: material?.images || [],
    // name: material?.name,
    // uid: material?.uid,
    sku: material?.sku,
    url: material?.url,
    params: material?.params?.map(convertMaterialParamDtoToFormGlobal),
    updatedAt: material.updatedAt,
    // packs: material?.packs,
    isAccountable: material?.isAccountable,
    isChecked: material?.isChecked,
    store: { name: material?.store?.name, data: { id: material?.store?.id, name: material?.store?.name } },
    storeParser: material?.store?.parser,
})
export const convertMaterialFormToRequestDto = (data: MaterialsFormGlobal): CreateMaterialRequestDto => ({
    // uid: data.uid,
    sku: data.sku ? data.sku : undefined,
    name: data.name,
    description: data.description,
    url: data.url,
    price: data.price,
    discountPrice: data.discountPrice,
    images: data.images,
    categoryId: data.category?.data?.id ? data.category?.data?.id:data.category.name,
    brandId: data.brand?.data?.id ? data.brand?.data?.id:data.brand.name,
    typeId: data.type?.data?.id ? data.type?.data?.id:data.type.name,
    packageId: data.package?.data?.id ? data.package?.data?.id:data.package.name,
    isChecked: data.isChecked,
    isAccountable: data.isAccountable,
    storeId: data.store?.data?.id ? data.store?.data?.id:data.store.name,
    uid: data.uid ? data.uid : undefined,
    // packageId: data.package?.data?.id ? data.package?.data?.id : '123',
})


// *
// TABS FUNCS
// *

export const materialTabs: MaterialTabs = {
    info: 'Общая информация',
    stores: 'Магазины',
    analogues: 'Аналоги',
}
export const getMaterialActiveTab = (pathname: string): MaterialTabsPath => pathname.includes('/info') ? 'info':
    pathname.includes('/stores') ? 'stores':
        pathname.includes('/analogues') ? 'analogues' : 'info'


// *
// INPUT PROCESSING FUNCS
// *

export const onMaterialAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<MaterialsAutocompleteKey, MaterialsFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onMaterialAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<MaterialsAutocompleteKey, MaterialsFormGlobal>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

export const onMaterialBodyInfoAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<MaterialBodyInfoAutocompleteKey, MaterialFormBodyInfo>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onMaterialBodyInfoAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<MaterialBodyInfoAutocompleteKey, MaterialFormBodyInfo>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

// export const onMaterialBodyStoresAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<MaterialBodyStoresAutocompleteKey, MaterialFormBodyStores>) => (data: AutocompleteSubmitData) => {
//     setFormValue(key, autocompleteSubmitArg(data))
// }
// export const onMaterialBodyStoresAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<MaterialBodyStoresAutocompleteKey, MaterialFormBodyStores>) => (label: string) => {
//     if (formData[key]?.name !== label) {
//         setFormValue(key, autocompleteChangeArg(label))
//     }
// }

// export const onMaterialMultiselectChange = ({formData, key, setFormValue}: InputChangeArg<MaterialMultiselectFilterParamKey, MaterialsFormGlobal>) => (label: string) => {
//     if (formData[key]?.name !== label) {
//         setFormValue(`${key}.name`, label)
//     }
// }

// export const onMaterialMultiselectSubmit = ({key, setFormValue}: InputSubmitArg<MaterialMultiselectFilterParamKey, MaterialsFormGlobal>) => (valueIds: Array<string>) => {
//     setFormValue(`${key}.id`, valueIds)
// }


// *
// STORE
// *

export const materialsFormGlobalDefaultValues: MaterialsFormGlobal = {
    name: '',
    brand: autocompleteFieldDefault,
    store: autocompleteFieldDefault,
    category: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    package: autocompleteFieldDefault,
    description: '',
    images: [],
    price: 0,
    discountPrice: 0,
    sku: '',
    url: '',
    imagesUrls: [],
    isAccountable: false,
    isChecked: false,
    variants: [],
    params: [],
    uid: '',
    updatedAt: '',
    storeParser: null,
    // packageId: '',
    // typeId: '',
    // uid: '',
    // date: '',
    // package: '',
}

export const materialFormHeaderInitialState: MaterialFormHeader = {
    name: '',
}

export const materialsFormBodyInitialState: MaterialFormBody = {
    brand: autocompleteFieldDefault,
    store: autocompleteFieldDefault,
    category: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    package: autocompleteFieldDefault,
    description: '',
    images: [],
    price: 0,
    discountPrice: 0,
    sku: '',
    url: '',
    imagesUrls: [],
    isAccountable: false,
    isChecked: false,
    variants: [],
    params: [],
    uid: '',
    updatedAt: '',
    storeParser: null,
    // packageId: '',
    // typeId: '',
    // uid: '',
    // date: '',
    // package: '',
}
