import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['ProjectWorks'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            projectWorksFind: build.query<
                ProjectWorksFindApiResponse,
                ProjectWorksFindApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/works',
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ProjectWorks'],
            }),
            projectWorksFindByProject: build.query<
                ProjectWorksFindByProjectApiResponse,
                ProjectWorksFindByProjectApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/project/${queryArg}`,
                }),
                providesTags: ['ProjectWorks'],
            }),
            projectWorksFindWorkPlanningByProject: build.query<
                ProjectWorksFindWorkPlanningByProjectApiResponse,
                ProjectWorksFindWorkPlanningByProjectApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/planning/work/project/${queryArg.projectId}`,
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ProjectWorks'],
            }),
            projectWorksFindForReportByProject: build.query<
                ProjectWorksFindForReportByProjectApiResponse,
                ProjectWorksFindForReportByProjectApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/report/project/${queryArg.projectId}`,
                    params: {
                        isDate: queryArg.isDate,
                        from: queryArg['from'],
                        to: queryArg.to,
                    },
                }),
                providesTags: ['ProjectWorks'],
            }),
            projectWorksFindOne: build.query<
                ProjectWorksFindOneApiResponse,
                ProjectWorksFindOneApiArg
            >({
                query: (queryArg) => ({ url: `/api/v1/projects/works/${queryArg}` }),
                providesTags: ['ProjectWorks'],
            }),
            projectWorkUpdate: build.mutation<
                ProjectWorkUpdateApiResponse,
                ProjectWorkUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.updateWorkRequestDto,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkDelete: build.mutation<
                ProjectWorkDeleteApiResponse,
                ProjectWorkDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/${queryArg}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkCreate: build.mutation<
                ProjectWorkCreateApiResponse,
                ProjectWorkCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/job/${queryArg.jobId}`,
                    method: 'POST',
                    body: queryArg.createWorkRequestDto,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkCreateWithJobGroup: build.mutation<
                ProjectWorkCreateWithJobGroupApiResponse,
                ProjectWorkCreateWithJobGroupApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/job-group/${queryArg.jobGroupId}`,
                    method: 'POST',
                    body: queryArg.createWorkRequestDto,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkCreateBatch: build.mutation<
                ProjectWorkCreateBatchApiResponse,
                ProjectWorkCreateBatchApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/works/job',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkRoomCopyRoom: build.mutation<
                ProjectWorkRoomCopyRoomApiResponse,
                ProjectWorkRoomCopyRoomApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/works/copy',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkUpdateBatch: build.mutation<
                ProjectWorkUpdateBatchApiResponse,
                ProjectWorkUpdateBatchApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/works/batch',
                    method: 'PUT',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkUpdateStatuses: build.mutation<
                ProjectWorkUpdateStatusesApiResponse,
                ProjectWorkUpdateStatusesApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/works/update-statuses',
                    method: 'PUT',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
            projectWorkUpdateLocal: build.mutation<
                ProjectWorkUpdateLocalApiResponse,
                ProjectWorkUpdateLocalApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/works/${queryArg.id}/local`,
                    method: 'PUT',
                    body: queryArg.updateWorkRequestDto,
                }),
                invalidatesTags: ['ProjectWorks'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as projectWorksApi };
export type ProjectWorksFindApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindWorkResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type ProjectWorksFindApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ProjectWorksFindByProjectApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindWorkResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type ProjectWorksFindByProjectApiArg = string;
export type ProjectWorksFindWorkPlanningByProjectApiResponse =
  /** status 200  */ {
      metadata: {
          id: string;
          permissions: string[];
      }[];
      data: FindWorkPlanningResponseDto;
  };
export type ProjectWorksFindWorkPlanningByProjectApiArg = {
    projectId: string;
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ProjectWorksFindForReportByProjectApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindWorkReportResponseDto;
};
export type ProjectWorksFindForReportByProjectApiArg = {
    projectId: string;
    isDate: boolean;
    from: string;
    to: string;
};
export type ProjectWorksFindOneApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindWorkResponseDto;
};
export type ProjectWorksFindOneApiArg = string;
export type ProjectWorkUpdateApiResponse =
  /** status 200  */ FindWorkResponseDto;
export type ProjectWorkUpdateApiArg = {
    id: string;
    updateWorkRequestDto: UpdateWorkRequestDto;
};
export type ProjectWorkDeleteApiResponse = /** status 200  */ void;
export type ProjectWorkDeleteApiArg = string;
export type ProjectWorkCreateApiResponse =
  /** status 201  */ FindWorkResponseDto;
export type ProjectWorkCreateApiArg = {
    jobId: string;
    createWorkRequestDto: CreateWorkRequestDto;
};
export type ProjectWorkCreateWithJobGroupApiResponse =
  /** status 201  */ FindWorkResponseDto[];
export type ProjectWorkCreateWithJobGroupApiArg = {
    jobGroupId: string;
    createWorkRequestDto: CreateWorkRequestDto;
};
export type ProjectWorkCreateBatchApiResponse =
  /** status 201  */ FindWorkResponseDto[];
export type ProjectWorkCreateBatchApiArg = CreateWorkBatchRequestDto[];
export type ProjectWorkRoomCopyRoomApiResponse =
  /** status 201  */ FindWorkResponseDto[];
export type ProjectWorkRoomCopyRoomApiArg = CopyRoomRequestDto[];
export type ProjectWorkUpdateBatchApiResponse =
  /** status 200  */ FindWorkResponseDto[];
export type ProjectWorkUpdateBatchApiArg = UpdateWorkBatchRequestDto[];
export type ProjectWorkUpdateStatusesApiResponse = unknown;
export type ProjectWorkUpdateStatusesApiArg = UpdateWorkStatusRequestDto;
export type ProjectWorkUpdateLocalApiResponse =
  /** status 200  */ FindWorkResponseDto;
export type ProjectWorkUpdateLocalApiArg = {
    id: string;
    updateWorkRequestDto: UpdateWorkRequestDto;
};
export type FindWorkPartResponseDto = {
    from: string;
    to: string;
    id: string;
    size: number;
    row?: number;
    isResized?: boolean | null;
    isMoved?: number | null;
};
export type JobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindMaterialTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    jobType: JobTypeResponseDto;
};
export type FindJobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    materialType: FindMaterialTypeResponseDto;
};
export type FindPackageResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindJobViewResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindWorkStageResponseDto = {
    id: string;
    name: string;
    description: string;
    sort: number;
};
export type FindJobSurfaceResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindClientKindResponseDto = {
    id: string;
    name: string;
};
export type FindClientTaxationSystemResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindDepartmentResponseDto = {
    id: string;
    name: string;
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindRolePermissionDto = {
    id: string;
    name: string;
    description: string;
    allow: boolean;
};
export type FindRolePermissionGroupDto = {
    name: string;
    permissions: FindRolePermissionDto[];
};
export type FindRoleResponseDto = {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
    type: 'MANAGER' | 'WORKER';
    organization: FindOrganizationResponseDto;
    permissions: FindRolePermissionGroupDto[];
};
export type FindUserResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    email: string;
    phone: string | null;
    job: string | null;
    chatId: number | null;
    deleted: boolean;
    compensationPercent: number;
    compensationPercentUpdatedAt: string | null;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    department: FindDepartmentResponseDto;
    specializations: FindSpecializationResponseDto[];
    avatar: FindFileResponseDto;
    roles: FindRoleResponseDto[];
    organizations: FindOrganizationResponseDto[];
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    workingDays: (number[][] | (string | null))[];
};
export type FindClientContactResponseDto = {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    position: string;
    phone: string | null;
    email: string | null;
    website: string | null;
    company: string | null;
    note: string | null;
};
export type FindClientBankResponseDto = {
    id: string;
    name: string;
    abbreviatedName: string;
    bik: string;
    ks?: string | null;
    index?: string | null;
    city?: string | null;
    address?: string | null;
    phone?: string | null;
    okato?: string | null;
    okpo?: string | null;
    regNum?: string | null;
};
export type FindAccountItemResponseDto = {
    id: string;
    number: string;
    bank: FindClientBankResponseDto;
};
export type FindClientTypeResponseDto = {
    id: string;
    name: string;
};
export type FindClientResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    name: string;
    middleName: string | null;
    registrationAddress: string | null;
    birthDate: string | null;
    passportNumber: number | null;
    passportIssuer: string | null;
    passportIssueDate: string | null;
    passportIssuingUnitCode: string | null;
    fullName: string | null;
    abbreviatedName: string | null;
    legalAddress: string | null;
    inn: string;
    kpp: string | null;
    ogrn: string | null;
    okved: string | null;
    okpo: string | null;
    okato: string | null;
    deleted: boolean;
    kind: FindClientKindResponseDto;
    taxationSystem: FindClientTaxationSystemResponseDto;
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    contact: FindClientContactResponseDto;
    account: FindAccountItemResponseDto;
    types: FindClientTypeResponseDto[];
    organization: FindOrganizationResponseDto;
    contacts: FindClientContactResponseDto[];
    accounts: FindAccountItemResponseDto[];
};
export type FindObjectResponseDto = {
    id: string;
    name: string;
    deleted: boolean;
    address: string;
    fullAddress: string;
    city: string;
    street: string;
    corpus: string;
    building: string;
    house: string;
    entrance: number;
    floor: number;
    apartment: number;
    intercomCode: string;
    comment: string;
    longitude: number;
    latitude: number;
    client: FindClientResponseDto;
};
export type FindRoomResponseDto = {
    id: string;
    name: string;
    square: number;
    deleted: boolean;
    object: FindObjectResponseDto;
    files: FindFileResponseDto[];
};
export type FormulaCalculateRequestDto = {
    id: string;
    name: string;
    colour: string;
    operator?: string | null;
    type?:
    | (
        | 'PLUS'
        | 'MINUS'
        | 'MULTIPLY'
        | 'DIVIDE'
        | 'MATH_FLOOR'
        | 'MATH_CEIL'
        | 'MATH_ROUND'
        | 'MATH_SQRT'
        | 'NUMBER_SQUARED'
        | 'NUMBER_POWER'
        | 'CONDITION'
        | 'LEFT_PARENTHESIS'
        | 'RIGHT_PARENTHESIS'
        | 'MORE'
        | 'LESS'
      )
    | null;
    value?:
    | (
        | number
        | FormulaCalculateRequestDto
        | (number | FormulaCalculateRequestDto)[]
        | (number | FormulaCalculateRequestDto)[][]
      )
    | null;
};
export type WorkFormulaCalculateRequestDto = {
    id: string;
    name: string;
    colour: string;
    operator?: string | null;
    type?:
    | (
        | 'PLUS'
        | 'MINUS'
        | 'MULTIPLY'
        | 'DIVIDE'
        | 'MATH_FLOOR'
        | 'MATH_CEIL'
        | 'MATH_ROUND'
        | 'MATH_SQRT'
        | 'NUMBER_SQUARED'
        | 'NUMBER_POWER'
        | 'CONDITION'
        | 'LEFT_PARENTHESIS'
        | 'RIGHT_PARENTHESIS'
        | 'MORE'
        | 'LESS'
      )
    | null;
    value?:
    | (
        | number
        | FormulaCalculateRequestDto
        | (number | FormulaCalculateRequestDto)[]
        | (number | FormulaCalculateRequestDto)[][]
      )
    | null;
};
export type FindFormulaTypeResponseDto = {
    id: string;
    name: string;
    colour: string;
    type:
    | 'CONSTANT'
    | 'MATERIAL_PARAMETER'
    | 'MATERIAL_COUNT'
    | 'SPECIAL'
    | 'OPERATION'
    | 'LEGKOREM';
    description: string;
};
export type FindMaterialStocksResponseDto = {
    id: string;
    title: string;
    remainsAmount: number;
};
export type FindCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
};
export type FindStoreResponseDto = {
    id: string;
    name: string;
    url: string | null;
    parser: string | null;
};
export type FindStoreCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
    store: FindStoreResponseDto;
};
export type FindBrandResponseDto = {
    id: string;
    name: string;
    url: string | null;
    image: FindFileResponseDto | null;
};
export type FindInventoryTypeResponseDto = {
    id: string;
    name: string;
    type: 'MATERIAL' | 'PRODUCT';
};
export type FindMaterialPackResponseDto = {
    id: string;
    packing: number;
    unitMeasure: string;
    price: number;
    tradePrice: number;
    default: boolean;
    proportion: number;
};
export type FindMaterialResponseDto = {
    id: string;
    updatedAt: string;
    sku: string | null;
    name: string;
    description: string;
    url: string;
    price: number;
    discountPrice: number | null;
    isChecked: boolean;
    isAvailable: boolean;
    images: string[];
    isAccountable: boolean;
    stocks: FindMaterialStocksResponseDto[];
    category: FindCategoryResponseDto;
    storeCategory: FindStoreCategoryResponseDto;
    store: FindStoreResponseDto;
    brand: FindBrandResponseDto;
    type: FindMaterialTypeResponseDto;
    package: FindPackageResponseDto;
    inventoryType: FindInventoryTypeResponseDto;
    packs: FindMaterialPackResponseDto[];
};
export type FindUnitsResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindWorkFormulaResponseDto = {
    id: string;
    name: string;
    value: (WorkFormulaCalculateRequestDto | number)[];
    type: FindFormulaTypeResponseDto;
    work?: FindWorkResponseDto;
    material: FindMaterialResponseDto;
    units: FindUnitsResponseDto;
};
export type FindProjectTaskStatusResponseDto = {
    id: string;
    name: string;
    description: string;
    type:
    | 'PREPARATION'
    | 'WAIT'
    | 'DONE'
    | 'PROBLEM'
    | 'CONTROL'
    | 'REVISION'
    | 'PAID';
};
export type FindProjectTaskMaterialResponseDto = {
    id: string;
    price: number;
    discountPrice?: number | null;
    material: FindMaterialResponseDto;
};
export type FindProjectTaskResponseDto = {
    id: string;
    updatedAt: string;
    name: string;
    description: string;
    workTime: number;
    status: FindProjectTaskStatusResponseDto;
    work?: FindWorkResponseDto | null;
    taskMaterials: FindProjectTaskMaterialResponseDto[];
    previous?: FindProjectTaskResponseDto | null;
};
export type FindProjectStatusResponseDto = {
    id: string;
    name: string;
    description: string;
    order: number;
    type:
    | 'PRESALE'
    | 'PLANNING'
    | 'REALIZATION'
    | 'PAUSE'
    | 'DELIVERY'
    | 'STOPPED'
    | 'WARRANTY'
    | 'DONE';
};
export type FindGroupResponseDto = {
    id: string;
    name: string;
};
export type FindProjectResponsibleResponseDto = {
    id: string;
    type: 'MANAGER' | 'FOREMAN';
    user: FindUserResponseDto;
    userGroup: FindGroupResponseDto;
};
export type FindProjectResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    from: string;
    to: string;
    workingHours: number[][];
    overhead: number;
    transportCosts: number;
    emergencyPhoneNumber: string;
    status: FindProjectStatusResponseDto;
    client: FindClientResponseDto;
    organization: FindOrganizationResponseDto;
    object: FindObjectResponseDto;
    responsibles: FindProjectResponsibleResponseDto[];
    images: FindFileResponseDto[];
};
export type FindJobStageResponseDto = {
    id: string;
    name: string;
    description: string;
    sort: number;
};
export type FindJobResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    insideDescription?: string | null;
    clientDescription?: string | null;
    materialsCount: number;
    isNoisiness: boolean;
    isInvisible: boolean;
    type: FindJobTypeResponseDto;
    package?: FindPackageResponseDto | null;
    view?: FindJobViewResponseDto | null;
    stage: FindJobStageResponseDto;
    surfaces?: FindJobSurfaceResponseDto[] | null;
    specializations: FindSpecializationResponseDto[];
    organization: FindOrganizationResponseDto;
    groups: FindJobGroupResponseDto[];
};
export type FindJobGroupResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    jobs: FindJobResponseDto[];
};
export type FindWorkResponseDto = {
    id: string;
    jobId?: string | null;
    name: string;
    insideDescription: string;
    clientDescription: string;
    materialsCount: number;
    isNoisiness: boolean;
    isInvisible: boolean;
    isUpdated: boolean;
    duration: number;
    from?: string | null;
    to?: string | null;
    fact?: string | null;
    onControl?: string | null;
    factLag?: number | null;
    model?: ('RESOURCE' | 'CUSTOM') | null;
    status?:
    | (
        | 'PREPARATION'
        | 'WAIT'
        | 'DONE'
        | 'PROBLEM'
        | 'CONTROL'
        | 'REVISION'
        | 'PAID'
      )
    | null;
    money?: number | null;
    lag?: number | null;
    workingHours: number[][];
    parts?: FindWorkPartResponseDto[] | null;
    type: FindJobTypeResponseDto;
    package: FindPackageResponseDto;
    view: FindJobViewResponseDto;
    stage: FindWorkStageResponseDto;
    surfaces: FindJobSurfaceResponseDto[];
    organization: FindOrganizationResponseDto;
    room: FindRoomResponseDto;
    formulas: FindWorkFormulaResponseDto[];
    tasks: FindProjectTaskResponseDto[];
    specializations: FindSpecializationResponseDto[];
    performer: FindUserResponseDto | null;
    performers: FindUserResponseDto[];
    project: FindProjectResponseDto;
    jobGroup: FindJobGroupResponseDto;
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type FindWorkPlanningResponseDto = {
    works: {
        [key: string]: FindWorkResponseDto[];
    };
    total: number;
};
export type WorkReportDoneWork = {
    id: string;
    name: string;
    roomName: string;
    clientDescription: string;
    volume: number;
    units: string;
    images: FindFileResponseDto[];
};
export type WorkReportPlannedWork = {
    id: string;
    name: string;
    roomName: string;
    clientDescription: string;
    volume: number;
    units: string;
    from?: string | null;
    to?: string | null;
    status: FindProjectTaskStatusResponseDto;
};
export type FindWorkReportResponseDto = {
    doneWorks: WorkReportDoneWork[];
    plannedWorks: WorkReportPlannedWork[];
};
export type UpdateWorkRequestDto = {
    /** Начало работы */
    from?: string | null;
    /** Окончание работы */
    to?: string | null;
    /** Длительность работы */
    duration?: number | null;
    workingHours?: number[][] | null;
    /** Идентификатор проекта */
    projectId?: string | null;
    /** Идентификатор помещения */
    roomId?: string | null;
    /** Идентификатор организации */
    organizationId?: string | null;
    /** Идентификатор ответственного работы */
    performerId?: string | null;
    /** Идентификаторы соисполнителей работы */
    performersIds?: string[];
    /** Название работы */
    name: string;
    /** Внутреннее описание работы */
    insideDescription: string;
    /** Клиентское описание работы */
    clientDescription: string;
    /** Шумность работы */
    isNoisiness: boolean;
    /** Является ли работа скрытой */
    isInvisible: boolean;
    /** Идентификатор вида работы */
    viewId?: string | null;
    /** Идентификатор этапа работы */
    stageId?: string | null;
    /** Идентификатор типового пакета */
    packageId?: string | null;
    /** Идентификатор типа работы */
    typeId?: string | null;
    /** Идентификаторы специализации исполнителей */
    specializationsIds: string[];
    /** Идентификаторы поверхностей работы */
    surfacesIds: string[];
    parts?: FindWorkPartResponseDto[] | null;
};
export type CreateWorkRequestDto = {
    /** Начало работы */
    from?: string | null;
    /** Окончание работы */
    to?: string | null;
    /** Длительность работы */
    duration?: number | null;
    workingHours?: number[][] | null;
    /** Идентификатор проекта */
    projectId?: string | null;
    /** Идентификатор помещения */
    roomId?: string | null;
    /** Идентификатор организации */
    organizationId?: string | null;
    /** Идентификатор ответственного работы */
    performerId?: string | null;
    /** Идентификаторы соисполнителей работы */
    performersIds?: string[];
};
export type CreateWorkBatchRequestDto = {
    /** Идентификатор проекта */
    projectId: string;
    /** Идентификатор помещения */
    roomId: string;
    /** Идентификатор группы работ */
    jobGroupId?: string | null;
    /** ID работы из справочника */
    jobId: string;
};
export type CopyRoomRequestDto = {
    roomId: string;
    jobId: string;
    workId: string;
};
export type UpdateWorkBatchRequestDto = {
    /** Начало работы */
    from?: string | null;
    /** Окончание работы */
    to?: string | null;
    /** Длительность работы */
    duration?: number | null;
    workingHours?: number[][] | null;
    /** Идентификатор проекта */
    projectId?: string | null;
    /** Идентификатор помещения */
    roomId?: string | null;
    /** Идентификатор организации */
    organizationId?: string | null;
    /** Идентификатор ответственного работы */
    performerId?: string | null;
    /** Идентификаторы соисполнителей работы */
    performersIds?: string[];
    /** Название работы */
    name: string;
    /** Внутреннее описание работы */
    insideDescription: string;
    /** Клиентское описание работы */
    clientDescription: string;
    /** Шумность работы */
    isNoisiness: boolean;
    /** Является ли работа скрытой */
    isInvisible: boolean;
    /** Идентификатор вида работы */
    viewId?: string | null;
    /** Идентификатор этапа работы */
    stageId?: string | null;
    /** Идентификатор типового пакета */
    packageId?: string | null;
    /** Идентификатор типа работы */
    typeId?: string | null;
    /** Идентификаторы специализации исполнителей */
    specializationsIds: string[];
    /** Идентификаторы поверхностей работы */
    surfacesIds: string[];
    parts?: FindWorkPartResponseDto[] | null;
    id: string;
};
export type UpdateWorkStatusRequestDto = {
    /** Статус работы */
    statusId: string;
    /** Идентификаторы работ */
    worksIds: string[];
};
export const {
    useProjectWorksFindQuery,
    useLazyProjectWorksFindQuery,
    useProjectWorksFindByProjectQuery,
    useLazyProjectWorksFindByProjectQuery,
    useProjectWorksFindWorkPlanningByProjectQuery,
    useLazyProjectWorksFindWorkPlanningByProjectQuery,
    useProjectWorksFindForReportByProjectQuery,
    useLazyProjectWorksFindForReportByProjectQuery,
    useProjectWorksFindOneQuery,
    useLazyProjectWorksFindOneQuery,
    useProjectWorkUpdateMutation,
    useProjectWorkDeleteMutation,
    useProjectWorkCreateMutation,
    useProjectWorkCreateWithJobGroupMutation,
    useProjectWorkCreateBatchMutation,
    useProjectWorkRoomCopyRoomMutation,
    useProjectWorkUpdateBatchMutation,
    useProjectWorkUpdateStatusesMutation,
    useProjectWorkUpdateLocalMutation,
} = injectedRtkApi;
