import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['ProjectJournals'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            projectJournals: build.query<
                ProjectJournalsApiResponse,
                ProjectJournalsApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/journals',
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ProjectJournals'],
            }),
            projectJournalsFindOneByProjectId: build.query<
                ProjectJournalsFindOneByProjectIdApiResponse,
                ProjectJournalsFindOneByProjectIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/journals/project/${queryArg.projectId}`,
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ProjectJournals'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as projectJournalsApi };
export type ProjectJournalsApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindProjectJournalResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type ProjectJournalsApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ProjectJournalsFindOneByProjectIdApiResponse =
  /** status 200 ОК */ {
      metadata: {
          id: string;
          permissions: string[];
      }[];
      data: FindProjectJournalByProjectResponseDto[];
      pagination: {
          offset: number;
          limit: number;
          total: number;
      };
  };
export type ProjectJournalsFindOneByProjectIdApiArg = {
    projectId: string;
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ProjectJournalValue = {
    id?: string | null;
    value: string | boolean | number;
};
export type FindProjectStatusResponseDto = {
    id: string;
    name: string;
    description: string;
    order: number;
    type:
    | 'PRESALE'
    | 'PLANNING'
    | 'REALIZATION'
    | 'PAUSE'
    | 'DELIVERY'
    | 'STOPPED'
    | 'WARRANTY'
    | 'DONE';
};
export type FindClientKindResponseDto = {
    id: string;
    name: string;
};
export type FindClientTaxationSystemResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindDepartmentResponseDto = {
    id: string;
    name: string;
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindRolePermissionDto = {
    id: string;
    name: string;
    description: string;
    allow: boolean;
};
export type FindRolePermissionGroupDto = {
    name: string;
    permissions: FindRolePermissionDto[];
};
export type FindRoleResponseDto = {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
    type: 'MANAGER' | 'WORKER';
    organization: FindOrganizationResponseDto;
    permissions: FindRolePermissionGroupDto[];
};
export type FindUserResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    email: string;
    phone: string | null;
    job: string | null;
    chatId: number | null;
    deleted: boolean;
    compensationPercent: number;
    compensationPercentUpdatedAt: string | null;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    department: FindDepartmentResponseDto;
    specializations: FindSpecializationResponseDto[];
    avatar: FindFileResponseDto;
    roles: FindRoleResponseDto[];
    organizations: FindOrganizationResponseDto[];
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    workingDays: (number[][] | (string | null))[];
};
export type FindClientContactResponseDto = {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    position: string;
    phone: string | null;
    email: string | null;
    website: string | null;
    company: string | null;
    note: string | null;
};
export type FindClientBankResponseDto = {
    id: string;
    name: string;
    abbreviatedName: string;
    bik: string;
    ks?: string | null;
    index?: string | null;
    city?: string | null;
    address?: string | null;
    phone?: string | null;
    okato?: string | null;
    okpo?: string | null;
    regNum?: string | null;
};
export type FindAccountItemResponseDto = {
    id: string;
    number: string;
    bank: FindClientBankResponseDto;
};
export type FindClientTypeResponseDto = {
    id: string;
    name: string;
};
export type FindClientResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    name: string;
    middleName: string | null;
    registrationAddress: string | null;
    birthDate: string | null;
    passportNumber: number | null;
    passportIssuer: string | null;
    passportIssueDate: string | null;
    passportIssuingUnitCode: string | null;
    fullName: string | null;
    abbreviatedName: string | null;
    legalAddress: string | null;
    inn: string;
    kpp: string | null;
    ogrn: string | null;
    okved: string | null;
    okpo: string | null;
    okato: string | null;
    deleted: boolean;
    kind: FindClientKindResponseDto;
    taxationSystem: FindClientTaxationSystemResponseDto;
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    contact: FindClientContactResponseDto;
    account: FindAccountItemResponseDto;
    types: FindClientTypeResponseDto[];
    organization: FindOrganizationResponseDto;
    contacts: FindClientContactResponseDto[];
    accounts: FindAccountItemResponseDto[];
};
export type FindObjectResponseDto = {
    id: string;
    name: string;
    deleted: boolean;
    address: string;
    fullAddress: string;
    city: string;
    street: string;
    corpus: string;
    building: string;
    house: string;
    entrance: number;
    floor: number;
    apartment: number;
    intercomCode: string;
    comment: string;
    longitude: number;
    latitude: number;
    client: FindClientResponseDto;
};
export type FindGroupResponseDto = {
    id: string;
    name: string;
};
export type FindProjectResponsibleResponseDto = {
    id: string;
    type: 'MANAGER' | 'FOREMAN';
    user: FindUserResponseDto;
    userGroup: FindGroupResponseDto;
};
export type FindProjectResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    from: string;
    to: string;
    workingHours: number[][];
    overhead: number;
    transportCosts: number;
    emergencyPhoneNumber: string;
    status: FindProjectStatusResponseDto;
    client: FindClientResponseDto;
    organization: FindOrganizationResponseDto;
    object: FindObjectResponseDto;
    responsibles: FindProjectResponsibleResponseDto[];
    images: FindFileResponseDto[];
};
export type FindProjectJournalResponseDto = {
    id: string;
    createdAt: string;
    type:
    | 'PROJECT'
    | 'STATUS'
    | 'CLIENT'
    | 'OBJECT'
    | 'OUTLAY'
    | 'FOREMAN'
    | 'MANAGER'
    | 'ADJUSTMENT';
    method: 'CREATE' | 'UPDATE' | 'DELETE';
    field: string | null;
    comment: string | null;
    oldValue: ProjectJournalValue | (string | null);
    newValue: ProjectJournalValue | (string | null);
    project: FindProjectResponseDto;
    user: FindUserResponseDto;
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type FindProjectJournalByProjectResponseDto = {
    id: string;
    createdAt: string;
    type:
    | 'PROJECT'
    | 'STATUS'
    | 'CLIENT'
    | 'OBJECT'
    | 'OUTLAY'
    | 'FOREMAN'
    | 'MANAGER'
    | 'ADJUSTMENT';
    method: 'CREATE' | 'UPDATE' | 'DELETE';
    field: string | null;
    comment: string | null;
    oldValue: ProjectJournalValue | (string | null);
    newValue: ProjectJournalValue | (string | null);
    user: FindUserResponseDto;
};
export const {
    useProjectJournalsQuery,
    useLazyProjectJournalsQuery,
    useProjectJournalsFindOneByProjectIdQuery,
    useLazyProjectJournalsFindOneByProjectIdQuery,
} = injectedRtkApi;
