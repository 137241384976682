import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { FinanceSettingsSliceInitialState } from 'components/Settings/NestedPages/Finance/FinancePage.types.ts';
import { financeSettingsFormBodyInitialState } from 'components/Settings/NestedPages/Finance/Finance.utils.ts';

const financeSettingsSliceInitialState: FinanceSettingsSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: financeSettingsFormBodyInitialState,
}

export const financeSettingsSlice = createAppSlice<FinanceSettingsSliceInitialState>('financeSettings', financeSettingsSliceInitialState)


export default financeSettingsSlice.reducer