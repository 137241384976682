import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem'
import { createElement } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { RootStateType } from 'store/store.ts'

export const RESOURCES_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={'materials'} />,
    },
    {
        id: 'materials',
        path: 'materials',
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Navigate to={'materials'} replace />,
            },
            {
                id: 'materialsMaterials',
                path: 'materials',
                element: <Outlet />,
                loader: () => ({
                    name: 'Материалы',
                    path: 'resources/materials/materials',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { MaterialsTable } = await import('components/Resources/Materials/MaterialsTable')
                            return { element: <MaterialsTable /> }
                        },
                    },
                    {
                        id: 'materialCreate',
                        path: 'new',
                        async lazy() {
                            const { MaterialPage } = await import('components/Resources/Materials/MaterialPage')
                            return { element: <MaterialPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.id,
                            name: 'Новый материал',
                            path: 'resources/materials/mater`ials/new',
                        }),
                        children: [
                            {
                                index: true,
                                element: <Navigate to={'info'} replace />,
                            },
                            {
                                id: 'materialCreateInfo',
                                path: 'info',
                                async lazy() {
                                    const { MaterialBodyEditInfo } = await import('components/Resources/Materials/PageBody/Info/MaterialBodyEditInfo')
                                    return { element: <MaterialBodyEditInfo /> }
                                },
                            },
                            {
                                id: 'materialCreateStores',
                                path: 'stores',
                                async lazy() {
                                    const { MaterialBodyEditStores } = await import('components/Resources/Materials/PageBody/Stores/MaterialBodyEditStores')
                                    return { element: <MaterialBodyEditStores /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'materialById',
                        path: ':materialId',
                        async lazy() {
                            const { MaterialPage } = await import('components/Resources/Materials/MaterialPage')
                            return { element: <MaterialPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.materialId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.materialsReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/materials/${params.materialId}`,
                        }),
                        children: [
                            {
                                index: true,
                                element: <Navigate to={'info'} replace />,
                            },
                            {
                                id: 'materialViewInfo',
                                path: 'info',
                                async lazy() {
                                    const { MaterialBodyViewInfo } = await import('components/Resources/Materials/PageBody/Info/MaterialBodyViewInfo')
                                    return { element: <MaterialBodyViewInfo /> }
                                },
                            },
                            {
                                id: 'materialViewStores',
                                path: 'stores',
                                async lazy() {
                                    const { MaterialBodyViewStores } = await import('components/Resources/Materials/PageBody/Stores/MaterialBodyViewStores')
                                    return { element: <MaterialBodyViewStores /> }
                                },
                            },
                            {
                                id: 'materialViewAnalogues',
                                path: 'analogues',
                                async lazy() {
                                    const { MaterialBodyViewAnalogues } = await import('components/Resources/Materials/PageBody/Analogues/MaterialBodyViewAnalogues')
                                    return { element: <MaterialBodyViewAnalogues /> }
                                },
                            },
                            {
                                id: 'materialEdit',
                                path: 'edit',
                                element: <Outlet />,
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={'info'} replace />,
                                    },
                                    {
                                        id: 'materialEditInfo',
                                        path: 'info',
                                        async lazy() {
                                            const { MaterialBodyEditInfo } = await import('components/Resources/Materials/PageBody/Info/MaterialBodyEditInfo')
                                            return { element: <MaterialBodyEditInfo /> }
                                        },
                                    },
                                    {
                                        id: 'materialEditStores',
                                        path: 'stores',
                                        async lazy() {
                                            const { MaterialBodyEditStores } = await import('components/Resources/Materials/PageBody/Stores/MaterialBodyEditStores')
                                            return { element: <MaterialBodyEditStores /> }
                                        },
                                    },
                                    {
                                        id: 'materialEditAnalogues',
                                        path: 'analogues',
                                        async lazy() {
                                            const { MaterialBodyEditAnalogues } = await import('components/Resources/Materials/PageBody/Analogues/MaterialBodyEditAnalogues')
                                            return { element: <MaterialBodyEditAnalogues /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'materialsStores',
                path: 'stores',
                element: <Outlet />,
                loader: () => ({
                    name: 'Магазины',
                    path: 'resources/materials/stores',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { StoresTable } = await import('components/Resources/Materials/NestedPages/Stores/StoresTable')
                            return { element: <StoresTable /> }
                        },
                    },
                    {
                        id: 'storeCreate',
                        path: 'new',
                        async lazy() {
                            const { StorePage } = await import('components/Resources/Materials/NestedPages/Stores/StorePage')
                            return { element: <StorePage /> }
                        },
                        loader: () => ({
                            name: 'Новый магазин',
                            path: 'resources/materials/stores/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { StoreBodyEdit } = await import('components/Resources/Materials/NestedPages/Stores/PageBody/StoreBodyEdit')
                                    return { element: <StoreBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'storeById',
                        path: ':storeId',
                        async lazy() {
                            const { StorePage } = await import('components/Resources/Materials/NestedPages/Stores/StorePage')
                            return { element: <StorePage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.storeId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.storesReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/materials/stores/${params.storeId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'storeView',
                                async lazy() {
                                    const { StoreBodyView } = await import('components/Resources/Materials/NestedPages/Stores/PageBody/StoreBodyView')
                                    return { element: <StoreBodyView /> }
                                },
                            },
                            {
                                id: 'storeEdit',
                                path: 'edit',
                                async lazy() {
                                    const { StoreBodyEdit } = await import('components/Resources/Materials/NestedPages/Stores/PageBody/StoreBodyEdit')
                                    return { element: <StoreBodyEdit /> }
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: 'materialsUsage',
                path: 'material_usage',
                element: <Outlet />,
                loader: () => ({
                    name: 'Использование материалов',
                    path: 'resources/materials/material_usage',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { MaterialUsageTable } = await import('components/Resources/Materials/NestedPages/MaterialUsage/MaterialUsageTable.tsx')
                            return { element: <MaterialUsageTable /> }
                        },
                    },
                ]
            },
            {
                id: 'minStroyMaterials',
                path: 'minStroyMaterials',
                element: <Outlet />,
                loader: () => ({
                    name: 'ФСБЦ',
                    path: 'resources/materials/minStroyMaterials',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { MinStroyMaterialsTable } = await import('components/Resources/Materials/NestedPages/MinStroyMaterials/MinStroyMaterialsTable')
                            return { element: <MinStroyMaterialsTable /> }
                        },
                    },
                    {
                        id: 'minStroyMaterialById',
                        path: ':minStroyMaterialId',
                        async lazy() {
                            const { MinStroyMaterialPageBodyView } = await import('components/Resources/Materials/NestedPages/MinStroyMaterials/PageBody/MinStroyMaterialPageBodyView.tsx')
                            return { element: <MinStroyMaterialPageBodyView /> }
                        },
                        loader: ({ params }) => ({
                            id: params.minStroyMaterialId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.breadCrumbsNameReducer.breadCrumbName || '',
                            }))),
                            path: `/resources/materials/Ksr/${params.minStroyMaterialId}`,
                        }),
                    },
                ]
            },
            {
                id: 'Ksr',
                path: 'Ksr',
                element: <Outlet />,
                loader: () => ({
                    name: 'КСР',
                    path: 'resources/materials/Ksr',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { MinstroyMaterialsKSRTable } = await import('components/Resources/Materials/NestedPages/MinstroyMaterialsKSRMaterials/MinstroyMaterialsKSRTable')
                            return { element: <MinstroyMaterialsKSRTable /> }
                        },
                    },
                    {
                        id: 'KSRMaterialById',
                        path: ':KSRMaterialId',
                        async lazy() {
                            const { MinstroyMaterialKSRPageBodyView } = await import('components/Resources/Materials/NestedPages/MinstroyMaterialsKSRMaterials/PageBody/MinstroyMaterialKSRPageBodyView')
                            return { element: <MinstroyMaterialKSRPageBodyView /> }
                        },
                        loader: ({ params }) => ({
                            id: params.KSRMaterialId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.breadCrumbsNameReducer.breadCrumbName || '',
                            }))),
                            path: `/resources/materials/Ksr/${params.KSRMaterialId}`,
                        }),
                    },
                ]
            },
            {
                id: 'materialsCategories',
                path: 'categories',
                element: <Outlet />,
                loader: () => ({
                    name: 'Категории',
                    path: 'resources/materials/categories',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { CategoriesTable } = await import('components/Resources/Materials/NestedPages/Categories/CategoriesTable')
                            return { element: <CategoriesTable /> }
                        },
                    },
                    {
                        id: 'categoryCreate',
                        path: 'new',
                        async lazy() {
                            const { CategoryPage } = await import('components/Resources/Materials/NestedPages/Categories/CategoryPage')
                            return { element: <CategoryPage /> }
                        },
                        loader: () => ({
                            name: 'Новая категория',
                            path: 'resources/materials/categories/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { CategoryBodyEdit } = await import('components/Resources/Materials/NestedPages/Categories/PageBody/CategoryBodyEdit')
                                    return { element: <CategoryBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'categoryById',
                        path: ':categoryId',
                        async lazy() {
                            const { CategoryPage } = await import('components/Resources/Materials/NestedPages/Categories/CategoryPage')
                            return { element: <CategoryPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.categoryId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.categoriesReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/materials/categories/${params.categoryId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'categoryView',
                                async lazy() {
                                    const { CategoryBodyView } = await import('components/Resources/Materials/NestedPages/Categories/PageBody/CategoryBodyView')
                                    return { element: <CategoryBodyView /> }
                                },
                            },
                            {
                                id: 'categoryEdit',
                                path: 'edit',
                                async lazy() {
                                    const { CategoryBodyEdit } = await import('components/Resources/Materials/NestedPages/Categories/PageBody/CategoryBodyEdit')
                                    return { element: <CategoryBodyEdit /> }
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: 'materialsBrands',
                path: 'brands',
                element: <Outlet />,
                loader: () => ({
                    name: 'Бренды',
                    path: 'resources/materials/brands',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { BrandsTable } = await import('components/Resources/Materials/NestedPages/Brands/BrandsTable')
                            return { element: <BrandsTable /> }
                        },
                    },
                    {
                        id: 'brandCreate',
                        path: 'new',
                        async lazy() {
                            const { BrandPage } = await import('components/Resources/Materials/NestedPages/Brands/BrandPage')
                            return { element: <BrandPage /> }
                        },
                        loader: () => ({
                            name: 'Новый бренд',
                            path: 'resources/materials/brands/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { BrandBodyEdit } = await import('components/Resources/Materials/NestedPages/Brands/PageBody/BrandBodyEdit')
                                    return { element: <BrandBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'brandById',
                        path: ':brandId',
                        async lazy() {
                            const { BrandPage } = await import('components/Resources/Materials/NestedPages/Brands/BrandPage')
                            return { element: <BrandPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.brandId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.brandsReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/materials/brands/${params.brandId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'brandView',
                                async lazy() {
                                    const { BrandBodyView } = await import('components/Resources/Materials/NestedPages/Brands/PageBody/BrandBodyView')
                                    return { element: <BrandBodyView /> }
                                },
                            },
                            {
                                id: 'brandEdit',
                                path: 'edit',
                                async lazy() {
                                    const { BrandBodyEdit } = await import('components/Resources/Materials/NestedPages/Brands/PageBody/BrandBodyEdit')
                                    return { element: <BrandBodyEdit /> }
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: 'jobs',
        path: 'jobs',
        element: <Outlet />,
        loader: () => ({
            name: 'Работы',
            path: 'resources/jobs',
        }),
        children: [
            {
                index: true,
                id: 'jobList',
                async lazy() {
                    const { JobsTable } = await import('components/Resources/Jobs/JobsTable')
                    return { element: <JobsTable /> }
                },

            },
            {
                id: 'jobCreate',
                path: 'new',
                async lazy() {
                    const { JobPage } = await import('components/Resources/Jobs/JobPage')
                    return { element: <JobPage /> }
                },
                loader: () => ({
                    name: 'Новая работа',
                    path: 'resources/jobs/new',
                }),
                children: [
                    {
                        index: true,
                        element: <Navigate to={'info'} replace />,
                    },
                    {
                        id: 'jobCreateInfo',
                        path: 'info',
                        async lazy() {
                            const { JobBodyEditInfo } = await import('components/Resources/Jobs/PageBody/Info/JobBodyEditInfo')
                            return { element: <JobBodyEditInfo /> }
                        },
                    },
                    {
                        id: 'jobCreateMaterials',
                        path: 'materials',
                        async lazy() {
                            const { JobBodyViewMaterialsTable } = await import('components/Resources/Jobs/PageBody/Materials/JobBodyViewMaterialsTable')
                            return { element: <JobBodyViewMaterialsTable isEditable /> }
                        },
                    },
                    {
                        id: 'jobCreateFormulas',
                        path: 'formulas',
                        async lazy() {
                            const { JobFormulasList } = await import('components/Resources/Jobs/PageBody/Formulas/JobFormulasList')
                            return { element: <JobFormulasList isEditable /> }
                        },
                    },
                    {
                        id: 'jobCreateTasks',
                        path: 'tasks',
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                id: 'jobCreateTaskList',
                                async lazy() {
                                    const { JobBodyEditTasks } = await import('components/Resources/Jobs/PageBody/Tasks/JobBodyEditTasks')
                                    return { element: <JobBodyEditTasks /> }
                                },
                            },
                            {
                                id: 'jobCreateTaskCreate',
                                path: 'new',
                                async lazy() {
                                    const { TaskPage } = await import('components/Resources/Jobs/NestedPages/Tasks/TaskPage')
                                    return { element: <TaskPage /> }
                                },
                                loader: () => ({
                                    name: 'Новая задача',
                                    path: 'resources/jobs/new/tasks/new',
                                }),
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={'params'} replace />,
                                    },
                                    {
                                        id: 'jobCreateTaskCreateParams',
                                        path: 'params',
                                        async lazy() {
                                            const { TaskBodyEditParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyEditParams')
                                            return { element: <TaskBodyEditParams /> }
                                        },
                                    },
                                    {
                                        id: 'jobCreateTaskCreateMaterials',
                                        path: 'materials',
                                        async lazy() {
                                            const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                            return { element: <TaskBodyViewMaterialsTable isEditable /> }
                                        },
                                    },
                                    {
                                        id: 'jobCreateTaskCreateInfo',
                                        path: 'info',
                                        async lazy() {
                                            const { TaskBodyEditInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyEditInfo')
                                            return { element: <TaskBodyEditInfo /> }
                                        },
                                    },
                                    {
                                        id: 'jobCreateTaskCreateSteps',
                                        path: 'steps',
                                        async lazy() {
                                            const { TaskBodyEditSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyEditSteps')
                                            return { element: <TaskBodyEditSteps /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'jobById',
                path: ':jobId',
                async lazy() {
                    const { JobPage } = await import('components/Resources/Jobs/JobPage')
                    return { element: <JobPage /> }
                },
                loader: ({ params }) => ({
                    id: params.jobId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.jobsReducer.editableFormHeader.name || '',
                    }))),
                    path: `resources/jobs/${params.jobId}`,
                }),
                children: [
                    {
                        index: true,
                        id: 'jobView',
                        element: <Navigate to={'info'} replace />,
                    },
                    {
                        id: 'jobInfo',
                        path: 'info',
                        async lazy() {
                            const { JobBodyViewInfo } = await import('components/Resources/Jobs/PageBody/Info/JobBodyViewInfo')
                            return { element: <JobBodyViewInfo /> }
                        },
                    },
                    {
                        id: 'jobMaterials',
                        path: 'materials',
                        async lazy() {
                            const { JobBodyViewMaterialsTable } = await import('components/Resources/Jobs/PageBody/Materials/JobBodyViewMaterialsTable')
                            return { element: <JobBodyViewMaterialsTable /> }
                        },
                    },
                    {
                        id: 'jobFormulas',
                        path: 'formulas',
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                id: 'jobFormulaList',
                                async lazy() {
                                    const { JobFormulasList } = await import('components/Resources/Jobs/PageBody/Formulas/JobFormulasList')
                                    return { element: <JobFormulasList /> }
                                },
                            },
                            {
                                id: 'jobFormulaById',
                                path: ':formulaId',
                                async lazy() {
                                    const { FormulaPage } = await import('components/Resources/Jobs/PageBody/Formulas/FormulaPage')
                                    return { element: <FormulaPage /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'jobTasks',
                        path: 'tasks',
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { JobBodyViewTasks } = await import('components/Resources/Jobs/PageBody/Tasks/JobBodyViewTasks')
                                    return { element: <JobBodyViewTasks /> }
                                },
                            },
                            {
                                id: 'jobTaskById',
                                path: ':taskId',
                                async lazy() {
                                    const { TaskPage } = await import('components/Resources/Jobs/NestedPages/Tasks/TaskPage')
                                    return { element: <TaskPage /> }
                                },
                                loader: ({ params }) => ({
                                    id: params.taskId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.tasksReducer.editableFormHeader.name || '',
                                    }))),
                                    path: `resources/jobs/${params.jobId}/tasks/${params.taskId}`,
                                }),
                                children: [
                                    {
                                        index: true,
                                        id: 'jobTaskView',
                                        element: <Navigate to={'params'} replace />,
                                    },
                                    {
                                        id: 'jobTaskParams',
                                        path: 'params',
                                        async lazy() {
                                            const { TaskBodyViewParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyViewParams')
                                            return { element: <TaskBodyViewParams /> }
                                        },
                                    },
                                    {
                                        id: 'jobTaskMaterials',
                                        path: 'materials',
                                        async lazy() {
                                            const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                            return { element: <TaskBodyViewMaterialsTable /> }
                                        },
                                    },
                                    {
                                        id: 'jobTaskInfo',
                                        path: 'info',
                                        async lazy() {
                                            const { TaskBodyViewInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyViewInfo')
                                            return { element: <TaskBodyViewInfo /> }
                                        },
                                    },
                                    {
                                        id: 'jobTaskSteps',
                                        path: 'steps',
                                        async lazy() {
                                            const { TaskBodyViewSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyViewSteps')
                                            return { element: <TaskBodyViewSteps /> }
                                        },
                                    },
                                ],
                            },
                            {
                                id: 'jobTaskCreate',
                                path: 'new',
                                async lazy() {
                                    const { TaskPage } = await import('components/Resources/Jobs/NestedPages/Tasks/TaskPage')
                                    return { element: <TaskPage /> }
                                },
                                loader: ({ params }) => ({
                                    name: 'Новая задача',
                                    path: `resources/jobs/${params.jobId}/tasks/new`,
                                }),
                                children: [
                                    {
                                        index: true,
                                        id: 'jobTaskEdit',
                                        element: <Navigate to={'params'} replace />,
                                    },
                                    {
                                        id: 'jobTaskCreateParams',
                                        path: 'params',
                                        async lazy() {
                                            const { TaskBodyEditParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyEditParams')
                                            return { element: <TaskBodyEditParams /> }
                                        },
                                    },
                                    {
                                        id: 'jobTaskCreateMaterials',
                                        path: 'materials',
                                        async lazy() {
                                            const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                            return { element: <TaskBodyViewMaterialsTable isEditable /> }
                                        },
                                    },
                                    {
                                        id: 'jobTaskCreateInfo',
                                        path: 'info',
                                        async lazy() {
                                            const { TaskBodyEditInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyEditInfo')
                                            return { element: <TaskBodyEditInfo /> }
                                        },
                                    },
                                    {
                                        id: 'jobTaskCreateSteps',
                                        path: 'steps',
                                        async lazy() {
                                            const { TaskBodyEditSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyEditSteps')
                                            return { element: <TaskBodyEditSteps /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'jobEdit',
                        path: 'edit',
                        children: [
                            {
                                index: true,
                                element: <Navigate to={'info'} replace />,
                            },
                            {
                                id: 'jobEditInfo',
                                path: 'info',
                                async lazy() {
                                    const { JobBodyEditInfo } = await import('components/Resources/Jobs/PageBody/Info/JobBodyEditInfo')
                                    return { element: <JobBodyEditInfo /> }
                                },
                            },
                            {
                                id: 'jobEditMaterials',
                                path: 'materials',
                                async lazy() {
                                    const { JobBodyViewMaterialsTable } = await import('components/Resources/Jobs/PageBody/Materials/JobBodyViewMaterialsTable')
                                    return { element: <JobBodyViewMaterialsTable isEditable /> }
                                },
                            },
                            {
                                id: 'jobEditFormulas',
                                path: 'formulas',
                                element: <Outlet />,
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { JobFormulasList } = await import('components/Resources/Jobs/PageBody/Formulas/JobFormulasList')
                                            return { element: <JobFormulasList isEditable /> }
                                        },
                                    },
                                    {
                                        id: 'jobEditFormulaById',
                                        path: ':formulaId',
                                        async lazy() {
                                            const { FormulaPage } = await import('components/Resources/Jobs/PageBody/Formulas/FormulaPage')
                                            return { element: <FormulaPage /> }
                                        },
                                    },
                                    {
                                        id: 'jobEditFormulaCreate',
                                        path: 'new',
                                        async lazy() {
                                            const { FormulaPage } = await import('components/Resources/Jobs/PageBody/Formulas/FormulaPage')                                            
                                            return { element: <FormulaPage /> }
                                        },
                                    }
                                ],
                            },
                            {
                                id: 'jobEditTasks',
                                path: 'tasks',
                                element: <Outlet />,
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { JobBodyEditTasks } = await import('components/Resources/Jobs/PageBody/Tasks/JobBodyEditTasks')
                                            return { element: <JobBodyEditTasks /> }
                                        },
                                    },
                                    {
                                        id: 'jobEditTaskById',
                                        path: ':taskId',
                                        async lazy() {
                                            const { TaskPage } = await import('components/Resources/Jobs/NestedPages/Tasks/TaskPage')
                                            return { element: <TaskPage /> }
                                        },
                                        loader: ({ params }) => ({
                                            id: params.taskId,
                                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                                name: state.tasksReducer.editableFormHeader.name || '',
                                            }))),
                                            path: `resources/jobs/${params.jobId}/edit/tasks/${params.taskId}`,
                                        }),
                                        children: [
                                            // TODO not used scenario? remove then if no bugs
                                            // {
                                            //     index: true,
                                            //     id: 'jobEditTaskEdit',
                                            //     element: <Navigate to={'params'} replace />
                                            // },
                                            // {
                                            //     id: 'jobEditTaskBodyEditParams',
                                            //     path: 'params',
                                            //     element: <TaskBodyEditParams />,
                                            // },
                                            // {
                                            //     id: 'jobEditTaskMaterials',
                                            //     path: 'materials',
                                            //     element: <TaskBodyViewMaterialsTable />,
                                            // },
                                            // {
                                            //     id: 'jobEditTaskInfo',
                                            //     path: 'info',
                                            //     element: <TaskBodyViewInfo />,
                                            // },
                                            // {
                                            //     id: 'jobEditTaskSteps',
                                            //     path: 'steps',
                                            //     element: <TaskBodyViewSteps />,
                                            // },

                                            {
                                                id: 'jobEditTaskEdit',
                                                path: 'edit',
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <Navigate to={'params'} replace />,
                                                    },
                                                    {
                                                        id: 'jobEditTaskEditParams',
                                                        path: 'params',
                                                        async lazy() {
                                                            const { TaskBodyEditParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyEditParams')
                                                            return { element: <TaskBodyEditParams /> }
                                                        },
                                                    },
                                                    {
                                                        id: 'jobEditTaskEditMaterials',
                                                        path: 'materials',
                                                        async lazy() {
                                                            const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                                            return { element: <TaskBodyViewMaterialsTable isEditable /> }
                                                        },
                                                    },
                                                    {
                                                        id: 'jobEditTaskEditInfo',
                                                        path: 'info',
                                                        async lazy() {
                                                            const { TaskBodyEditInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyEditInfo')
                                                            return { element: <TaskBodyEditInfo /> }
                                                        },
                                                    },
                                                    {
                                                        id: 'jobEditTaskEditSteps',
                                                        path: 'steps',
                                                        async lazy() {
                                                            const { TaskBodyEditSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyEditSteps')
                                                            return { element: <TaskBodyEditSteps /> }
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        id: 'jobEditTaskCreate',
                                        path: 'new',
                                        async lazy() {
                                            const { TaskPage } = await import('components/Resources/Jobs/NestedPages/Tasks/TaskPage')
                                            return { element: <TaskPage /> }
                                        },
                                        loader: ({ params }) => ({
                                            name: 'Новая задача',
                                            path: `resources/jobs/${params.jobId}/edit/tasks/new`,
                                        }),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to={'params'} replace />,
                                            },
                                            {
                                                id: 'jobEditTaskCreateParams',
                                                path: 'params',
                                                async lazy() {
                                                    const { TaskBodyEditParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyEditParams')
                                                    return { element: <TaskBodyEditParams /> }
                                                },
                                            },
                                            {
                                                id: 'jobEditTaskCreateMaterials',
                                                path: 'materials',
                                                async lazy() {
                                                    const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                                    return { element: <TaskBodyViewMaterialsTable isEditable /> }
                                                },
                                            },
                                            {
                                                id: 'jobEditTaskCreateInfo',
                                                path: 'info',
                                                async lazy() {
                                                    const { TaskBodyEditInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyEditInfo')
                                                    return { element: <TaskBodyEditInfo /> }
                                                },
                                            },
                                            {
                                                id: 'jobEditTaskCreateSteps',
                                                path: 'steps',
                                                async lazy() {
                                                    const { TaskBodyEditSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyEditSteps')
                                                    return { element: <TaskBodyEditSteps /> }
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'minstroyJobs',
                path: 'minstroyJobs',
                element: <Outlet />,
                loader: () => ({
                    name: 'ГЭСН',
                    path: 'resources/materials/minstroyJobs',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { MinstroyJobsTable } = await import('components/Resources/Jobs/NestedPages/MinstroyJobs/MinstroyJobsTable')
                            return { element: <MinstroyJobsTable /> }
                        },
                    },
                    {
                        id: 'minstroyJoblId',
                        path: ':minstroyJoblId',
                        async lazy() {
                            const { MinstroyJobsPageBodyView } = await import('components/Resources/Jobs/NestedPages/MinstroyJobs/MinstroyJobsPageBodyView.tsx')
                            return { element: <MinstroyJobsPageBodyView /> }
                        },
                        loader: ({ params }) => ({
                            id: params.minstroyJoblId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.breadCrumbsNameReducer.breadCrumbName || '',
                            }))),
                            path: `/resources/jobs/minstroyJobs/${params.minstroyJoblId}`,
                        }),
                    },
                ]
            },
            {
                id: 'tasks',
                path: 'tasks',
                element: <Outlet />,
                loader: () => ({
                    name: 'Задачи',
                    path: 'resources/jobs/tasks',
                }),
                children: [
                    {
                        index: true,
                        id: 'taskList',
                        async lazy() {
                            const { TasksTable } = await import('components/Resources/Jobs/NestedPages/Tasks/TasksTable')
                            return { element: <TasksTable /> }
                        },
                    },
                    {
                        id: 'taskCreate',
                        path: 'new',
                        async lazy() {
                            const { TaskPage } = await import('components/Resources/Jobs/NestedPages/Tasks/TaskPage')
                            return { element: <TaskPage /> }
                        },
                        loader: () => ({
                            name: 'Новая задача',
                            path: 'resources/jobs/tasks/new',
                        }),
                        children: [
                            {
                                index: true,
                                element: <Navigate to={'params'} replace />,
                            },
                            {
                                id: 'taskCreateParams',
                                path: 'params',
                                async lazy() {
                                    const { TaskBodyEditParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyEditParams')
                                    return { element: <TaskBodyEditParams /> }
                                },
                            },
                            {
                                id: 'taskCreateMaterials',
                                path: 'materials',
                                async lazy() {
                                    const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                    return { element: <TaskBodyViewMaterialsTable isEditable /> }
                                },
                            },
                            {
                                id: 'taskCreateInfo',
                                path: 'info',
                                async lazy() {
                                    const { TaskBodyEditInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyEditInfo')
                                    return { element: <TaskBodyEditInfo /> }
                                },
                            },
                            {
                                id: 'taskCreateSteps',
                                path: 'steps',
                                async lazy() {
                                    const { TaskBodyEditSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyEditSteps')
                                    return { element: <TaskBodyEditSteps /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'taskById',
                        path: ':taskId',
                        async lazy() {
                            const { TaskPage } = await import('components/Resources/Jobs/NestedPages/Tasks/TaskPage')
                            return { element: <TaskPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.taskId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.tasksReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/jobs/tasks/${params.taskId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'taskView',
                                element: <Navigate to={'params'} replace />,
                            },
                            {
                                id: 'taskParams',
                                path: 'params',
                                async lazy() {
                                    const { TaskBodyViewParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyViewParams')
                                    return { element: <TaskBodyViewParams /> }
                                },
                            },
                            {
                                id: 'taskMaterials',
                                path: 'materials',
                                async lazy() {
                                    const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                    return { element: <TaskBodyViewMaterialsTable /> }
                                },
                            },
                            {
                                id: 'taskInfo',
                                path: 'info',
                                async lazy() {
                                    const { TaskBodyViewInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyViewInfo')
                                    return { element: <TaskBodyViewInfo /> }
                                },
                            },
                            {
                                id: 'taskSteps',
                                path: 'steps',
                                async lazy() {
                                    const { TaskBodyViewSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyViewSteps')
                                    return { element: <TaskBodyViewSteps /> }
                                },
                            },
                            {
                                id: 'taskEdit',
                                path: 'edit',
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={'params'} replace />,
                                    },
                                    {
                                        id: 'taskEditParams',
                                        path: 'params',
                                        async lazy() {
                                            const { TaskBodyEditParams } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Params/TaskBodyEditParams')
                                            return { element: <TaskBodyEditParams /> }
                                        },
                                    },
                                    {
                                        id: 'taskEditMaterials',
                                        path: 'materials',
                                        async lazy() {
                                            const { TaskBodyViewMaterialsTable } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskBodyViewMaterialsTable')
                                            return { element: <TaskBodyViewMaterialsTable isEditable /> }
                                        },
                                    },
                                    {
                                        id: 'taskEditInfo',
                                        path: 'info',
                                        async lazy() {
                                            const { TaskBodyEditInfo } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskBodyEditInfo')
                                            return { element: <TaskBodyEditInfo /> }
                                        },
                                    },
                                    {
                                        id: 'taskEditSteps',
                                        path: 'steps',
                                        async lazy() {
                                            const { TaskBodyEditSteps } = await import('components/Resources/Jobs/NestedPages/Tasks/PageBody/Steps/TaskBodyEditSteps')
                                            return { element: <TaskBodyEditSteps /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'stages',
                path: 'stages',
                element: <Outlet />,
                loader: () => ({
                    name: 'Этапы работ',
                    path: 'resources/jobs/stages',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { StagesTable } = await import('components/Resources/Jobs/NestedPages/Stages/StagesTable')
                            return { element: <StagesTable /> }
                        },
                    },
                    {
                        id: 'stageCreate',
                        path: 'new',
                        async lazy() {
                            const { StagePage } = await import('components/Resources/Jobs/NestedPages/Stages/StagePage')
                            return { element: <StagePage /> }
                        },
                        loader: () => ({
                            name: 'Новый этапы работ',
                            path: 'resources/jobs/stages/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { StagePageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Stages/PageBody/StagePageBodyEdit')
                                    return { element: <StagePageBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'stageById',
                        path: ':stageId',
                        async lazy() {
                            const { StagePage } = await import('components/Resources/Jobs/NestedPages/Stages/StagePage')
                            return { element: <StagePage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.stageId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.stagesReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/jobs/stages/${params.stageId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'stageView',
                                async lazy() {
                                    const { StagePageBodyView } = await import('components/Resources/Jobs/NestedPages/Stages/PageBody/StagePageBodyView')
                                    return { element: <StagePageBodyView /> }
                                },
                            },
                            {
                                id: 'stageEdit',
                                path: 'edit',
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { StagePageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Stages/PageBody/StagePageBodyEdit')
                                            return { element: <StagePageBodyEdit /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'views',
                path: 'views',
                element: <Outlet />,
                loader: () => ({
                    name: 'Виды работ',
                    path: 'resources/jobs/views',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { ViewsTable } = await import('components/Resources/Jobs/NestedPages/Views/ViewsTable')
                            return { element: <ViewsTable /> }
                        },
                    },
                    {
                        id: 'viewCreate',
                        path: 'new',
                        async lazy() {
                            const { ViewPage } = await import('components/Resources/Jobs/NestedPages/Views/ViewPage')
                            return { element: <ViewPage /> }
                        },
                        loader: () => ({
                            name: 'Новый вид работ',
                            path: 'resources/jobs/views/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { ViewPageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Views/PageBody/ViewPageBodyEdit')
                                    return { element: <ViewPageBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'viewById',
                        path: ':viewId',
                        async lazy() {
                            const { ViewPage } = await import('components/Resources/Jobs/NestedPages/Views/ViewPage')
                            return { element: <ViewPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.viewId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.viewsReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/jobs/views/${params.viewId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'viewView',
                                async lazy() {
                                    const { ViewPageBodyView } = await import('components/Resources/Jobs/NestedPages/Views/PageBody/ViewPageBodyView')
                                    return { element: <ViewPageBodyView /> }
                                },
                            },
                            {
                                id: 'viewEdit',
                                path: 'edit',
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ViewPageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Views/PageBody/ViewPageBodyEdit')
                                            return { element: <ViewPageBodyEdit /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'jobsTypes',
                path: 'types',
                element: <Outlet />,
                loader: () => ({
                    name: 'Типы работ',
                    path: 'resources/jobs/types',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { JobTypesTable } = await import('components/Resources/Jobs/NestedPages/Types/JobTypesTable')
                            return { element: <JobTypesTable /> }
                        },
                    },
                    {
                        id: 'jobTypeCreate',
                        path: 'new',
                        async lazy() {
                            const { JobTypePage } = await import('components/Resources/Jobs/NestedPages/Types/JobTypePage')
                            return { element: <JobTypePage /> }
                        },
                        loader: () => ({
                            name: 'Новый тип работ',
                            path: 'resources/jobs/types/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { JobTypePageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Types/PageBody/JobTypePageBodyEdit')
                                    return { element: <JobTypePageBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'jobTypeById',
                        path: ':typeId',
                        async lazy() {
                            const { JobTypePage } = await import('components/Resources/Jobs/NestedPages/Types/JobTypePage')
                            return { element: <JobTypePage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.typeId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.jobTypesReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/jobs/types/${params.typeId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'jobTypeView',
                                async lazy() {
                                    const { JobTypePageBodyView } = await import('components/Resources/Jobs/NestedPages/Types/PageBody/JobTypePageBodyView')
                                    return { element: <JobTypePageBodyView /> }
                                },
                            },
                            {
                                id: 'jobTypeEdit',
                                path: 'edit',
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { JobTypePageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Types/PageBody/JobTypePageBodyEdit')
                                            return { element: <JobTypePageBodyEdit /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'jobsPackages',
                path: 'packages',
                element: <Outlet />,
                loader: () => ({
                    name: 'Типовые пакеты',
                    path: 'resources/jobs/packages',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { PackagesTable } = await import('components/Resources/Jobs/NestedPages/Packages/PackagesTable')
                            return { element: <PackagesTable /> }
                        },
                    },
                    {
                        id: 'jobPackageCreate',
                        path: 'new',
                        async lazy() {
                            const { PackagePage } = await import('components/Resources/Jobs/NestedPages/Packages/PackagePage')
                            return { element: <PackagePage /> }
                        },
                        loader: () => ({
                            name: 'Новый типовой пакет',
                            path: 'resources/jobs/packages/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { PackagePageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Packages/PageBody/PackagePageBodyEdit')
                                    return { element: <PackagePageBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'jobPackageById',
                        path: ':packageId',
                        async lazy() {
                            const { PackagePage } = await import('components/Resources/Jobs/NestedPages/Packages/PackagePage')
                            return { element: <PackagePage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.packageId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.packagesReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/jobs/packages/${params.packageId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'jobPackageView',
                                async lazy() {
                                    const { PackagePageBodyView } = await import('components/Resources/Jobs/NestedPages/Packages/PageBody/PackagePageBodyView')
                                    return { element: <PackagePageBodyView /> }
                                },
                            },
                            {
                                id: 'jobPackageEdit',
                                path: 'edit',
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { PackagePageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Packages/PageBody/PackagePageBodyEdit')
                                            return { element: <PackagePageBodyEdit /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: 'jobsFormulas',
                path: 'formulas',
                element: <Outlet />,
                loader: () => ({
                    name: 'Формулы',
                    path: 'resources/jobs/formulas',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { FormulasPage } = await import('components/Resources/Jobs/NestedPages/Formulas/FormulasPage.tsx')
                            return { element: <FormulasPage /> }
                        },

                    },
                    {
                        id: 'formulaCreate',
                        path: 'new',
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { FormulasPageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Formulas/PageBody/FormulasPageBodyEdit.tsx')
                                    return { element: <FormulasPageBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'formulaById',
                        path: ':formulaId',
                        async lazy() {
                            const { FormulaPage } = await import('components/Resources/Jobs/NestedPages/Formulas/PageBody/FormulaPage.tsx')
                            return { element: <FormulaPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.formulaId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.formulasReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/jobs/formulas/${params.formulaId}`,
                        }),
                        children: [
                            {
                                id: 'formulaEdit',
                                path: 'edit',
                                async lazy() {
                                    const { FormulasPageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Formulas/PageBody/FormulasPageBodyEdit.tsx')
                                    return { element: <FormulasPageBodyEdit /> }
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: 'jobsGroups',
                path: 'groups',
                element: <Outlet />,
                loader: () => ({
                    name: 'Группы работ',
                    path: 'resources/jobs/groups',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { GroupsTable } = await import('components/Resources/Jobs/NestedPages/Groups/GroupsTable.tsx')
                            return { element: <GroupsTable /> }
                        },
                    },
                    {
                        id: 'jobsGroupsCreate',
                        path: 'new',
                        async lazy() {
                            const { GroupsPage } = await import('components/Resources/Jobs/NestedPages/Groups/GroupsPage.tsx')
                            return { element: <GroupsPage /> }
                        },
                        loader: () => ({
                            name: 'Новая группа работ',
                            path: 'resources/jobs/groups/new',
                        }),
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { GroupsPageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Groups/PageBody/GroupsPageBodyEdit.tsx')
                                    return { element: <GroupsPageBodyEdit /> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'jobGroupById',
                        path: ':groupId',
                        async lazy() {
                            const { GroupsPage } = await import('components/Resources/Jobs/NestedPages/Groups/GroupsPage.tsx')
                            return { element: <GroupsPage /> }
                        },
                        loader: ({ params }) => ({
                            id: params.groupId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state.groupsReducer.editableFormHeader.name || '',
                            }))),
                            path: `resources/jobs/groups/${params.groupId}`,
                        }),
                        children: [
                            {
                                index: true,
                                id: 'jobGroupView',
                                async lazy() {
                                    const { GroupsPageBodyView } = await import('components/Resources/Jobs/NestedPages/Groups/PageBody/GroupsPageBodyView.tsx')
                                    return { element: <GroupsPageBodyView /> }
                                },
                            },
                            {
                                id: 'jobGroupEdit',
                                path: 'edit',
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { GroupsPageBodyEdit } = await import('components/Resources/Jobs/NestedPages/Groups/PageBody/GroupsPageBodyEdit.tsx')
                                            return { element: <GroupsPageBodyEdit /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: 'operations',
        path: 'operations',
        element: <Outlet />,
        loader: () => ({
            name: 'Операции',
            path: 'resources/operations',
        }),
        children: [
            {
                index: true,
                element: <>Operations page</>,
            },
        ],
    },
]
