import { WorkerTasksSliceInitialState } from 'components/Worker/Task/WorkerTask.types'
import { workerTaskFormHeaderInitialState, workerTaskFormBodyInitialState } from 'components/Worker/Task/WorkerTask.utils.ts'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const workerTaskSliceInitialState: WorkerTasksSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: workerTaskFormHeaderInitialState,
    editableFormBody: workerTaskFormBodyInitialState,
}

export const workerTaskSlice = createAppSlice<WorkerTasksSliceInitialState>('workerTask', workerTaskSliceInitialState)


export default workerTaskSlice.reducer
