import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { FilterParam, SortParam } from 'types/inputs'
import { FormBasedSliceInitialState } from 'types/redux'

export const createFormBasedSliceReducers = <InitialState extends FormBasedSliceInitialState>() => ({
    setEditableFormHeader: (state: Draft<InitialState>, action: PayloadAction<Partial<InitialState['editableFormHeader']>>) => ({
        ...state,
        editableFormHeader: { ...state.editableFormHeader, ...action.payload },
    }),
    setEditableFormBody: (state: Draft<InitialState>, action: PayloadAction<Partial<InitialState['editableFormBody']>>) => ({
        ...state,
        editableFormBody: { ...state.editableFormBody, ...action.payload },
    }),
    setGlobalFilterParam: (state: Draft<InitialState>, action: PayloadAction<FilterParam>) => {
        const filterIndex = state.filterParamsArray.findIndex(
            (filter) => filter.param === action.payload.param,
        )
        if (filterIndex > -1) {

            // SPECIAL CASE FOR DATE TIME INTERVALS
            if(action.payload.rule === 'gte' || action.payload.rule === 'lte') {
                const filterIndexWithRule = state.filterParamsArray.findIndex(
                    (filter) => filter.param === action.payload.param && filter.rule === action.payload.rule,
                )

                if(filterIndexWithRule > -1) {
                    state.filterParamsArray[filterIndexWithRule] = action.payload
                } else {
                    state.filterParamsArray.push(action.payload)
                }
            } else {
                state.filterParamsArray[filterIndex] = action.payload
            }
        } else {
            state.filterParamsArray.push(action.payload)
        }
    },
    deleteGlobalFilterParam: (state: Draft<InitialState>, action: PayloadAction<FilterParam['param']>) => {
        // const filterIndex = state.filterParamsArray.findIndex(
        //     (filter) => filter.param === action.payload,
        // )
        // if (filterIndex > -1) {
        //     state.filterParamsArray.splice(filterIndex, 1)
        // }

        state.filterParamsArray = state.filterParamsArray.filter((filter) => filter.param !== action.payload)
    },
    setPermanentFilterParam: (state: Draft<InitialState>, action: PayloadAction<FilterParam>) => {
        if (state.permanentFilterParamsArray) {
            const filterIndex = state.permanentFilterParamsArray?.findIndex(
                (filter) => filter.param === action.payload.param,
            )
            if (filterIndex > -1) {
                state.permanentFilterParamsArray[filterIndex] = action.payload
            } else {
                state.permanentFilterParamsArray.push(action.payload)
            }
        }
    },
    deletePermanentFilterParam: (state: Draft<InitialState>, action: PayloadAction<FilterParam['param']>) => {
        if (state?.permanentFilterParamsArray) {
            const filterIndex = state.permanentFilterParamsArray.findIndex(
                (filter) => filter.param === action.payload,
            )
            if (filterIndex > -1) {
                state.permanentFilterParamsArray.splice(filterIndex, 1)
            }
        }
    },
    storeSortParams: (state: Draft<InitialState>, action: PayloadAction<SortParam[]>) => {
        state.sortParamsArray = action.payload
    },
    setShouldResetGlobalFilterParams: (state: Draft<InitialState>, action: PayloadAction<InitialState['shouldResetGlobalFilterParams']>) => {
        state.shouldResetGlobalFilterParams = action.payload
    },
    setIsFormEditAllowed: (state: Draft<InitialState>, action: PayloadAction<InitialState['isFormEditAllowed']>) => {
        state.isFormEditAllowed = action.payload
    },
})
