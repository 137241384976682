import {
    FinanceSettingsFormBody,
} from 'components/Settings/NestedPages/Finance/FinancePage.types.ts';

// *
// STORE
// *

export const financeSettingsFormBodyInitialState: FinanceSettingsFormBody = {
    speed: '',
    quality: ''
}