import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs.tsx';
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts';
import {
    SupplyRequestEditAutocompleteKey,
    SupplyRequestEditFormBody,
    SupplyRequestEditFormGlobal
} from 'components/Supply/SupplyRequests/PageBody/List/SupplyRequestListEdit.types.ts';
import { FindMaterialResponseDto } from 'store/api/materialVariants.tsx';

// *
// STORE
// *

export const supplyRequestEditFormBodyInitialState: SupplyRequestEditFormBody = {
    destination: autocompleteFieldDefault
}

// *
// INPUT PROCESSING FUNCS
// *


type SupplyRequestEditAutocompleteSubmitData = AutocompleteSubmitData & { label: string }


export const onSupplyRequestEditBodyAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<SupplyRequestEditAutocompleteKey, SupplyRequestEditFormGlobal>) =>
    (data: SupplyRequestEditAutocompleteSubmitData) => {
        if(data.value && data.label) {
            setFormValue(key, autocompleteSubmitArg({ id: data.value, value: data.label }))
            return
        }
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onSupplyRequestEditBodyAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<SupplyRequestEditAutocompleteKey, SupplyRequestEditFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }

export type ReplaceMaterialType = FindMaterialResponseDto & {
    showInput?: boolean
}

export type AdjustmentMaterialType = FindMaterialResponseDto & {
    count: number
}

