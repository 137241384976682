
import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import { createAppSlice } from 'utils/createAppSlice.ts';
import {
    MaterialsAnalogueSliceInitialState
} from 'components/Resources/Materials/PageBody/Analogues/MaterialsAnalogue.types.ts';
import {
    materialAnalogueFormBodyInitialState, materialAnalogueFormHeaderInitialState
} from 'components/Resources/Materials/PageBody/Analogues/MaterialsAnalogue.utils.ts';

const materialAnalogueInitialState: MaterialsAnalogueSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: materialAnalogueFormHeaderInitialState,
    editableFormBody: materialAnalogueFormBodyInitialState,
}

export const materialAnalogueSlice = createAppSlice<MaterialsAnalogueSliceInitialState>('materialAnalogue', materialAnalogueInitialState)


export default materialAnalogueSlice.reducer
