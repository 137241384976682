import { Button, Flex, Text } from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import NotFound from '../../assets/icons/notFound.svg'
import { useMediaQuery } from '@mantine/hooks';
import { MEDIA_MAX } from 'styles/constants.ts';

export const Page404: React.FC = () => {
    const isSmScreen = useMediaQuery(MEDIA_MAX.SM)
    const navigate = useNavigate()

    return (
        <Flex h={'calc(100vh - 200px)'} w={'100%'} align={'center'} justify={'center'} direction={'column'} gap={16}>
            <Text fz={isSmScreen ? 48 : 70} fw={isSmScreen ? 500 : 700} color={'natural.4'}>Ошибка 404</Text>
            <NotFound/>
            <Button miw={isSmScreen ? 'none' : 350} mt={32} variant={'primary'} size={'md'} onClick={() => navigate('/') }>Вернуться на главную</Button>
        </Flex>
    )
}
