import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { ContractorsSliceInitialState } from 'components/Finance/Contractors/Contractors.types.ts';

const contractorFinancesSliceInitialState: ContractorsSliceInitialState = {
    ...defaultInitialState,
    sortParamsArray: [
        {
            param: 'work.to',
            rule: 'asc',
        },
    ],
}

export const contractorFinancesSlice = createAppSlice<ContractorsSliceInitialState>('contractorFinances', contractorFinancesSliceInitialState)


export default contractorFinancesSlice.reducer
