import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['Performer'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            performerFindByProject: build.query<
                PerformerFindByProjectApiResponse,
                PerformerFindByProjectApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/performer/${queryArg.id}`,
                    params: { from: queryArg['from'], to: queryArg.to },
                }),
                providesTags: ['Performer'],
            }),
            performerFind: build.query<PerformerFindApiResponse, PerformerFindApiArg>(
                {
                    query: (queryArg) => ({
                        url: '/api/v1/performer',
                        params: { from: queryArg['from'], to: queryArg.to },
                    }),
                    providesTags: ['Performer'],
                }
            ),
            performerFindOutdated: build.query<
                PerformerFindOutdatedApiResponse,
                PerformerFindOutdatedApiArg
            >({
                query: () => ({ url: '/api/v1/performer/outdated' }),
                providesTags: ['Performer'],
            }),
            performerFinance: build.query<
                PerformerFinanceApiResponse,
                PerformerFinanceApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/performer/finance/${queryArg.id}`,
                    params: { from: queryArg['from'], to: queryArg.to },
                }),
                providesTags: ['Performer'],
            }),
            performerFinanceByYear: build.query<
                PerformerFinanceByYearApiResponse,
                PerformerFinanceByYearApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/performer/finance/year/${queryArg.id}`,
                    params: { date: queryArg.date },
                }),
                providesTags: ['Performer'],
            }),
            performerOccupation: build.query<
                PerformerOccupationApiResponse,
                PerformerOccupationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/performer/occupation/${queryArg.id}`,
                    params: {
                        from: queryArg['from'],
                        to: queryArg.to,
                        view: queryArg.view,
                    },
                }),
                providesTags: ['Performer'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as performerApi };
export type PerformerFindByProjectApiResponse =
  /** status 200  */ FindPerformerResponseDto[];
export type PerformerFindByProjectApiArg = {
    from: string;
    to: string;
    id: string;
};
export type PerformerFindApiResponse = /** status 200  */ {
    meta?: ResponseMetadata;
    data?: {
        [key: string]: FindWorkByPerformerResponseDto[] | number;
    };
};
export type PerformerFindApiArg = {
    from: string;
    to: string;
};
export type PerformerFindOutdatedApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: PerformerOutdatedResponseDto[];
};
export type PerformerFindOutdatedApiArg = void;
export type PerformerFinanceApiResponse =
  /** status 200  */ PerformerFinanceResponseDto;
export type PerformerFinanceApiArg = {
    from: string;
    to: string;
    id: string;
};
export type PerformerFinanceByYearApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: PerformerFinanceByYearResponseDto;
};
export type PerformerFinanceByYearApiArg = {
    date: string;
    id: string;
};
export type PerformerOccupationApiResponse =
  /** status 200  */ PerformerOccupationResponseDto;
export type PerformerOccupationApiArg = {
    from: string;
    to: string;
    view: 'month' | 'week';
    id: string;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindPerformerResponseDto = {
    id: string;
    name: string;
    email: string;
    avatar: FindFileResponseDto;
    isOccupied: boolean;
    occupied: string;
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type ResponseMetadata = {
    /** Идентификатор данных */
    id: string;
    /** Права доступа */
    permissions: boolean;
};
export type JobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindMaterialTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    jobType: JobTypeResponseDto;
};
export type FindJobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    materialType: FindMaterialTypeResponseDto;
};
export type FindProjectTaskStatusResponseDto = {
    id: string;
    name: string;
    description: string;
    type:
    | 'PREPARATION'
    | 'WAIT'
    | 'DONE'
    | 'PROBLEM'
    | 'CONTROL'
    | 'REVISION'
    | 'PAID';
};
export type FindWorkPartResponseDto = {
    from: string;
    to: string;
    id: string;
    size: number;
    row?: number;
    isResized?: boolean | null;
    isMoved?: number | null;
};
export type FindPackageResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindJobViewResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindWorkStageResponseDto = {
    id: string;
    name: string;
    description: string;
    sort: number;
};
export type FindJobSurfaceResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindClientKindResponseDto = {
    id: string;
    name: string;
};
export type FindClientTaxationSystemResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindDepartmentResponseDto = {
    id: string;
    name: string;
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type FindRolePermissionDto = {
    id: string;
    name: string;
    description: string;
    allow: boolean;
};
export type FindRolePermissionGroupDto = {
    name: string;
    permissions: FindRolePermissionDto[];
};
export type FindRoleResponseDto = {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
    type: 'MANAGER' | 'WORKER';
    organization: FindOrganizationResponseDto;
    permissions: FindRolePermissionGroupDto[];
};
export type FindUserResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    email: string;
    phone: string | null;
    job: string | null;
    chatId: number | null;
    deleted: boolean;
    compensationPercent: number;
    compensationPercentUpdatedAt: string | null;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    department: FindDepartmentResponseDto;
    specializations: FindSpecializationResponseDto[];
    avatar: FindFileResponseDto;
    roles: FindRoleResponseDto[];
    organizations: FindOrganizationResponseDto[];
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    workingDays: (number[][] | (string | null))[];
};
export type FindClientContactResponseDto = {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    position: string;
    phone: string | null;
    email: string | null;
    website: string | null;
    company: string | null;
    note: string | null;
};
export type FindClientBankResponseDto = {
    id: string;
    name: string;
    abbreviatedName: string;
    bik: string;
    ks?: string | null;
    index?: string | null;
    city?: string | null;
    address?: string | null;
    phone?: string | null;
    okato?: string | null;
    okpo?: string | null;
    regNum?: string | null;
};
export type FindAccountItemResponseDto = {
    id: string;
    number: string;
    bank: FindClientBankResponseDto;
};
export type FindClientTypeResponseDto = {
    id: string;
    name: string;
};
export type FindClientResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    name: string;
    middleName: string | null;
    registrationAddress: string | null;
    birthDate: string | null;
    passportNumber: number | null;
    passportIssuer: string | null;
    passportIssueDate: string | null;
    passportIssuingUnitCode: string | null;
    fullName: string | null;
    abbreviatedName: string | null;
    legalAddress: string | null;
    inn: string;
    kpp: string | null;
    ogrn: string | null;
    okved: string | null;
    okpo: string | null;
    okato: string | null;
    deleted: boolean;
    kind: FindClientKindResponseDto;
    taxationSystem: FindClientTaxationSystemResponseDto;
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    contact: FindClientContactResponseDto;
    account: FindAccountItemResponseDto;
    types: FindClientTypeResponseDto[];
    organization: FindOrganizationResponseDto;
    contacts: FindClientContactResponseDto[];
    accounts: FindAccountItemResponseDto[];
};
export type FindObjectResponseDto = {
    id: string;
    name: string;
    deleted: boolean;
    address: string;
    fullAddress: string;
    city: string;
    street: string;
    corpus: string;
    building: string;
    house: string;
    entrance: number;
    floor: number;
    apartment: number;
    intercomCode: string;
    comment: string;
    longitude: number;
    latitude: number;
    client: FindClientResponseDto;
};
export type FindRoomResponseDto = {
    id: string;
    name: string;
    square: number;
    deleted: boolean;
    object: FindObjectResponseDto;
    files: FindFileResponseDto[];
};
export type FormulaCalculateRequestDto = {
    id: string;
    name: string;
    colour: string;
    operator?: string | null;
    type?:
    | (
        | 'PLUS'
        | 'MINUS'
        | 'MULTIPLY'
        | 'DIVIDE'
        | 'MATH_FLOOR'
        | 'MATH_CEIL'
        | 'MATH_ROUND'
        | 'MATH_SQRT'
        | 'NUMBER_SQUARED'
        | 'NUMBER_POWER'
        | 'CONDITION'
        | 'LEFT_PARENTHESIS'
        | 'RIGHT_PARENTHESIS'
        | 'MORE'
        | 'LESS'
      )
    | null;
    value?:
    | (
        | number
        | FormulaCalculateRequestDto
        | (number | FormulaCalculateRequestDto)[]
        | (number | FormulaCalculateRequestDto)[][]
      )
    | null;
};
export type WorkFormulaCalculateRequestDto = {
    id: string;
    name: string;
    colour: string;
    operator?: string | null;
    type?:
    | (
        | 'PLUS'
        | 'MINUS'
        | 'MULTIPLY'
        | 'DIVIDE'
        | 'MATH_FLOOR'
        | 'MATH_CEIL'
        | 'MATH_ROUND'
        | 'MATH_SQRT'
        | 'NUMBER_SQUARED'
        | 'NUMBER_POWER'
        | 'CONDITION'
        | 'LEFT_PARENTHESIS'
        | 'RIGHT_PARENTHESIS'
        | 'MORE'
        | 'LESS'
      )
    | null;
    value?:
    | (
        | number
        | FormulaCalculateRequestDto
        | (number | FormulaCalculateRequestDto)[]
        | (number | FormulaCalculateRequestDto)[][]
      )
    | null;
};
export type FindFormulaTypeResponseDto = {
    id: string;
    name: string;
    colour: string;
    type:
    | 'CONSTANT'
    | 'MATERIAL_PARAMETER'
    | 'MATERIAL_COUNT'
    | 'SPECIAL'
    | 'OPERATION'
    | 'LEGKOREM';
    description: string;
};
export type FindMaterialStocksResponseDto = {
    id: string;
    title: string;
    remainsAmount: number;
};
export type FindCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
};
export type FindStoreResponseDto = {
    id: string;
    name: string;
    url: string | null;
};
export type FindStoreCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
    store: FindStoreResponseDto;
};
export type FindBrandResponseDto = {
    id: string;
    name: string;
    url: string | null;
    image: FindFileResponseDto | null;
};
export type FindInventoryTypeResponseDto = {
    id: string;
    name: string;
    type: 'MATERIAL' | 'PRODUCT';
};
export type FindMaterialPackResponseDto = {
    id: string;
    packing: number;
    unitMeasure: string;
    price: number;
    tradePrice: number;
    default: boolean;
    proportion: number;
};
export type FindMaterialResponseDto = {
    id: string;
    updatedAt: string;
    sku: string | null;
    name: string;
    description: string;
    url: string;
    price: number;
    discountPrice: number | null;
    isChecked: boolean;
    isAvailable: boolean;
    images: string[];
    isAccountable: boolean;
    stocks: FindMaterialStocksResponseDto[];
    category: FindCategoryResponseDto;
    storeCategory: FindStoreCategoryResponseDto;
    store: FindStoreResponseDto;
    brand: FindBrandResponseDto;
    type: FindMaterialTypeResponseDto;
    package: FindPackageResponseDto;
    inventoryType: FindInventoryTypeResponseDto;
    packs: FindMaterialPackResponseDto[];
};
export type FindUnitsResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindWorkFormulaResponseDto = {
    id: string;
    name: string;
    value: (WorkFormulaCalculateRequestDto | number)[];
    type: FindFormulaTypeResponseDto;
    work?: FindWorkResponseDto;
    material: FindMaterialResponseDto;
    units: FindUnitsResponseDto;
};
export type FindProjectStatusResponseDto = {
    id: string;
    name: string;
    description: string;
    order: number;
    type:
    | 'PRESALE'
    | 'PLANNING'
    | 'REALIZATION'
    | 'PAUSE'
    | 'DELIVERY'
    | 'STOPPED'
    | 'WARRANTY'
    | 'DONE';
};
export type FindGroupResponseDto = {
    id: string;
    name: string;
};
export type FindProjectResponsibleResponseDto = {
    id: string;
    type: 'MANAGER' | 'FOREMAN';
    user: FindUserResponseDto;
    userGroup: FindGroupResponseDto;
};
export type FindProjectResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    from: string;
    to: string;
    workingHours: number[][];
    overhead: number;
    transportCosts: number;
    emergencyPhoneNumber: string;
    status: FindProjectStatusResponseDto;
    client: FindClientResponseDto;
    organization: FindOrganizationResponseDto;
    object: FindObjectResponseDto;
    responsibles: FindProjectResponsibleResponseDto[];
    images: FindFileResponseDto[];
};
export type FindJobStageResponseDto = {
    id: string;
    name: string;
    description: string;
    sort: number;
};
export type FindJobResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    insideDescription?: string | null;
    clientDescription?: string | null;
    materialsCount: number;
    isNoisiness: boolean;
    isInvisible: boolean;
    type: FindJobTypeResponseDto;
    package?: FindPackageResponseDto | null;
    view?: FindJobViewResponseDto | null;
    stage: FindJobStageResponseDto;
    surfaces?: FindJobSurfaceResponseDto[] | null;
    specializations: FindSpecializationResponseDto[];
    organization: FindOrganizationResponseDto;
    groups: FindJobGroupResponseDto[];
};
export type FindJobGroupResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    jobs: FindJobResponseDto[];
};
export type FindWorkResponseDto = {
    id: string;
    jobId?: string | null;
    name: string;
    insideDescription: string;
    clientDescription: string;
    materialsCount: number;
    isNoisiness: boolean;
    isInvisible: boolean;
    duration: number;
    from?: string | null;
    to?: string | null;
    fact?: string | null;
    onControl?: string | null;
    factLag?: number | null;
    model?: ('RESOURCE' | 'CUSTOM') | null;
    status?:
    | (
        | 'PREPARATION'
        | 'WAIT'
        | 'DONE'
        | 'PROBLEM'
        | 'CONTROL'
        | 'REVISION'
        | 'PAID'
      )
    | null;
    money?: number | null;
    lag?: number | null;
    workingHours: number[][];
    parts?: FindWorkPartResponseDto[] | null;
    type: FindJobTypeResponseDto;
    package: FindPackageResponseDto;
    view: FindJobViewResponseDto;
    stage: FindWorkStageResponseDto;
    surfaces: FindJobSurfaceResponseDto[];
    organization: FindOrganizationResponseDto;
    room: FindRoomResponseDto;
    formulas: FindWorkFormulaResponseDto[];
    tasks: FindProjectTaskResponseDto[];
    specializations: FindSpecializationResponseDto[];
    performer: FindUserResponseDto | null;
    performers: FindUserResponseDto[];
    project: FindProjectResponseDto;
    jobGroup: FindJobGroupResponseDto;
};
export type FindProjectTaskMaterialResponseDto = {
    id: string;
    price: number;
    discountPrice?: number | null;
    material: FindMaterialResponseDto;
};
export type FindProjectTaskResponseDto = {
    id: string;
    updatedAt: string;
    name: string;
    description: string;
    workTime: number;
    status: FindProjectTaskStatusResponseDto;
    work?: FindWorkResponseDto | null;
    taskMaterials: FindProjectTaskMaterialResponseDto[];
    previous?: FindProjectTaskResponseDto | null;
};
export type FindWorkByPerformerResponseDto = {
    id: string;
    name: string;
    insideDescription: string;
    isNoisiness: boolean;
    isInvisible: boolean;
    duration: number;
    money: number;
    workingHours: string[];
    value: string;
    unitPrice: number;
    type: FindJobTypeResponseDto;
    tasks: FindProjectTaskResponseDto[];
    room: FindRoomResponseDto;
    project: FindProjectResponseDto;
    parts: FindWorkPartResponseDto[];
    status?:
    | (
        | 'PREPARATION'
        | 'WAIT'
        | 'DONE'
        | 'PROBLEM'
        | 'CONTROL'
        | 'REVISION'
        | 'PAID'
      )
    | null;
};
export type PerformerWorkOutdatedResponseDto = {
    id: string;
    name: string;
    address: string;
    money: number;
    from?: string | null;
    to?: string | null;
    lag?: number | null;
    factLag?: number | null;
    status?:
    | (
        | 'PREPARATION'
        | 'WAIT'
        | 'DONE'
        | 'PROBLEM'
        | 'CONTROL'
        | 'REVISION'
        | 'PAID'
      )
    | null;
    tasks: FindProjectTaskResponseDto[];
};
export type PerformerOutdatedResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    from: string;
    to: string;
    workingHours: number[][];
    overhead: number;
    transportCosts: number;
    emergencyPhoneNumber: string;
    status: FindProjectStatusResponseDto;
    client: FindClientResponseDto;
    organization: FindOrganizationResponseDto;
    object: FindObjectResponseDto;
    responsibles: FindProjectResponsibleResponseDto[];
    images: FindFileResponseDto[];
    works: PerformerWorkOutdatedResponseDto[];
};
export type PerformerFinanceWork = {
    /** ID */
    id: string;
    /** Name */
    name: string;
    deadline: string;
    acceptance: string;
    value: string;
    unitPrice: number;
    progress: number;
    /** За работу */
    price: number;
    /** Итого */
    summary: number;
    /** Фактическая премия */
    bonus: number;
    bestBonus: number;
    /** Амортизация */
    compensation: number;
    status:
    | 'PREPARATION'
    | 'WAIT'
    | 'DONE'
    | 'PROBLEM'
    | 'CONTROL'
    | 'REVISION'
    | 'PAID';
    isPerformer: boolean;
};
export type PerformerFinanceRoom = {
    /** ID */
    id: string;
    /** Name */
    name: string;
    works: PerformerFinanceWork[];
};
export type PerformerFinanceProjectSummary = {
    /** К выплате */
    payToday: number;
    /** Не завершено */
    notClosed: number;
    /** Оплачено */
    payed: number;
};
export type PerformerFinanceProject = {
    /** ID */
    id: string;
    /** Name */
    name: string;
    rooms: PerformerFinanceRoom[];
    summary: PerformerFinanceProjectSummary[];
};
export type PerformerFinanceSummary = {
    /** К выплате */
    payToday: number;
    /** Не завершено */
    notClosed: number;
    /** Оплачено */
    payed: number;
    /** Общая сумма */
    summary: number;
    summaryBest: number;
    /** Амортизация */
    compensation: number;
    /** Оплаченная амортизация */
    payedCompensation: number;
    /** Амортизация к выплате */
    payTodayCompensation: number;
    /** Прогноз амортизации */
    notClosedCompensation: number;
    /** Премия */
    bonus: number;
    bestBonus: number;
    /** Оплаченная премия */
    payedBonus: number;
    payedBestBonus: number;
    /** Премия к выплате */
    payTodayBonus: number;
    payTodayBestBonus: number;
    /** Прогноз премии */
    notClosedBonus: number;
    notClosedBestBonus: number;
};
export type PerformerFinanceResponseDto = {
    data: PerformerFinanceProject[];
    summary: PerformerFinanceSummary;
};
export type PerformerFinanceByYear = {
    payToday: number;
    payed: number;
};
export type PerformerFinanceByYearSummary = {
    summary: number;
    bestSummary: number;
};
export type PerformerFinanceByYearResponseDto = {
    chart: {
        [key: string]: PerformerFinanceByYear[];
    };
    summary: PerformerFinanceByYearSummary;
};
export type ShortEntity = {
    /** ID */
    id: string;
    /** Name */
    name: string;
};
export type PerformerOccupation = {
    /** Идентификатор работы */
    id: string;
    /** Название работы */
    name: string;
    /** Адрес объекта работы */
    address: string;
    /** Прогресс работы */
    progress: number;
    /** Цена работы */
    price: number;
    /** Проект */
    project: ShortEntity | null;
    /** Время начала на этот день */
    from: string;
    /** Время окончания на этот день */
    to: string;
    /** Аккумулированный статус из задач */
    status:
    | 'PREPARATION'
    | 'WAIT'
    | 'DONE'
    | 'PROBLEM'
    | 'CONTROL'
    | 'REVISION'
    | 'PAID';
};
export type PerformerOccupationResponseDto = {
    data: {
        [key: string]: PerformerOccupation[];
    };
};
export const {
    usePerformerFindByProjectQuery,
    useLazyPerformerFindByProjectQuery,
    usePerformerFindQuery,
    useLazyPerformerFindQuery,
    usePerformerFindOutdatedQuery,
    useLazyPerformerFindOutdatedQuery,
    usePerformerFinanceQuery,
    useLazyPerformerFinanceQuery,
    usePerformerFinanceByYearQuery,
    useLazyPerformerFinanceByYearQuery,
    usePerformerOccupationQuery,
    useLazyPerformerOccupationQuery,
} = injectedRtkApi;
