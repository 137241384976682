import React, { useMemo } from 'react'
import styles from 'components/Worker/Dashboard.module.css'
import { Box, Divider, Flex, Text } from '@mantine/core'
import classNames from 'classnames'
import dayjs from 'utils/dayjs.ts'
import { WorkCardProps } from 'components/Worker/Calendar/Types/WorkCardProps'
import { WorkStatusComponent } from 'components/Worker/Work/WorkStatus.tsx'
import { IconCube, IconMapPin } from '@tabler/icons-react';
import { formatMoneyValue } from 'components/Worker/Profile/Money/MoneyTab.utils.tsx';
// import { WorkMoneyComponent } from 'components/Worker/Work/WorkMoney'


export const WorkCardCalendar: React.FC<WorkCardProps> = ({ date, work, isActive }: WorkCardProps) => {

    // const isSmScreen = useMediaQuery(MEDIA_MAX.SM)
    
    const workTime= useMemo(() => {
        const partsInDay = work?.parts?.filter((part) => dayjs(date, 'YYYY-MM-DD').isSame(dayjs(part.from, 'YYYY-MM-DD HH:mm'), 'day') && dayjs(date, 'YYYY-MM-DD').isSame(dayjs(part.to, 'YYYY-MM-DD HH:mm'), 'day'))
        return  partsInDay && partsInDay.length ? `${ dayjs(partsInDay[0].from, 'YYYY-MM-DD'
            + ' HH:mm').format('HH:mm') } - ${ dayjs(partsInDay[partsInDay.length - 1].to, 'YYYY-MM-DD HH:mm').format('HH:mm') }` : 'Ошибка времени'
    }, [work])
    
    return (
        <Box className={
            classNames(styles.calendarWork, {
                [styles.calendarWorkActive]: isActive,
            })
        }>
            <Flex direction={'row'} justify={'space-between'} align={'center'}>
                
                <Box className={ styles.workTime }>
                    { workTime }
                </Box>
                
                <Flex justify="flex-end" align="center" gap={8}>
                    { work?.status && <WorkStatusComponent workStatus={work.status} /> }
                    
                    {/*{ work?.isShowMoney &&*/}
                    {/*    <WorkMoneyComponent workStatus={work.status} workMoney={work.money} />*/}
                    {/*}*/}
                </Flex>

            </Flex>
            
            <Box className={ styles.workName }>
                { work?.name }
            </Box>

            <Flex gap={4} align={'center'}>
                <IconMapPin className={styles.additionalInfoIcon}/>
                <Text className={styles.additionalInfoText}>{ work?.project?.object?.address }, кв. { work?.project?.object?.apartment }</Text>
            </Flex>

            <Flex gap={6} align={'center'}>
                <Flex gap={4} align={'center'}>
                    <IconCube className={styles.additionalInfoIcon}/>
                    <Text className={styles.additionalInfoText}>{ work?.room?.name || '-' }</Text>
                </Flex>
                <Divider orientation={'vertical'} size={1} color={'custom.0'} h={12} mt={6} />
                <Text className={styles.additionalInfoText}>{`${work?.value ? `${work.value} x ` : ''}${work?.unitPrice ? formatMoneyValue(work.unitPrice) : ''}`}</Text>
            </Flex>
        </Box>
    )
}
