import { createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FindOutlayResponseDto, FindOutlaySummaryResponseDto } from 'store/api/outlays.tsx';

type OutlayInitialState = { outlay: FindOutlayResponseDto | null, compareOutlaySummary: FindOutlaySummaryResponseDto[] }

const initialState: OutlayInitialState = {
    outlay: null,
    compareOutlaySummary: []
}

export const selectedOutlaySlice = createSlice({
    name: 'selectedOutlaySlice',
    initialState,
    reducers: {
        setOutlayToStore: (state: Draft<OutlayInitialState>, action: PayloadAction<FindOutlayResponseDto>) => {
            state.outlay = action.payload
        },
        setCompareOutlaySummaryToStore: (state: Draft<OutlayInitialState>, action: PayloadAction<FindOutlaySummaryResponseDto[]>) => {
            state.compareOutlaySummary = action.payload
        }
    }
})

export default selectedOutlaySlice.reducer
