import { multiselectFieldDefault } from 'utils/inputs.tsx';
import {
    ReplaceMaterialBodyInitialState,
    ReplaceMaterialFormValues,
    ReplaceMaterialHeaderInitialState,
    ReplaceMaterialMultiselectFilterParamKey
} from './ReplaceMaterialDrawer.types.ts';
import { InputChangeArg, InputSubmitArg } from 'types/inputs.ts';
import { FindMaterialResponseDto } from 'store/api/materialVariants.tsx';

export const replaceMaterialHeaderInitialState: ReplaceMaterialHeaderInitialState = {
    name: '',
}

export const replaceMaterialBodyInitialState: ReplaceMaterialBodyInitialState = {
    type: multiselectFieldDefault,
    inventoryType: multiselectFieldDefault,
    isAccountable: false,
    isChecked: false,
}

export const onMaterialTypeMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<ReplaceMaterialMultiselectFilterParamKey, ReplaceMaterialFormValues>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${key}.name`, label)
    }
}
export const onMaterialTypeMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<ReplaceMaterialMultiselectFilterParamKey, ReplaceMaterialFormValues>) => (valueIds: Array<string>) => {
    setFormValue(`${key}.ids`, valueIds)
}

export const replaceMaterialFormDefaultValues: ReplaceMaterialFormValues = {
    name: '',
    type: {
        name: '',
        ids: [],
        alreadySelected: [],
    },
    inventoryType: {
        name: '',
        ids: [],
        alreadySelected: [],
    },
    isAccountable: false,
    isChecked: false,
}

export const getPaginatedFilteredData = (activePage: number, rowsPerPage: number, filteredTableData: FindMaterialResponseDto[]) => {
    const startIdx = (activePage - 1) * rowsPerPage
    const endIdx = (activePage - 1) * rowsPerPage + rowsPerPage
    const copiedFilteredData = [...filteredTableData]
    return copiedFilteredData.slice(startIdx, endIdx )
}