import { Navigate,  RouteObject } from 'react-router-dom';

export const SETTINGS_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={ 'motivation' } />,
    },
    {
        id: 'settingsMotivation',
        path: 'motivation',
        loader: ({ params }) => ({ id: params.id, name: 'Мотивация', path: '/motivation' }),

        async lazy() {
            const { FinancePage } = await import('components/Settings/NestedPages/Finance/FinancePage.tsx')
            return { element: <FinancePage /> }
        },
        children: [
            {
                index: true,
                id: 'settingsFinanceView',
                async lazy() {
                    const { FinancePageBodyView } = await import('components/Settings/NestedPages/Finance/PageBody/FinancePageBodyView.tsx')
                    return { element: <FinancePageBodyView/> }
                },
            },
            {
                id: 'settingsFinanceEdit',
                path: 'edit',
                async lazy() {
                    const { FinancePageBodyEdit } = await import('components/Settings/NestedPages/Finance/PageBody/FinancePageBodyEdit.tsx')
                    return { element: <FinancePageBodyEdit/> }
                },
            },
        ]
    },
]