import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { ObjectCoordinatesFieldKey, ObjectFormBody, ObjectFormHeader, ObjectsAutocompleteKey, ObjectsFormGlobal } from './Objects.types'
import {
    // AutocompleteSubmitData,
    InputChangeArg,
    InputSubmitArg
} from 'types/inputs'
import { FindObjectResponseDto, UpdateObjectRequestDto } from 'store/api/object'


// *
// DATA CONVERT FUNCS
// *

export const convertObjectDtoToFormHeader = (object: FindObjectResponseDto): ObjectFormHeader => ( {
    name: object?.name || objectFormHeaderInitialState.name,
} )
export const convertObjectDtoToFormBody = (object: FindObjectResponseDto): ObjectFormBody => ( {
    address: object?.address || '',
    client: object?.client && {
        name: object.client?.fullName || object.client?.abbreviatedName || object.client?.firstName || '' + ' ' + object.client?.lastName || '',
        data: {
            id: object.client.id,
            name: object.client?.fullName || object.client?.abbreviatedName || object.client?.firstName || '' + ' ' + object.client?.lastName || '',
        },
    } || objectFormBodyInitialState.client,
    latitude: object?.latitude || objectFormBodyInitialState.latitude,
    longitude: object?.longitude || objectFormBodyInitialState.longitude,
    deleted: object?.deleted || objectFormBodyInitialState.deleted,
    city: object?.city || objectFormBodyInitialState.city,
    street: object?.street || objectFormBodyInitialState.street,
    house: object?.house || objectFormBodyInitialState.house,
    apartment: object?.apartment || objectFormBodyInitialState.apartment,
    comment: object?.comment || objectFormBodyInitialState.comment,
    entrance: object?.entrance || objectFormBodyInitialState.entrance,
    floor: object?.floor || objectFormBodyInitialState.floor,
    corpus: object?.corpus || objectFormBodyInitialState.corpus,
    building: object?.building || objectFormBodyInitialState.building,
    intercomCode: object?.intercomCode || objectFormBodyInitialState.intercomCode,
} )
export const convertObjectFormToRequestDto = (data: Partial<ObjectFormBody & ObjectFormHeader>): UpdateObjectRequestDto => ( {
    name: data?.name || objectFormHeaderInitialState.name,
    address: data?.address || objectFormBodyInitialState.address,
    clientId: data?.client?.data?.id || data?.client?.name
        || objectFormBodyInitialState.client.data.id,
    latitude: data?.latitude ? +data?.latitude : objectFormBodyInitialState?.latitude,
    longitude: data?.longitude ? +data?.longitude : objectFormBodyInitialState?.longitude,
    
    city: data?.city || objectFormBodyInitialState.city,
    street: data?.street || objectFormBodyInitialState.street,
    house: data?.house || objectFormBodyInitialState.house,
    apartment: data?.apartment || objectFormBodyInitialState.apartment,
    comment: data?.comment || objectFormBodyInitialState.comment,
    entrance: data?.entrance || objectFormBodyInitialState.entrance,
    floor: data?.floor || objectFormBodyInitialState.floor,
    corpus: data?.corpus || objectFormBodyInitialState.corpus,
    building: data?.building || objectFormBodyInitialState.building,
    intercomCode: data?.intercomCode || objectFormBodyInitialState.intercomCode,
} )

// *
// STORE
// *

export const objectsFormGlobalDefaultValues: ObjectsFormGlobal = {
    name: '',
    address: '',
    latitude: 0,
    longitude: 0,
    client: autocompleteFieldDefault,
    deleted: false,
    
    city: '',
    street: '',
    house: '',
    apartment: 0,
    comment: '',
    entrance: 0,
    floor: 0,
    corpus: '',
    building: '',
    intercomCode: '',
}
export const objectFormHeaderInitialState: ObjectFormHeader = {
    name: '',
}
export const objectFormBodyInitialState: ObjectFormBody = {
    address: '',
    latitude: 0,
    longitude: 0,
    client: autocompleteFieldDefault,
    deleted: false,
    
    city: '',
    street: '',
    house: '',
    apartment: 0,
    comment: '',
    entrance: 0,
    floor: 0,
    corpus: '',
    building: '',
    intercomCode: '',
}


// *
// INPUT PROCESSING FUNCS
// *

export type ObjectAutocompleteSubmitData = {
    id: string
    value: string
    label: string
}

// global
export const onObjectsGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ObjectsAutocompleteKey, ObjectsFormGlobal>) =>
    (data: ObjectAutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg({ id: data.id, value: data.label }))
    }
export const onObjectsGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ObjectsAutocompleteKey, ObjectsFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }

// body

export const onObjectBodyCoordinatesInputChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ObjectCoordinatesFieldKey, ObjectFormBody>) =>
    (label: string) => {
        const numLabel = +label
        if (!isNaN(numLabel) && formData[key] !== numLabel) {
            setFormValue(key, numLabel)
        }
    }

export const onObjectBodyAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ObjectsAutocompleteKey, ObjectFormBody>) =>
    (data: ObjectAutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg({ id: data.id, value: data.label }))
    }
export const onObjectBodyAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ObjectsAutocompleteKey, ObjectFormBody>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
