import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { createElement } from 'react'
import { RootStateType } from 'store/store.ts'
// import { UnderConstruction } from 'components/AbstractComponents/UnderConstruction/UnderConstruction.tsx'

export const SUPPLY_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={ 'warehouses' } />,
    },
    {
        id: 'warehouses',
        path: 'warehouses',
        element: <Outlet />,
        loader: () => ( {
            name: 'Список складов',
            path: 'supply/warehouses',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { WarehousesTable } = await import('components/Supply/Warehouses/WarehousesTable')
                    return { element: <WarehousesTable /> }
                },
            },
            {
                id: 'warehouseCreate',
                path: 'new',
                async lazy() {
                    const { WarehousePage } = await import('components/Supply/Warehouses/WarehousePage')
                    return { element: <WarehousePage /> }
                },
                loader: () => ( {
                    name: 'Новый склад',
                    path: 'supply/warehouses/new',
                } ),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { WarehousePageBodyEdit } = await import('components/Supply/Warehouses/PageBody/Info/WarehousePageBodyEdit.tsx')
                            return { element: <WarehousePageBodyEdit /> }
                        },
                    },
                ],
            },
            {
                id: 'warehouseById',
                path: ':warehouseId',
                async lazy() {
                    const { WarehousePage } = await import('components/Supply/Warehouses/WarehousePage')
                    return { element: <WarehousePage /> }
                },
                loader: ({ params }) => ( {
                    id: params.warehouseId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                        name: state.warehousesReducer.editableFormHeader.name || '',
                    } ))),
                    path: `supply/warehouses/${ params.warehouseId }/about`,
                } ),
                children: [
                    {
                        index: true,
                        element: <Navigate to={ 'about' } replace />,
                    },
                    {
                        id: 'warehouseInfo',
                        path: 'about',
                        async lazy() {
                            const { WarehousePageBodyView } = await import('components/Supply/Warehouses/PageBody/Info/WarehousePageBodyView.tsx')
                            return { element: <WarehousePageBodyView /> }
                        },
                    },
                    {
                        id: 'warehouseDocumentMovements',
                        path: 'document-movements',
                        loader: ({ params }) => ({
                            name: 'Перемещения',
                            path: `supply/warehouses/${params.warehouseId}/document-movements`,
                        }),
                        children: [
                            {
                                id: 'warehouseDocumentMovementsList',
                                path: '',
                                async lazy() {
                                    const { WarehouseDocumentMovementsTable } = await import('components/Supply/Warehouses/PageBody/DocumentMovements/WarehouseDocumentMovementsTable.tsx')
                                    return { element: <WarehouseDocumentMovementsTable /> }
                                },
                            },
                            {
                                id: 'warehouseDocumentMovementById',
                                path: ':movementId',

                                async lazy() {
                                    const { DocumentMovementPage } = await import('components/Supply/DocumentMovements/DocumentMovementPage.tsx')
                                    return { element: <DocumentMovementPage/> }
                                },
                                loader: ({ params }) => ({
                                    id: params.movementId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.documentMovementMaterialsReducer.documentMovementData?.name || '',
                                    }))),
                                    path: `supply/document-movements/${ params.movementId }`,
                                }),
                                children: [   {
                                    index: true,
                                    element: <Navigate to={ 'materials' } replace />,
                                },
                                {
                                    id: 'warehouseDocumentMovementMaterials',
                                    path: 'materials',
                                    loader: () => ({ active: 'materials' }),
                                    async lazy() {
                                        const { MaterialsTab } = await import('components/Supply/DocumentMovements/PageBody/Materials/MaterialsTab.tsx')
                                        return { element: <MaterialsTab/> }
                                    },
                                },
                                {
                                    id: 'warehouseDocumentMovementOrders',
                                    path: 'orders',
                                    loader: () => ({ active: 'orders' }),
                                    async lazy() {
                                        const { OrdersTab } = await import('components/Supply/DocumentMovements/PageBody/Orders/OrdersTab.tsx')
                                        return { element: <OrdersTab/> }
                                    },
                                },
                                {
                                    path: 'edit',
                                    id: 'warehouseDocumentMovementEdit',
                                    async lazy() {
                                        const { DocumentMovementBodyEdit } = await import('components/Supply/DocumentMovements/PageBody/DocumentMovementBodyEdit.tsx')
                                        return { element: <DocumentMovementBodyEdit /> }
                                    },
                                },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'warehouseInventories',
                        path: 'inventories',
                        children: [
                            {
                                id: 'warehouseInventoriesList',
                                path: '',
                                async lazy() {
                                    const { WarehouseInventoriesPage } = await import('components/Supply/Warehouses/PageBody/Inventories/WarehouseInventoriesPage.tsx')
                                    return { element: <WarehouseInventoriesPage /> }
                                },
                            },
                        ],

                    },
                    {
                        id: 'warehouseOrders',
                        path: 'orders',
                        loader: ({ params }) => ({
                            name: 'Ордера',
                            path: `supply/warehouses/${params.warehouseId}/orders`,
                        }),
                        children: [
                            {
                                id: 'warehouseOrdersList',
                                path: '',
                                async lazy() {
                                    const { WarehouseOrdersPage } = await import('components/Supply/Warehouses/PageBody/Orders/WarehouseOrdersPage.tsx')
                                    return { element: <WarehouseOrdersPage /> }
                                },
                            },
                            {
                                id: 'warehouseOrderById',
                                path: ':orderId',

                                async lazy() {
                                    const { OrderPage } = await import('components/Supply/Orders/OrderPage')
                                    return { element: <OrderPage/> }
                                },
                                loader: ({ params }) => ({
                                    id: params.orderId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.orderMaterialsReducer.documentMovementOrderData?.name || '',
                                    }))),
                                    path: `supply/document-movement-orders/${ params.orderId }`,
                                }),
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/Desktop/OrderBodyView.tsx')
                                            return { element: <OrderBodyView /> }
                                        },
                                    },
                                    {
                                        path: 'edit',
                                        id: 'warehouseOrderEdit',
                                        async lazy() {
                                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/Desktop/OrderBodyView.tsx')
                                            return { element: <OrderBodyView /> }
                                        },
                                    },
                                    {
                                        path: 'execute',
                                        id: 'warehouseOrderExecute',
                                        async lazy() {
                                            const { OrderMaterialsTableEditWrapper } = await import('components/Supply/Orders/PageBody/Desktop/OrderMaterials/OrderMaterialsTableEdit/OrderMaterialsTableEditWrapper.tsx')
                                            return { element: <OrderMaterialsTableEditWrapper /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'warehouseSupplyRequests',
                        path: 'supply-requests',
                        loader: ({ params }) => ({
                            name: 'Заявки на снабжение',
                            path: `supply/warehouses/${params.warehouseId}/supply-requests`,
                        }),
                        children: [
                            {
                                id: 'warehouseSupplyRequestsList',
                                path: '',
                                async lazy() {
                                    const { WarehouseSupplyRequestsPage } = await import('components/Supply/Warehouses/PageBody/SupplyRequests/WarehouseSupplyRequestsPage.tsx')
                                    return { element: <WarehouseSupplyRequestsPage /> }
                                },
                            },
                            {
                                id: 'warehouseSupplyRequestById',
                                path: ':supplyRequestId',

                                async lazy() {
                                    const { SupplyRequestPage } = await import('components/Supply/SupplyRequests/SupplyRequestPage.tsx')
                                    return { element: <SupplyRequestPage/> }
                                },
                                loader: ({ params }) => ({
                                    id: params.requestId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.supplyRequestMaterialsReducer.supplyRequestData?.name || '',
                                    }))),
                                    path: `supply/supply-requests/${ params.requestId }`,
                                }),
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={ 'list' } replace />,
                                    },
                                    {
                                        id: 'warehouseSupplyRequestList',
                                        path: 'list',
                                        async lazy() {
                                            const { ListTab } = await import('components/Supply/SupplyRequests/PageBody/List/ListTab.tsx')
                                            return { element: <ListTab/> }
                                        },
                                    },
                                    {
                                        id: 'warehouseSupplyRequestOutlay',
                                        path: 'outlay',
                                        async lazy() {
                                            const { OutlayTab } = await import('components/Supply/SupplyRequests/PageBody/Outlay/OutlayTab.tsx')
                                            return { element: <OutlayTab/> }
                                        },
                                    },
                                    {
                                        id: 'warehouseSupplyRequestComments',
                                        path: 'comments',
                                        async lazy() {
                                            const { CommentsTab } = await import('components/Supply/SupplyRequests/PageBody/Comments/CommentsTab.tsx')
                                            return { element: <CommentsTab/> }
                                        },
                                    },
                                    {
                                        id: 'warehouseSupplyRequestDocuments',
                                        path: 'documents',
                                        async lazy() {
                                            const { DocumentsTab } = await import('components/Supply/SupplyRequests/PageBody/Documents/DocumentsTab.tsx')
                                            return { element: <DocumentsTab/> }
                                        },
                                    },
                                    {
                                        id: 'warehouseSupplyRequestEdit',
                                        path: 'edit',
                                        async lazy() {
                                            const { ListTab } = await import('components/Supply/SupplyRequests/PageBody/List/ListTab.tsx')
                                            return { element: <ListTab /> }
                                        },
                                    },
                                ],
                            },
                        ],

                    },
                    {
                        id: 'warehouseDocumentSupplierPurchases',
                        path: 'document-supplier-purchases',
                        loader: ({ params }) => ({
                            name: 'Заказы поставщикам',
                            path: `supply/warehouses/${params.warehouseId}/document-supplier-purchases`,
                        }),
                        children: [
                            {
                                id: 'warehouseDocumentSupplierPurchasesList',
                                path: '',
                                async lazy() {
                                    const { WarehouseDocumentSupplierPurchasesPage } = await import('components/Supply/Warehouses/PageBody/DocumentSupplierPurchases/WarehouseDocumentSupplierPurchasesPage.tsx')
                                    return { element: <WarehouseDocumentSupplierPurchasesPage /> }
                                },
                            },
                            {
                                id: 'warehouseDocumentSupplierPurchaseById',
                                path: ':documentSupplierPurchaseId',

                                async lazy() {
                                    const { DocumentSupplierPurchasePage } = await import('components/Supply/DocumentSupplierPurchases/DocumentSupplierPurchasePage.tsx')
                                    return { element: <DocumentSupplierPurchasePage/> }
                                },
                                // loader: ({ params }) => ({
                                //     id: params.movementId,
                                //     name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                //         name: state.documentMovementMaterialsReducer.documentMovementData?.name || '',
                                //     }))),
                                //     path: `supply/document-supplier-purchases/${ params.movementId }`,
                                // }),
                                children: [   {
                                    index: true,
                                    element: <Navigate to={ 'materials' } replace />,
                                },
                                {
                                    id: 'warehouseDocumentSupplierPurchaseMaterials',
                                    path: 'materials',
                                    loader: () => ({ active: 'materials' }),
                                    async lazy() {
                                        const { MaterialsTab } = await import('components/Supply/DocumentSupplierPurchases/PageBody/Materials/MaterialsTab.tsx')
                                        return { element: <MaterialsTab/> }
                                    },
                                },
                                {
                                    id: 'warehouseDocumentSupplierPurchaseOrders',
                                    path: 'orders',
                                    loader: () => ({ active: 'orders' }),
                                    async lazy() {
                                        const { OrdersTab } = await import('components/Supply/DocumentSupplierPurchases/PageBody/Orders/OrdersTab.tsx')
                                        return { element: <OrdersTab/> }
                                    },
                                },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'warehouseDocumentWriteOff',
                        path: 'document-write-off',
                        children: [
                            {
                                id: 'warehouseDocumentWriteOffList',
                                path: '',
                                async lazy() {
                                    const { WarehouseMaterialsWriteOffPage } = await import('components/Supply/Warehouses/PageBody/MaterialsWriteOff/WarehouseMaterialsWriteOffPage.tsx')
                                    return { element: <WarehouseMaterialsWriteOffPage /> }
                                },
                            },
                        ],

                    },
                    {
                        path: 'edit',
                        id: 'warehouseEdit',
                        async lazy() {
                            const { WarehousePageBodyEdit } = await import('components/Supply/Warehouses/PageBody/Info/WarehousePageBodyEdit.tsx')
                            return { element: <WarehousePageBodyEdit /> }
                        },
                    },
                ],
            },

        ],
    },
    {
        id: 'inventory',
        path: 'inventory',
        element: <Outlet />,
        loader: () => ( {
            name: 'Инвентаризации',
            path: 'supply/inventory',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { InventoriesTable } = await import('components/Supply/Inventories/InventoriesTable.tsx')
                    return { element: <InventoriesTable /> }
                },
            },
            {
                id: 'inventoryById',
                path: ':inventoryId',
                async lazy() {
                    const { InventoryPage } = await import('components/Supply/Inventories/InventoryPage.tsx')
                    return { element: <InventoryPage /> }
                },
                loader: ({ params }) => ( {
                    id: params.inventoryId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                        name: state.inventoryMaterialsReducer.inventoryData?.name || '',
                    } ))),
                    path: `supply/inventory/${ params.inventoryId }`,
                } ),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { InventoryPageBodyView } = await import('src/components/Supply/Inventories/PageBody/InventoryPageBodyView.tsx')
                            return { element: <InventoryPageBodyView /> }
                        },
                    },
                    {
                        path: 'edit',
                        id: 'inventoryEdit',
                        async lazy() {
                            const { InventoryPageBodyEdit  } = await import('src/components/Supply/Inventories/PageBody/InventoryPageBodyEdit.tsx')
                            return { element: <InventoryPageBodyEdit /> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'documentMovements',
        path: 'document-movements',
        element: <Outlet />,
        loader: () => ( {
            name: 'Перемещения',
            path: 'supply/document-movements',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { DocumentMovementsTable } = await import('components/Supply/DocumentMovements/DocumentMovementsTable.tsx')
                    return { element: <DocumentMovementsTable /> }
                },
            },
            {
                id: 'documentMovementNew',
                path: 'new',
                async lazy() {
                    const { DocumentMovementPage } = await import('components/Supply/DocumentMovements/DocumentMovementPage.tsx')
                    return { element: <DocumentMovementPage/> }
                },
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { DocumentMovementBodyEdit } = await import('components/Supply/DocumentMovements/PageBody/DocumentMovementBodyEdit.tsx')
                            return { element: <DocumentMovementBodyEdit /> }
                        },
                    },
                ],
            },
            {
                id: 'documentMovementById',
                path: ':movementId',

                async lazy() {
                    const { DocumentMovementPage } = await import('components/Supply/DocumentMovements/DocumentMovementPage.tsx')
                    return { element: <DocumentMovementPage/> }
                },
                loader: ({ params }) => ({
                    id: params.movementId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.documentMovementMaterialsReducer.documentMovementData?.name || '',
                    }))),
                    path: `supply/document-movements/${ params.movementId }`,
                }),
                children: [   {
                    index: true,
                    element: <Navigate to={ 'materials' } replace />,
                },
                {
                    id: 'documentMovementMaterialsTab',
                    path: 'materials',
                    loader: () => ({ active: 'materials' }),
                    async lazy() {
                        const { MaterialsTab } = await import('components/Supply/DocumentMovements/PageBody/Materials/MaterialsTab.tsx')
                        return { element: <MaterialsTab/> }
                    },
                },
                {
                    id: 'documentMovementOrdersTab',
                    path: 'orders',
                    loader: () => ({ active: 'orders' }),
                    async lazy() {
                        const { OrdersTab } = await import('components/Supply/DocumentMovements/PageBody/Orders/OrdersTab.tsx')
                        return { element: <OrdersTab/> }
                    },
                },
                {
                    path: 'edit',
                    id: 'documentMovementEdit',
                    async lazy() {
                        const { DocumentMovementBodyEdit } = await import('components/Supply/DocumentMovements/PageBody/DocumentMovementBodyEdit.tsx')
                        return { element: <DocumentMovementBodyEdit /> }
                    },
                },
                ],
            },
        ],
    },
    {
        id: 'documentWriteOff',
        path: 'document-write-off',
        element: <Outlet />,
        loader: () => ( {
            name: 'Списание',
            path: 'supply/document-write-off',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { DocumentWriteOffTable } = await import('components/Supply/DocumentWriteOff/DocumentWriteOffTable.tsx')
                    return { element: <DocumentWriteOffTable /> }
                },
            },
            {
                id: 'MaterialWriteOffById',
                path: ':writeOffId',

                async lazy() {
                    const { DocumentWriteOffPage } = await import('components/Supply/DocumentWriteOff/DocumentWriteOffPage.tsx')
                    return { element: <DocumentWriteOffPage/> }
                },
                loader: ({ params }) => ({
                    id: params.writeOffId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.documentWriteOffReducer.documentWriteOffData?.name || '',
                    }))),
                    path: `supply/materials-write-off/${ params.writeOffId }`,
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { DocumentWriteOffMaterialsBodyView } = await import('components/Supply/DocumentWriteOff/PageBody/DocumentWriteOffBodyView.tsx')
                            return { element: <DocumentWriteOffMaterialsBodyView /> }
                        },
                    },
                    {
                        path: 'edit',
                        id: 'materialWriteOffEdit',
                        async lazy() {
                            const { DocumentWriteOffMaterialsBodyView } = await import('components/Supply/DocumentWriteOff/PageBody/DocumentWriteOffBodyView.tsx')
                            return { element: <DocumentWriteOffMaterialsBodyView /> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'documentMovementOrders',
        path: 'document-movement-orders',
        element: <Outlet />,
        loader: () => ( {
            name: 'Ордера',
            path: 'supply/document-movement-orders',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { OrdersTable } = await import('components/Supply/Orders/OrdersTable.tsx')
                    return { element: <OrdersTable /> }
                },
            },
            {
                id: 'orderById',
                path: ':orderId',

                async lazy() {
                    const { OrderPage } = await import('components/Supply/Orders/OrderPage')
                    return { element: <OrderPage/> }
                },
                loader: ({ params }) => ({
                    id: params.orderId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.orderMaterialsReducer.documentMovementOrderData?.name || '',
                    }))),
                    path: `supply/document-movement-orders/${ params.orderId }`,
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/Desktop/OrderBodyView.tsx')
                            return { element: <OrderBodyView /> }
                        },
                    },
                    {
                        path: 'edit',
                        id: 'orderEdit',
                        async lazy() {
                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/Desktop/OrderBodyView.tsx')
                            return { element: <OrderBodyView /> }
                        },
                    },
                    {
                        path: 'execute',
                        id: 'orderExecute',
                        async lazy() {
                            const { OrderMaterialsTableEditWrapper } = await import('components/Supply/Orders/PageBody/Desktop/OrderMaterials/OrderMaterialsTableEdit/OrderMaterialsTableEditWrapper.tsx')
                            return { element: <OrderMaterialsTableEditWrapper /> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'supplySupplyRequests',
        path: 'supply-requests',
        element: <Outlet />,
        loader: () => ( {
            name: 'Заявки на снабжение',
            path: 'supply/supply-requests',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { SupplyRequestsTable } = await import('components/Supply/SupplyRequests/SupplyRequestsTable.tsx')
                    return { element: <SupplyRequestsTable /> }
                },
            },
            {
                id: 'supplyRequestById',
                path: ':supplyRequestId',

                async lazy() {
                    const { SupplyRequestPage } = await import('components/Supply/SupplyRequests/SupplyRequestPage.tsx')
                    return { element: <SupplyRequestPage/> }
                },
                loader: ({ params }) => ({
                    id: params.requestId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.supplyRequestMaterialsReducer.supplyRequestData?.name || '',
                    }))),
                    path: `supply/supply-requests/${ params.requestId }`,
                }),
                children: [
                    {
                        index: true,
                        element: <Navigate to={ 'list' } replace />,
                    },
                    {
                        id: 'supplyRequestList',
                        path: 'list',
                        async lazy() {
                            const { ListTab } = await import('components/Supply/SupplyRequests/PageBody/List/ListTab.tsx')
                            return { element: <ListTab/> }
                        },
                    },
                    {
                        id: 'supplyRequestOutlay',
                        path: 'outlay',
                        async lazy() {
                            const { OutlayTab } = await import('components/Supply/SupplyRequests/PageBody/Outlay/OutlayTab.tsx')
                            return { element: <OutlayTab/> }
                        },
                    },
                    {
                        id: 'supplyRequestComments',
                        path: 'comments',
                        async lazy() {
                            const { CommentsTab } = await import('components/Supply/SupplyRequests/PageBody/Comments/CommentsTab.tsx')
                            return { element: <CommentsTab/> }
                        },
                    },
                    {
                        id: 'supplyRequestDocuments',
                        path: 'documents',
                        async lazy() {
                            const { DocumentsTab } = await import('components/Supply/SupplyRequests/PageBody/Documents/DocumentsTab.tsx')
                            return { element: <DocumentsTab/> }
                        },
                    },
                    // {
                    //     path: 'edit',
                    //     id: 'supplyRequestEdit',
                    //     async lazy() {
                    //         const { ListTab } = await import('components/Supply/SupplyRequests/PageBody/List/ListTab.tsx')
                    //         return { element: <ListTab /> }
                    //     },
                    // },
                ],
            },
        ],
    },
    {
        id: 'documentSupplierPurchases',
        path: 'document-supplier-purchases',
        element: <Outlet />,
        loader: () => ( {
            name: 'Заказы поставщикам',
            path: 'supply/document-supplier-purchases',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { DocumentSupplierPurchasesTable } = await import('components/Supply/DocumentSupplierPurchases/DocumentSupplierPurchasesTable.tsx')
                    return { element: <DocumentSupplierPurchasesTable /> }
                },
            },
            {
                id: 'documentSupplierPurchaseId',
                path: ':documentSupplierPurchaseId',

                async lazy() {
                    const { DocumentSupplierPurchasePage } = await import('components/Supply/DocumentSupplierPurchases/DocumentSupplierPurchasePage.tsx')
                    return { element: <DocumentSupplierPurchasePage/> }
                },
                // loader: ({ params }) => ({
                //     id: params.requestId,
                //     name: createElement(BreadcrumbItem((state: RootStateType) => ({
                //         name: state.supplyRequestMaterialsReducer.supplyRequestData?.name || '',
                //     }))),
                //     path: `supply/supply-requests/${ params.requestId }`,
                // }),
                children: [   
                    {
                        index: true,
                        element: <Navigate to={ 'materials' } replace />,
                    },
                    {
                        id: 'documentSupplierPurchaseMaterialsTab',
                        path: 'materials',
                        loader: () => ({ active: 'materials' }),
                        async lazy() {
                            const { MaterialsTab } = await import('components/Supply/DocumentSupplierPurchases/PageBody/Materials/MaterialsTab.tsx')
                            return { element: <MaterialsTab/> }
                        },
                    },
                    {
                        id: 'documentSupplierPurchaseOrdersTab',
                        path: 'orders',
                        loader: () => ({ active: 'orders' }),
                        async lazy() {
                            const { OrdersTab } = await import('components/Supply/DocumentSupplierPurchases/PageBody/Orders/OrdersTab.tsx')
                            return { element: <OrdersTab/> }
                        },
                    },
                ],
            },

        ],
    },
]
