import {
    autocompleteChangeArg,
    autocompleteFieldDefault, autocompleteSubmitArg,
    multiselectFieldDefault
} from 'utils/inputs.tsx';
import {
    ProjectWorkAutocompleteKey,
    ProjectWorkFormBody, ProjectWorkFormGlobal,
    ProjectWorkFormHeader,
    ProjectWorkMultiselectFilterParamKey
} from 'components/Resources/Jobs/ProjectWorkPageBody/projectWork.types.ts';
import {
    FindWorkResponseDto, UpdateWorkRequestDto
} from 'store/api/projectWorks.tsx';
import {
    AutocompleteSubmitData,
    InputChangeArg,
    InputSubmitArg
} from 'types/inputs.ts';
import {
    FlattenedFormulaItem, JobFormulaFormBody
} from 'components/Resources/Jobs/PageBody/Formulas/Formulas.types.ts';
import {
    convertFormulaValueToRequestDto
} from 'components/Resources/Jobs/PageBody/Formulas/Formulas.utils.ts';
import { CreateWorkFormulaRequestDto } from 'store/api/projectWorkFormulas.tsx';

export const projectWorkFormHeaderInitialState: ProjectWorkFormHeader = {
    name: '',
}
export const projectWorkFormBodyInitialState: ProjectWorkFormBody = {
    insideDescription: '',
    clientDescription: '',
    type: autocompleteFieldDefault,
    view: autocompleteFieldDefault,
    stage: autocompleteFieldDefault,

    surfaces: multiselectFieldDefault,

    formulasList: {
        CONSTANT: [],
        LEGKOREM: [],
        MATERIAL_COUNT: [],
        MATERIAL_PARAMETER: [],
        OPERATION: [],
        SPECIAL: [],
    },

    isInvisible: false,

}


export const convertProjectWorkDtoToFormHeader = (work:  FindWorkResponseDto): ProjectWorkFormHeader => ({
    name: work?.name || '',
})

export const convertProjectWorkDtoToFormBody = (work: FindWorkResponseDto): ProjectWorkFormBody => ({
    clientDescription: work?.clientDescription || '',
    insideDescription: work?.insideDescription || '',

    stage: work?.stage ? { name: work.stage.name, data: { id: work.stage.id, name: work.stage.name } } : autocompleteFieldDefault,
    type: work?.type ? { name: work.type.name, data: { id: work.type.id, name: work.type.name } } : autocompleteFieldDefault,
    view: work?.view ? { name: work.view.name, data: { id: work.view.id, name: work.view.name } } : autocompleteFieldDefault,

    surfaces: {
        name: '',
        ids: work?.surfaces?.map(s => s.id) || [],
        alreadySelected: work?.surfaces?.map(s => ({ label: s.name, value: s.id })) || [],
    },

    isInvisible: work?.isInvisible,
})


// edit form funcs

export const onWorkBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<ProjectWorkAutocompleteKey, ProjectWorkFormBody>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onWorkBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<ProjectWorkAutocompleteKey, ProjectWorkFormBody>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
export const onWorkBodyMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<ProjectWorkMultiselectFilterParamKey, ProjectWorkFormBody>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${key}.name`, label)
    }
}
export const onWorkBodyMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<ProjectWorkMultiselectFilterParamKey, ProjectWorkFormBody>) => (valueIds: Array<string>) => {
    setFormValue(`${key}.ids`, valueIds)
}

export const convertWorkFormToRequestDto = (data: ProjectWorkFormGlobal): Omit<UpdateWorkRequestDto, 'name' | 'specializationsIds' | 'isNoisiness'> => ({
    insideDescription: data.insideDescription,
    clientDescription: data.clientDescription, 
    isInvisible: data.isInvisible,
    viewId: data.view?.data.id || null,
    stageId: data.stage?.data.id || null, 
    typeId : data.type?.data.id || null,      
    surfacesIds: data.surfaces?.ids?.length ? data.surfaces.ids : [],
})


export const convertWorkFormulaToRequestDto = (formula: JobFormulaFormBody & { value: FlattenedFormulaItem[], typeId: string }): CreateWorkFormulaRequestDto => ({
    name: formula.name,
    value: convertFormulaValueToRequestDto(formula?.value),
    materialId: formula?.material?.id,
    typeId: formula.typeId,
    unitsId: formula.units.data?.id,
})
