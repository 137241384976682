import { ClientFormBody, ClientFormHeader } from 'components/Clients/Clients.types'
import { ObjectFormBody, ObjectFormHeader } from 'components/Clients/NestedPages/Objects/Objects.types'
import { RoomFormBody, RoomFormHeader } from 'components/Clients/NestedPages/Rooms/Rooms.types'
import { BankAccountFormGlobal, BankFormGlobal } from 'components/Clients/PageBody/ClientFormParts/BankAccountForms/BankAccountForms.types'
import { ClientContactFormGlobal } from 'components/Clients/PageBody/ClientFormParts/ContactForms/ClientContactForms.types'
import { SpecializationsFormGlobal } from 'components/Organizations/NestedPages/Specializations/Specializations.types'
import { UserRoleFormBody, UserRoleFormHeader } from 'components/Organizations/NestedPages/UserRoles/UserRoles.types'
import { UserFormBody, UserFormHeader } from 'components/Organizations/NestedPages/Users/Users.types'
import { UserOrganizationFormBody, UserOrganizationFormHeader } from 'components/Organizations/UserOrganizations.types'
import { ProjectsFormGlobal } from 'components/Projects/Projects.types'
import { JobFormBody, JobFormHeader } from 'components/Resources/Jobs/Jobs.types'
import { PackageFormBody, PackageFormHeader } from 'components/Resources/Jobs/NestedPages/Packages/Packages.types'
import { StageFormBody, StageFormHeader } from 'components/Resources/Jobs/NestedPages/Stages/Stages.types'

import { TaskLinkFormGlobal } from 'components/Resources/Jobs/NestedPages/Tasks/PageBody/Info/TaskInfo.types'
import { StepFormBody, StepFormHeader, TasksFormGlobal } from 'components/Resources/Jobs/NestedPages/Tasks/Tasks.types'
import { JobTypeFormBody, JobTypeFormHeader } from 'components/Resources/Jobs/NestedPages/Types/JobType.types'
import { ViewFormBody, ViewFormHeader } from 'components/Resources/Jobs/NestedPages/Views/Views.types'
import { MaterialsFormGlobal } from 'components/Resources/Materials/Materials.types'
import { BrandFormBody, BrandFormHeader } from 'components/Resources/Materials/NestedPages/Brands/Brands.types'
import { CategoriesFormGlobal } from 'components/Resources/Materials/NestedPages/Categories/Categories.types'
import { StoresFormGlobal } from 'components/Resources/Materials/NestedPages/Stores/Stores.types'
import { MaterialPackFormGlobal } from 'components/Resources/Materials/PageBody/FormParts/Packs/MaterialPack.types'
import { MaterialParamFormGlobal } from 'components/Resources/Materials/PageBody/FormParts/Params/MaterialParam.types'
import { MaterialVariantFormGlobal } from 'components/Resources/Materials/PageBody/FormParts/Variants/MaterialVariant.types'
import { WarehouseFormBody, WarehouseFormHeader } from 'components/Supply/Warehouses/Warehouses.types'
import { GetFormPattern, OtherPatterns } from 'types/validation'
import { MinStroyMaterialsFormGlobal } from 'components/Resources/Materials/NestedPages/MinStroyMaterials/MinStroyMaterials.types'
import { WarehouseInventoriesFormGlobal } from 'components/Supply/Warehouses/PageBody/Info/WarehouseInventories/WarehouseInventories.types.ts'
import { ProjectPlanningWorksFormGlobal } from 'components/Projects/PageBody/Planning/PlanningParts/Works/ProjectPlanningWorks.types.ts'
import { FinanceSettingsFormGlobal } from 'components/Settings/NestedPages/Finance/FinancePage.types.ts';
import { HelpFormBody } from 'components/AbstractComponents/ModalHelp/ModalHelp.types.ts';

// const emailRegExp = /^( (([0-9A-Za-z][-0-9A-z.]+[0-9A-Za-z]) | ([0-9А-Яа-я][-0-9А-я.]+[0-9А-Яа-я])) @ ([-A-Za-z]+\.) {1,2} [-A-Za-z] {2,} )$/u
const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
const passwordRegExp = /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z!@#$%^&*]{3,}/g
const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
const compensationPercentRegExp = /^(100(\.0+)?|(\d{1,2})(\.\d+)?)$/g
const nameRegExp = /^([A-Za-z\-']{2,50})|([А-Яа-я\-']{2,50})$/
const min1symbolRegExp = /^([0-9A-Za-z.\-']{2,50})|([0-9А-я.\-']{2,50})$/
const linkRegExp = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\\/])*)?/
// /^(http|https):\/\/cs[0-9]+\.[a-zA-Z0-9]+\.me\/[^.]+/
const priceRegExp = /^[0-9]*[.,]?[0-9]+$/
const min2symbolsRegExp = /[-0-9А-я. 0-9A-Za-z.]{2,1000}/g
export const codeRegExp = /[a-zA-Zа-яА-Я]/
const positiveFrom0To100RegExp = /^(0|[1-9][0-9]?|100)$/g

const latitudeRegExp = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
const longitudeRegExp = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/

const onlyWholePositiveNumbersRegExp = /^[0-9]+$/

const descriptionNoLessThan10Exp = /[-0-9А-я. 0-9A-Za-z.]{10,1000}/g

const min2symbolsMessage = 'Не менее 2-х символов'
const requiredFieldMessage = 'Обязательное поле'
const requiredFieldCompany = 'Некорректное название компании'
const requiredFieldComment = 'Некорректно введено примечание'
const priceFieldMessage = 'Некорректное число'
const coordinatesFieldMessage = 'Неверные координаты'
const urlFieldMessage = 'Неверный адрес ссылки'
const emailFieldMessage = 'Введён некорректный email'
const phoneFieldMessage = 'Введён некорректный телефон'
const compensationPercentFieldMessage = 'Некорректное число. Введите число от 0 до 100'
const onlyWholePositiveNumbersMessage = 'Некорректное число'
const positiveFrom0To100Message = 'Некорректное число'
const descriptionNoLessThan10Message = 'Не менее 10 символов'



export const authValidation: OtherPatterns = {
    email: {
        pattern: emailRegExp,
        message: emailFieldMessage,
    },
    password: {
        pattern: passwordRegExp,
        message: 'Не менее 7 символов, 3 цифры и 1 символ',
    },
    name: {
        pattern: nameRegExp,
        message: min2symbolsMessage,
    },
}

export const materialValidation: GetFormPattern<MaterialsFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    brand: {
        name: {
            pattern: min1symbolRegExp,
            message: requiredFieldMessage,
        },
    },
    category: {
        name: {
            pattern: min1symbolRegExp,
            message: requiredFieldMessage,
        },
    },
    description: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    images: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    package: {
        name: {
            pattern: min1symbolRegExp,
            message: requiredFieldMessage,
        },
    },
    sku: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    type: {
        name: {
            pattern: min1symbolRegExp,
            message: requiredFieldMessage,
        },
    },
    uid: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    url: {
        pattern: linkRegExp,
        message: urlFieldMessage,
    },
    price: {
        pattern: priceRegExp,
        message: priceFieldMessage,
    },
    discountPrice: {
        pattern: priceRegExp,
        message: priceFieldMessage,
    },
}

export const jobValidation: GetFormPattern<JobFormHeader & JobFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    clientDescription: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    insideDescription: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    
    type: {
        name: {
            pattern: min2symbolsRegExp,
            message: min2symbolsMessage,
        },
    },
    view: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    stage: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    organization: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    surfaces: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    groups: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const objectValidation: GetFormPattern<ObjectFormHeader & ObjectFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    address: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    client: {
        name: {
            pattern: min2symbolsRegExp,
            message: min2symbolsMessage,
        },
    },
    latitude: {
        pattern: latitudeRegExp,
        message: coordinatesFieldMessage,
    },
    longitude: {
        pattern: longitudeRegExp,
        message: coordinatesFieldMessage,
    },
}

export const roomValidation: GetFormPattern<RoomFormHeader & RoomFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    object: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    square: {
        pattern: priceRegExp,
        message: priceFieldMessage,
    },
}


export const taskValidation: GetFormPattern<TasksFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    previous: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    workTime: {
        pattern: priceRegExp,
        message: priceFieldMessage,
    },
}

export const brandValidation: GetFormPattern<BrandFormHeader & BrandFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    url: {
        pattern: linkRegExp,
        message: urlFieldMessage,
    },
}

export const storeValidation: GetFormPattern<StoresFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    url: {
        pattern: linkRegExp,
        message: urlFieldMessage,
    },
}

export const minStroyMaterialsValidation: GetFormPattern<MinStroyMaterialsFormGlobal> = {
    text: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    category: {
        name: {
            pattern: min1symbolRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const categoryValidation: GetFormPattern<CategoriesFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    url: {
        pattern: linkRegExp,
        message: urlFieldMessage,
    },
    code: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    store: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const stageValidation: GetFormPattern<StageFormHeader & StageFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    description: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    sort: {
        pattern: onlyWholePositiveNumbersRegExp,
        message: onlyWholePositiveNumbersMessage
    }
}

export const viewValidation: GetFormPattern<ViewFormHeader & ViewFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    description: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
}

export const packageValidation: GetFormPattern<PackageFormHeader & PackageFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    description: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
}



export const jobTypeValidation: GetFormPattern<JobTypeFormHeader & JobTypeFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    description: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
}

export const stepValidation: GetFormPattern<StepFormHeader & StepFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    task: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const userOrganizationValidation: GetFormPattern<UserOrganizationFormHeader & UserOrganizationFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
}

export const userRoleValidation: GetFormPattern<UserRoleFormHeader & UserRoleFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    organization: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const userValidation: GetFormPattern<UserFormHeader & UserFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    password: {
        pattern: passwordRegExp,
        message: 'Не менее 7 символов, 3 цифры и 1 символ',
    },
    department: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    email: {
        pattern: emailRegExp,
        message: emailFieldMessage,
    },
    job: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    phone: {
        pattern: phoneRegExp,
        message: phoneFieldMessage,
    },
    specializations: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    groups: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    roles: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    compensationPercent: {
        pattern: compensationPercentRegExp,
        message: compensationPercentFieldMessage,
    }
}

export const warehouseValidation: GetFormPattern<WarehouseFormHeader & WarehouseFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    address: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    description: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    type: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    object: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const clientValidation: GetFormPattern<ClientFormHeader & ClientFormBody> = {
    name: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    birthDate: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    firstName: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    lastName: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    middleName: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    fullName: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    abbreviatedName: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    passportNumber: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    passportIssuer: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    passportIssueDate: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    passportIssuingUnitCode: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    registrationAddress: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    legalAddress: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    inn: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    ogrn: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    kpp: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    okato: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    okpo: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    okved: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    
    // bank: {
    //   name: {
    //     pattern: min2symbolsRegExp,
    //     message: requiredFieldMessage,
    //   }
    // },
    // contact: {
    //   name: {
    //     pattern: min2symbolsRegExp,
    //     message: requiredFieldMessage,
    //   }
    // },
    kind: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    taxationSystem: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    deleted: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    
    type: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    
    // bankDetails: {
    //   name: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   bik: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   ks: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   inn: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   kpp: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    // },
    // contactDetails: {
    //   company: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   email: {
    //     pattern: emailRegExp,
    //     message: emailFieldMessage,
    //   },
    //   lastName: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   firstName: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   middleName: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   note: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   phone: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   position: {
    //     pattern: min2symbolsRegExp,
    //     message: min2symbolsMessage,
    //   },
    //   website: {
    //     pattern: linkRegExp,
    //     message: urlFieldMessage,
    //   },
    // },
}

export const clientContactValidation: GetFormPattern<ClientContactFormGlobal> = {
    email: {
        pattern: emailRegExp,
        message: emailFieldMessage,
    },
    company: {
        pattern: min2symbolsRegExp,
        message: requiredFieldCompany,
    },
    firstName: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    lastName: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    middleName: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    note: {
        pattern: min2symbolsRegExp,
        message: requiredFieldComment,
    },
    phone: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    position: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    website: {
        pattern: linkRegExp,
        message: urlFieldMessage,
    },
}

export const bankAccountValidation: GetFormPattern<BankAccountFormGlobal> = {
    number: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
}

export const bankValidation: GetFormPattern<BankFormGlobal> = {
    bik: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    abbreviatedName: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    address: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    city: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    index: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    ks: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    name: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    okato: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    okpo: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    phone: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    regNum: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
}

export const materialParamValidation: GetFormPattern<MaterialParamFormGlobal> = {
    units: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    name: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    type: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    values: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
}

export const materialPackValidation: GetFormPattern<MaterialPackFormGlobal> = {
    packing: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    unitMeasure: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    price: {
        pattern: priceRegExp,
        message: requiredFieldMessage,
    },
    tradePrice: {
        pattern: priceRegExp,
        message: requiredFieldMessage,
    },
}

export const taskLinkValidation: GetFormPattern<TaskLinkFormGlobal> = {
    name: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    link: {
        pattern: linkRegExp,
        message: urlFieldMessage,
    },
}

export const materialVariantValidation: GetFormPattern<MaterialVariantFormGlobal> = {
    store: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    url: {
        pattern: linkRegExp,
        message: urlFieldMessage,
    },
    sku: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
}

export const specializationValidation: GetFormPattern<SpecializationsFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
}

export const projectValidation: GetFormPattern<ProjectsFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    description: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    emergencyPhoneNumber: {
        pattern: phoneRegExp,
        message: phoneFieldMessage,
    },
    from: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    to: {
        pattern: min2symbolsRegExp,
        message: min2symbolsMessage,
    },
    manager: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    supervisor: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    client: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    object: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    organization: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    status: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    workingHours: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    transportCosts: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
}

export const warehouseInventoryValidation: GetFormPattern<WarehouseInventoriesFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    count: {
        pattern: min1symbolRegExp,
        message: requiredFieldMessage,
    },
    materialType: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    inventoryType: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    status: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    project: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    material: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
    units: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const projectPlanningWorkValidation: GetFormPattern<ProjectPlanningWorksFormGlobal> = {
    name: {
        pattern: min2symbolsRegExp,
        message: requiredFieldMessage,
    },
    status: {
        name: {
            pattern: min2symbolsRegExp,
            message: requiredFieldMessage,
        },
    },
}

export const settingValidation: GetFormPattern<FinanceSettingsFormGlobal> = {
    speed: {
        pattern: positiveFrom0To100RegExp,
        message: positiveFrom0To100Message
    },
    quality: {
        pattern: positiveFrom0To100RegExp,
        message: positiveFrom0To100Message
    },
}

export const helpValidation: GetFormPattern<HelpFormBody> = {
    email: {
        pattern: emailRegExp,
        message: emailFieldMessage,
    },
    phone: {
        pattern: phoneRegExp,
        message: phoneFieldMessage,
    },
    description: {
        pattern: descriptionNoLessThan10Exp,
        message: descriptionNoLessThan10Message,
    },
}
