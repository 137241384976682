import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    ProjectWorkSliceInitialState
} from 'components/Resources/Jobs/ProjectWorkPageBody/projectWork.types.ts';
import {
    projectWorkFormBodyInitialState, projectWorkFormHeaderInitialState
} from 'components/Resources/Jobs/ProjectWorkPageBody/projectWork.utils.ts';

const projectWorkInitialState: ProjectWorkSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: projectWorkFormHeaderInitialState,
    editableFormBody: projectWorkFormBodyInitialState,
}

export const projectWorkSlice = createAppSlice<ProjectWorkSliceInitialState>('projectWork', projectWorkInitialState)


export default projectWorkSlice.reducer
