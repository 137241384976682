import {
    MaterialsSliceInitialState
} from 'components/Resources/Materials/Materials.types.ts';
import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import {
    materialFormHeaderInitialState, materialsFormBodyInitialState
} from 'components/Resources/Materials/Materials.utils.ts';
import { createAppSlice } from 'utils/createAppSlice.ts';

const materialAnalogueAddInitialState: MaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: materialFormHeaderInitialState,
    editableFormBody: materialsFormBodyInitialState,
}

export const materialAnalogueAddSlice = createAppSlice<MaterialsSliceInitialState>('materialAnalogueAdd', materialAnalogueAddInitialState)


export default materialAnalogueAddSlice.reducer
