import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    SupplyRequestDocumentsSliceInitialState
} from 'components/Supply/SupplyRequests/PageBody/Documents/Documents.types.ts';

const supplyRequestDocumentsSliceInitialState: SupplyRequestDocumentsSliceInitialState = {
    ...defaultInitialState,
    // sortParamsArray: [
    //     {
    //         param: 'supplyRequest.createdAt',
    //         rule: 'desc',
    //     },
    // ],
}

export const supplyRequestDocumentsSlice = createAppSlice<SupplyRequestDocumentsSliceInitialState>(
    'supplyRequestDocuments',
    supplyRequestDocumentsSliceInitialState,
)


export default supplyRequestDocumentsSlice.reducer