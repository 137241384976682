import { MantineThemeOverride } from '@mantine/core'
import { COLOR_WHITE, MEDIA_EM } from 'styles/constants'
import { mantineColors } from 'styles/mantineColors'
import { dropdownOptions, inputLabelOptions, inputOptions, inputVariants, selectStyles } from 'styles/mixins'

export const lightTheme: MantineThemeOverride = {
    colorScheme: 'light',
    fontFamily: 'Inter, sans-serif',
    cursorType: 'pointer',
    // TODO create overlay-centered loader relative to content
    loader: 'dots',
    breakpoints: {
        xl: MEDIA_EM.XL,
        lg: MEDIA_EM.LG,
        md: MEDIA_EM.MD,
        sm: MEDIA_EM.SM,
        xs: MEDIA_EM.XS,
    },
    components: {
        Accordion: {
            styles: () => ({
                content: {
                    padding: 0,
                },
            }),
        },
        Anchor: {
            variants: {
                chatFile: (theme) => ({
                    root: {
                        padding: 5,
                        borderRadius: 8,
                        color: theme.colors.gray[7],
                        '&:hover': {
                            backgroundColor: theme.colors.gray[0],
                            color: theme.colors.gray[8],
                        }
                    }
                })
            }
        },
        Autocomplete: {
            variants: {
                custom1: (theme) => ({
                    'root': {
                        width: 'calc(100% - 32px)'
                    },
                    label: {
                        lineHeight: '16px !important',
                        fontSize: '12px !important',
                        color: `${theme.colors.natural[7]} !important`,
                    },
                    input: {
                        padding: '0 12px 0 12px',
                        borderColor: `${theme.colors.natural[2]} !important`,
                    }
                })
            },
            styles: (theme) => ({
                dropdown: dropdownOptions(),
                label: inputLabelOptions(theme),
                input: {
                    '&::placeholder': {
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: theme.colors.natural[7],
                    },
                },
            }),
        },
        Carousel: {
            styles: (theme) => ({
                controls: {
                    display: 'none',
                },
                indicators: {
                    position: 'absolute',
                    bottom: -20,
                },
                indicator: {
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    border: 'none',
                    outline: 'none',
                    backgroundColor: theme.colors.natural[3],
                    '&[data-active]': {
                        backgroundColor: theme.colors.natural[8],
                        transform: 'scale(1.3)',
                    },
                },
            }),
        },
        Drawer: {
            styles: (theme) => ({
                header: {
                    backgroundColor: theme.colors.natural[0],
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    gap: '16px',
                },
                content: {
                    overflowY: 'scroll',
                },
                title: {
                    fontSize: '24px',
                    fontWeight: 500,
                    lineHeight: '32px',
                    color: theme.colors.natural[9],
                },
                close: {
                    marginLeft: 0,
                    width: '24px',
                    height: '24px',
                    
                    svg: {
                        width: '100% !important',
                        height: '100% !important',
                        color: theme.colors.natural[9],
                    },
                },
            }),
            variants: {
                greyBg: (theme) => ({
                    body: {
                        backgroundColor: theme.colors.natural[0],
                    },
                    inner: {
                        display: 'block',
                        marginTop: 'calc(var(--mantine-header-height, 0px))',
                        marginBottom: 'calc(var(--mantine-footer-height, 0px))',
                        paddingRight:  'calc(var(--mantine-navbar-width) + px)',
                        paddingLeft: 'calc(var(--mantine-navbar-width, 0px) + 33rem)',
                    },
                    content: {
                        overflowY: 'initial',
                        boxShadow: '-8px 0px 16px 0px #000F3D0F',
                        backgroundColor: 'white',
                    },
                }),
                addTMC: (theme) => ({
                    root: {
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    },
                    body: {
                        backgroundColor: theme.colors.natural[0],
                    },
                    header: {
                        paddingTop: 10,
                    },
                    inner: {
                        display: 'block',
                        marginTop: 'calc(var(--mantine-header-height, 0px))',
                        marginBottom: 'calc(var(--mantine-footer-height, 0px))',
                        paddingRight:  'calc(var(--mantine-navbar-width) + 36px)',
                        paddingLeft: 'calc(var(--mantine-navbar-width, 0px) + 14rem)',
                    },
                    content: {
                        overflowY: 'initial',
                        boxShadow: '-8px 0px 16px 0px #000F3D0F',
                        backgroundColor: 'white',
                    },
                }),
                addMaterial: () => ({
                    root: {
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    },
                    header: {
                        backgroundColor: 'white !important',
                        paddingTop: 10,
                    },
                    inner: {
                        display: 'block',
                        marginTop: 'calc(var(--mantine-header-height, 0px))',
                        marginBottom: 'calc(var(--mantine-footer-height, 0px))',
                        paddingRight:  'calc(var(--mantine-navbar-width) + 36px)',
                        paddingLeft: 'calc(var(--mantine-navbar-width, 0px) + 14rem)',
                    },
                    content: {
                        overflowY: 'initial',
                        boxShadow: '-8px 0px 16px 0px #000F3D0F',
                        backgroundColor: 'white',
                    },
                }),
                calendar: () => ({
                    header: {
                        backgroundColor: 'white !important',
                        margin: '14px',
                    },
                    body: {
                        margin: '14px',
                    },
                    content: {
                        overflowY: 'auto',
                        boxShadow: '-8px 0px 16px 0px #000F3D0F',
                        backgroundColor: 'white',
                    },
                    inner: {
                        marginTop: 'calc(var(--mantine-header-height, 0px))',
                        marginBottom: 'calc(var(--mantine-footer-height, 0px))',
                        paddingRight:  'calc(var(--mantine-navbar-width) + 36px)',
                    }
                }),
                noOverflowForDnd: () => ({
                    header: {
                        backgroundColor: 'white !important',
                    },
                    content: {
                        overflowY: 'initial',
                        boxShadow: '-8px 0px 16px 0px #000F3D0F',
                        backgroundColor: 'white',
                    },
                    inner: {
                        display: 'block',
                        marginTop: 'calc(var(--mantine-header-height, 0px))',
                        marginBottom: 'calc(var(--mantine-footer-height, 0px))',
                        paddingRight: 'calc(var(--mantine-navbar-width, 0px) + 2rem)',
                        paddingLeft: 'calc(var(--mantine-navbar-width, 0px) + 14rem)',
                    },
                }),
                rightContentSized: (theme) => ({
                    inner: {
                        display: 'block',
                        marginTop: 'calc(var(--mantine-header-height, 0px))',
                        marginBottom: 'calc(var(--mantine-footer-height, 0px))',
                        paddingRight: 'calc(var(--mantine-navbar-width, 0px) + 2rem)',
                        paddingLeft: 'calc(var(--mantine-navbar-width, 0px) + 14rem)',
                    },
                    content: {
                        boxShadow: '-8px 0px 16px 0px #000F3D0F',
                        backgroundColor: theme.colors.natural[0],
                    },
                }),
            },
        },
        Popover: {
            variants: {
                notification: () => ({
                    dropdown: {
                        borderRadius: 16,
                        boxShadow: '-4px 4px 6px 0px #00000014',

                    }
                })
            }
        },
        Table: {
            styles: () => ({
                root: {
                    borderRadius: '4px',
                    borderWidth: '1px',
                    borderSpacing: '8px',
                    // borderColor: 'natural.2'
                },
            }),
        },
        Textarea: {
            styles: (theme) => ({
                label: inputLabelOptions(theme),
                input: { resize: 'vertical', maxHeight: 400, minHeight: 80 },
            }),
        },
        Title: {
            styles: (theme) => ({
                root: {
                    color: theme.colors.natural[9],
                },
            }),
        },
        // Tooltip: {
        //     styles: {
        //         tooltip: {
        //             zIndex: 1000,
        //         },
        //     },
        // },
        Checkbox: {
            styles: (theme) => ({
                inner: {
                    display: 'flex',
                    alignItems: 'center',
                },
                input: {
                    ...inputOptions(theme),
                    '&:checked': {
                        backgroundColor: theme.colors.primary[3],
                        border: 'none',
                    },
                },
                label: {
                    ...inputLabelOptions(theme),
                    color: theme.colors.natural[9],
                    fontWeight: 400,
                    paddingLeft: '8px',
                },
            }),
        },
        Select: {
            styles: selectStyles,
        },
        LoadingOverlay: {
            styles: {
                root: {
                    backgroundColor: 'none !important',
                    left: -15,
                    right: -15,
                },
            },
        },
        MultiSelect: {
            styles: (theme) => ({
                ...selectStyles,
                searchInput: {
                    '&::placeholder': {
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: theme.colors.natural[7],
                    },
                },
            }),
        },
        Input: {
            styles: (theme) => ({
                input: inputOptions(theme),
                label: inputLabelOptions(theme),
            }),
            variants: inputVariants(),
        },
        PasswordInput: {
            styles: (theme) => ({
                input: inputOptions(theme),
                label: inputLabelOptions(theme),
            }),
            variants: inputVariants(),
        },
        DateInput: {
            styles: (theme) => ({
                input: inputOptions(theme),
                label: inputLabelOptions(theme),
            }),
            // variants: inputVariants(),
        },
        NumberInput: {
            styles: (theme) => ({
                input: inputOptions(theme),
                label: inputLabelOptions(theme),
            }),
        },
        TextInput: {
            variants: {
                titled1: () => ({
                    root: {
                        display: 'block',
                        paddingBottom: '24px',
                        maxWidth: '85%',
                    },
                    input: {
                        borderRadius: '8px',
                        border: 'none !important',
                        outline: 'none !important',
                        fontSize: '32px',
                        lineHeight: '40px',
                        // height: '40px',
                        fontWeight: 700,

                        '&:disabled': {
                            backgroundColor: 'transparent !important',
                            color: 'inherit',
                            opacity: 1,
                        },
                    },
                }),
                titled3: (theme) => ({
                    root: {
                        display: 'block',
                    },
                    input: {
                        borderRadius: '8px',
                        border: 'none !important',
                        outline: 'none !important',
                        height: '40px',
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 500,

                        '&:disabled': {
                            backgroundColor: 'transparent !important',
                            color: 'inherit',
                            opacity: 1,
                        },

                        color: theme.colors.natural[6],
                        '&::placeholder': {
                            fontSize: '24px !important',
                            fontWeight: 500,
                            lineHeight: '32px !important',
                        },
                    },
                    placeholder: {
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: '32px',
                    },
                }),
                titled4: (theme) => ({
                    root: {
                        display: 'block',
                    },
                    input: {
                        borderRadius: '8px',
                        border: 'none !important',
                        outline: 'none !important',
                        height: '40px',
                        fontSize: '20px',
                        lineHeight: '24px',
                        fontWeight: 400,

                        '&:disabled': {
                            backgroundColor: 'transparent !important',
                            color: 'inherit',
                            opacity: 1,
                        },

                        color: theme.colors.natural[6],
                        '&::placeholder': {
                            fontSize: '20px !important',
                        },
                    },
                    placeholder: {
                        fontSize: '20px',
                    },
                }),
                titled5: () => ({
                    root: {
                        display: 'block',
                    },
                    input: {
                        borderRadius: '8px',
                        border: 'none !important',
                        outline: 'none !important',
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontWeight: 400,

                        '&:disabled': {
                            backgroundColor: 'transparent !important',
                            color: 'inherit',
                            opacity: 1,
                        },
                    },
                }),
            },
        },
        Breadcrumbs: {
            styles: (theme) => ({
                breadcrumb: {
                    color: theme.colors.gray[5],
                    '&.mantine-Anchor-root': {
                        color: theme.colors.gray[9],
                    },
                },
                separator: {
                    marginLeft: '8px',
                    marginRight: '8px',
                    color: theme.colors.gray[9],
                },
            }),
        },
        Modal: {
            styles: {
                content: {
                    borderRadius: '16px',
                },
                body: {
                    padding: '24px',
                },
            },
            variants: {
                mobileGray: (theme) => ({
                    body: {
                        backgroundColor: theme.colors.gray[0]
                    },
                    header: {
                        backgroundColor: theme.colors.gray[0]
                    },
                }),
                greyToast: () => ({
                    root: {
                        opacity: '80%',
                        padding: '0 !important',
                    },
                    body: {
                        backgroundColor: 'rgba(92, 96, 102, 0.8)',
                        padding: '10px !important'
                    },
                    header: {
                        backgroundColor: 'rgba(92, 96, 102, 0.8)'
                    }
                })
            }
        },
        Stepper: {
            variants: {
                primary: (theme) => ({
                    verticalSeparator: {
                        border: `1px solid ${ theme.colors.gray[1] }`,
                    },
                    verticalSeparatorActive: {
                        border: `1px solid ${ theme.colors.primary[3] }`,
                    },
                    stepIcon: {
                        color: theme.colors.gray[9],
                        backgroundColor: 'transparent',
                        '&[data-completed=true]': {
                            border: 'none',
                            backgroundColor: theme.colors.primary[3],
                        },
                        '&[data-progress=true]': {
                            backgroundColor: theme.colors.primary[3],
                            border: 'none',
                        },
                    },
                    stepCompletedIcon: {
                        color: theme.colors.gray[9],
                        '& > svg': {
                            width: '15px',
                        },
                    },
                }),
                vertical: (theme) => ({
                    root: {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    },
                    verticalSeparator: {
                        border: 'none',
                    },
                    verticalSeparatorActive: {
                        border: 'none',
                    },
                    steps: {
                        boxShadow: `2px 0px 0px 0px ${ theme.colors.natural[2] }`,
                    },
                    step: {
                        alignItems: 'center',
                        gap: 2,

                        '&[data-progress]': {
                            boxShadow: `2px 0px 0px 0px ${ theme.colors.primary[3] }`,
                        },
                    },
                    stepLabel: {
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '20px',
                    },
                    content: {
                        padding: 0,
                        paddingLeft: 40,
                        flex: 1,
                    },
                    stepIcon: {
                        color: theme.colors.gray[9],
                        backgroundColor: 'transparent',
                        border: 'none',
                        height: '2.125rem',
                        width: '2.125rem',
                        minWidth: '2.125rem',

                        '&[data-completed=true]': {
                            backgroundColor: theme.colors.primary[3],
                        },
                        '&[data-progress=true]': {
                            backgroundColor: theme.colors.primary[3],
                        },
                    },
                    stepCompletedIcon: {
                        color: theme.colors.gray[9],
                        '& > svg': {
                            width: '15px',
                        },
                    },
                }),
            },
        },
        Tabs: {
            styles: (theme, params: { orientation: string }) => ({
                tabsList: {
                    // paddingLeft: '10px',
                    // paddingTop: '10px',
                    // paddingBottom: '14px',
                },
                tab: {
                    fontSize: '16px',
                    borderBottom: '2px solid transparent',
                    // borderRight: params.orientation === 'vertical' ? `2px solid ${theme.colors.gray[2]}` : '',
                    paddingBottom: '24px',
                    '&[data-active]': {
                        borderBottom: params.orientation !== 'vertical' ? `3px solid ${ theme.colors.natural[9] }` : '',
                        '&:hover': {
                            borderBottom: params.orientation !== 'vertical' ? `3px solid ${ theme.colors.natural[9] }` : '',
                        },
                    },
                    '&:not([data-active])': {
                        color: theme.colors.natural[6],
                    },
                },
            }),
            variants: {
                primary: (theme, params: { orientation: string }) => ({
                    tab: {
                        color: theme.colors.gray[6],
                        '&[data-disabled]': {
                            color: theme.colors.gray[2],
                        },
                        '&[data-active]': {
                            color: theme.colors.gray[9],
                            borderBottom: params.orientation === 'horizontal' ? `2px solid ${ theme.colors.gray[9] }` : '',
                            borderLeft: params.orientation === 'vertical' ? `2px solid ${ theme.colors.primary[3] }` : '',
                        },
                        '&:hover': {
                            color: theme.colors.gray[9],
                            borderLeft: params.orientation === 'vertical' ? `2px solid ${ theme.colors.primary[3] }` : '',
                        },
                    },
                }),
                dark: (theme) => ({
                    tabsList: {
                        backgroundColor: theme.colors.gray[9],
                        borderRadius: '8px',
                    },
                    tab: {
                        color: COLOR_WHITE,
                        '&[data-disabled]': {
                            color: theme.colors.gray[2],
                        },
                        '&[data-active]': {
                            color: theme.colors.primary[3],
                            borderBottom: `2px solid ${ theme.colors.primary[3] }`,
                        },
                        '&:hover': {
                            color: theme.colors.primary[3],
                        },
                    },
                }),
                primaryVertical: (theme) => ({
                    tab: {
                        fontSize: '16px',
                        fontWeight: 400,

                        color: `${ theme.colors.natural[8] } !important`,
                        '&[data-disabled]': {
                            color: `${ theme.colors.natural[8] } !important`,
                        },
                        '&[data-active]': {
                            color: `${ theme.colors.info[5] } !important`,
                        },
                        '&:hover': {
                            color: `${ theme.colors.info[5] } !important`,
                        },
                    },
                }),
                primaryVerticalBorderedLeft: (theme) => ({
                    root: {

                    },
                    tab: {
                        flexDirection: 'row',
                        borderLeft: `4px solid ${ theme.colors.natural[2] }`,
                        padding: '8px 20px !important',
                        whiteSpace: 'normal',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        textAlign: 'left',

                        color: `${ theme.colors.natural[8] } !important`,
                        '&[data-disabled]': {
                            color: `${ theme.colors.natural[8] } !important`,
                        },
                        '&[data-active]': {
                            color: `${ theme.colors.info[5] } !important`,
                            borderLeft: `4px solid ${ theme.colors.primary[3] }`,
                        },
                        '&:hover': {
                            color: `${ theme.colors.info[5] } !important`,
                        },
                    },
                    tabsList: {
                        width: '100%',
                    },
                }),
                verticalScroll: (theme) => ({
                    root: {
                        height: '700px',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto'

                    },
                    tab: {
                        flexDirection: 'row',
                        borderLeft: `4px solid ${ theme.colors.natural[2] }`,
                        padding: '8px 20px !important',
                        whiteSpace: 'normal',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        textAlign: 'left',

                        color: `${ theme.colors.natural[8] } !important`,
                        '&[data-disabled]': {
                            color: `${ theme.colors.natural[8] } !important`,
                        },
                        '&[data-active]': {
                            color: `${ theme.colors.info[5] } !important`,
                            borderLeft: `4px solid ${ theme.colors.primary[3] }`,
                        },
                        '&:hover': {
                            color: `${ theme.colors.info[5] } !important`,
                        },
                    },
                    tabsList: {
                        width: '100%',
                    },
                }),
                primaryVerticalBorderedRight: (theme) => ({
                    root: {

                    },
                    tab: {
                        flexDirection: 'row',
                        borderRight: `4px solid ${ theme.colors.natural[2] }`,
                        padding: '14px 20px !important',

                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        textAlign: 'left',

                        color: `${ theme.colors.natural[5] } !important`,
                        '&[data-disabled]': {
                            color: `${ theme.colors.natural[5] } !important`,
                        },
                        '&[data-active]': {
                            color: `${ theme.colors.natural[9] } !important`,
                            borderRight: `4px solid ${ theme.colors.primary[3] }`,
                        },
                        '&:hover': {
                            color: `${ theme.colors.info[5] } !important`,
                        },
                    },
                    tabsList: {
                        width: '100%',
                    },
                }),
                mobile: (theme) => ({
                    root: {
                        position: 'fixed',
                        top: '126px',
                        backgroundColor:  theme.colors.gray[8],
                        width: '100vw',
                        zIndex: 4,
                        overflow: 'scroll',
                    },
                    tabsList: {
                        flexWrap: 'nowrap'
                    },

                    tab: {
                        borderBottom: '3px solid transparent !important',
                        padding: '16px 12px',
                        '&[data-active]': {
                            color: theme.colors.yellow[4],
                            borderBottom: `3px solid ${theme.colors.yellow[4]}`
                        },
                        '&[data-active]:hover': {
                            color: theme.colors.yellow[4],
                            borderBottom: `3px solid ${theme.colors.yellow[4]} !important`
                        }
                    }
                }),
                mobileForWorkerProfile: (theme) => ({
                    root: {
                        position: 'fixed',
                        backgroundColor:  theme.colors.gray[8],
                        overflow: 'scroll',
                        width: '100vw',
                        zIndex: 4,
                        top: '100px'
                    },
                    tabsList: {
                        flexWrap: 'nowrap'
                    },

                    tab: {
                        borderBottom: '3px solid transparent !important',
                        padding: '16px 12px',
                        '&[data-active]': {
                            color: theme.colors.yellow[4],
                            borderBottom: `3px solid ${theme.colors.yellow[4]}`
                        },
                        '&[data-active]:hover': {
                            color: theme.colors.yellow[4],
                            borderBottom: `3px solid ${theme.colors.yellow[4]} !important`
                        }
                    }
                }),
                yellowRight: (theme) => ({
                    root: {
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: 10
                    },
                    tab: {
                        padding: '8px !important',
                        color: theme.colors.gray[6],
                        borderRadius: 5,
                        borderBottom:  'none',
                        marginLeft: 10,
                        '&[data-disabled]': {
                            color: theme.colors.gray[2],
                        },
                        '&[data-active]': {
                            color: theme.colors.gray[9],
                            fontWeight: 500,
                            borderBottom:  'none !important',
                            backgroundColor: theme.colors.yellow[3],
                        },
                        '&:hover': {
                            color: theme.colors.gray[9],
                            backgroundColor: theme.colors.yellow[3],
                        },
                    },
                })
            },
        },
        Tooltip: {
            variants: {
                whiteWBlackText: () => ({
                    tooltip: {
                        backgroundColor: 'white',
                        color: 'black',
                        padding: 6,
                        // border: '1px solid #8a91a2'
                    }
                })
            }
        },
        UnstyledButton: {
            variants: {
                header: (theme) => ({
                    root: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            backgroundColor: `${theme.colors.natural[7]} !important`,
                        },
                    },
                })
            },
        },
        Button: {
            styles: (theme) => ({
                root: {
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: theme.colors.natural[9],
                },
                label: {
                    textWrap: 'wrap',
                },
                '&[data-disabled]': {
                    opacity: 0.5,
                },
            }),
            variants: {
                primary: (theme) => ({
                    root: {
                        borderRadius: '4px',
                        backgroundColor: theme.colors.primary[3],
                        '&:hover': {
                            backgroundColor: theme.colors.primary[2],
                        },
                    },
                }),
                secondary: (theme) => ({
                    root: {
                        borderRadius: '4px',
                        backgroundColor: theme.colors.secondary,
                        '&:hover': {
                            backgroundColor: theme.colors.primary[2],
                        },
                    },
                }),
                secondaryBlue: (theme) => ({
                    root: {
                        borderRadius: '4px',
                        backgroundColor: theme.colors.info[1],
                        '&:hover': {
                            backgroundColor: theme.colors.primary[2],
                        },
                    },
                    inner: {
                        justifyContent: 'flex-start',
                    },
                }),
                secondaryGray: (theme) =>({
                    root: {
                        backgroundColor: theme.colors.natural[1],
                        '&:hover': {
                            backgroundColor: theme.colors.natural[2],
                        },
                    },
                }),
                commentsGray: (theme) =>({
                    root: {
                        backgroundColor: '',
                        '&:hover': {
                            backgroundColor: theme.colors.natural[1],
                        },
                    },
                }),
                subtleUpload: () => ({
                    root: {
                        paddingLeft: 0,
                        paddingRight: 0,
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                    label: {
                        textWrap: 'nowrap !important'
                    }
                }),
                subtleBlue: () => ({
                    root: {
                        paddingLeft: 0,
                        color: '#3656be !important',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                }),
                yellow: (theme) => ({
                    root: {
                        backgroundColor: theme.colors.yellow[4],
                        '&:hover': {
                            backgroundColor: theme.colors.yellow[5],
                        },
                    },
                }),
                danger: (theme) => ({
                    root: {
                        borderRadius: '10px',
                        width: '100%',
                        backgroundColor: theme.colors.danger[1],
                    },
                    inner: {
                        justifyContent: 'center',
                        color: theme.colors.natural[0],
                    },
                }),
                mobileInput: () => ({
                    root: {
                        paddingLeft: '0.1rem',
                        paddingRight: '0.1rem',
                    }
                }),
                grayBlue: (theme) => ({
                    root: {
                        height: 36,
                        borderRadius: 4,
                        backgroundColor: theme.colors.custom[0],
                        color: `${theme.colors.info[5]} !important`,
                        border: `1px solid ${theme.colors.custom[0]}`,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                }),
                planner: (theme) => ({
                    root: {
                        borderRadius: '4px',
                        backgroundColor: theme.colors.custom[0],
                        '&:hover': {
                            backgroundColor: theme.colors.primary[2],
                        },
                    },
                }),
            },
        },
        MonthPickerInput: {
            variants: {
                blue: (theme) => ({
                    input: {
                        backgroundColor: theme.colors.blue[0],
                        padding: 8,
                        color: theme.colors.blue[5],
                        border: `solid 1px ${theme.colors.blue[1]}`,
                        borderRadius: 15,
                        '&:hover': {
                            border: `solid 1px ${theme.colors.blue[1]}`,
                            backgroundColor: theme.colors.gray[0],
                        },
                        '&:active': {
                            border: `solid 1px ${theme.colors.yellow[1]}`,
                        },

                    },
                }),
                custom: (theme) => ({
                    input: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 36,
                        borderColor: theme.colors.custom[0],
                        textTransform: 'capitalize',
                        color: theme.colors.natural[6],
                        backgroundColor: theme.colors.custom[0],
                        paddingRight: 16,
                        '&:hover': {
                            borderColor: theme.colors.custom[0],
                            backgroundColor: theme.colors.natural[0]
                        },
                        '&:focus': {
                            borderColor: theme.colors.custom[0]
                        },
                        '&[data-with-icon]': {
                            paddingLeft: 28
                        },
                        '&:disabled': {
                            borderColor: theme.colors.custom[0],
                            color: theme.colors.natural[6],
                            backgroundColor: theme.colors.custom[0],
                            opacity: 1
                        },
                    },
                    icon: {
                        paddingBottom: 1
                    }
                })
            }
        },
        DatePickerInput: {
            variants: {
                blue: (theme) => ({
                    input: {
                        backgroundColor: theme.colors.blue[0],
                        padding: 8,
                        color: theme.colors.blue[5],
                        border: `solid 1px ${theme.colors.blue[1]}`,
                        borderRadius: 15,
                        '&:hover': {
                            border: `solid 1px ${theme.colors.blue[1]}`,
                            backgroundColor: theme.colors.gray[0],
                        },
                        '&:active': {
                            border: `solid 1px ${theme.colors.yellow[1]}`,
                        },

                    },
                }),
                custom: (theme) => ({
                    input: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 36,
                        borderColor: theme.colors.custom[0],
                        textTransform: 'capitalize',
                        color: theme.colors.natural[6],
                        backgroundColor: theme.colors.custom[0],
                        paddingRight: 16,
                        '&:hover': {
                            borderColor: theme.colors.custom[0],
                            backgroundColor: theme.colors.natural[0]
                        },
                        '&:focus': {
                            borderColor: theme.colors.custom[0]
                        },
                        '&[data-with-icon]': {
                            paddingLeft: 28
                        }
                    },
                    icon: {
                        paddingBottom: 2
                    }
                })
            }
        },
        RingProgress: {
            styles: {
                root: {
                    paddingRight: '52px',
                },
                label: {
                    // top: '100px',
                },
            },
        },
        Switch: {
            variants: {
                secondary: (theme) => ({
                    track: {
                        backgroundColor: `${theme.colors.custom[1]} !important`
                    },
                    thumb: {
                        backgroundColor: `${theme.colors.natural[3]} !important`
                    }
                })
            }
        }

    },
    colors: mantineColors,
    fontSizes: {
        xs: '12px',
        sm: '14px',
        md: '14px',
        lg: '16px',
        xl: '16px',
    },
    headings: {
        sizes: {
            h1: {
                fontSize: '40px',
                lineHeight: '120px',
            },
            h2: {
                fontSize: '32px',
                lineHeight: '96px',
            },
            h3: {
                fontSize: '24px',
                lineHeight: '32px',
                fontWeight: 500,
            },
            h4: {
                fontSize: '20px',
                lineHeight: '24px',
                fontWeight: 400,
            },
        },
    },
    globalStyles: (theme) => ({
        '*, *::before, *::after': {
            boxSizing: 'border-box',
            padding: '0',
            margin: '0',
        },
        'a': {
            fontWeight: 500,
            textDecoration: 'none',
            color: 'inherit',

            '&:hover': {
                color: theme.colors.info[4],
            },
        },
    }),
}
