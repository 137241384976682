

import { autocompleteChangeArg, autocompleteFieldDefault } from 'utils/inputs'
import {
    ObjectOptionType,
    RoomBodyNumberFieldKey,
    RoomFormBody,
    RoomFormHeader,
    RoomsAutocompleteKey,
    RoomsFormGlobal
} from './Rooms.types'
import {  InputChangeArg, InputSubmitArg } from 'types/inputs'
import { FindRoomResponseDto, UpdateRoomRequestDto } from 'store/api/orderRoom.tsx'

// *
// Data convert functions
// *

export const convertRoomDtoToFormBody = (room: FindRoomResponseDto): RoomFormBody => ({
    deleted: room?.deleted || roomFormBodyInitialState.deleted,
    square: room?.square || roomFormBodyInitialState.square,
    files: room?.files || roomFormBodyInitialState.files,
    object: room?.object && { name: room.object.name, data: { id: room.object.id, name: room.object.name } } || roomFormBodyInitialState.object,
})
export const convertRoomDtoToFormHeader = (room: FindRoomResponseDto): RoomFormHeader => ({
    name: room?.name || roomFormHeaderInitialState.name,
})
export const convertRoomFormToRequestDto = (
    data: Partial<RoomFormBody & RoomFormHeader>
): UpdateRoomRequestDto => ({
    name: data?.name || roomFormHeaderInitialState.name,
    square: data?.square || roomFormBodyInitialState.square,
    filesIds: data?.files && data.files.map(file => file.id) || roomFormBodyInitialState.files && roomFormBodyInitialState.files.map(file => file.id),
    objectId: data?.object?.data?.id || data?.object?.name || roomFormBodyInitialState.object.data?.id,
    deleted: data?.deleted || roomFormBodyInitialState.deleted,
})


// *
// STORE
// *

export const roomsFormGlobalDefaultValues: RoomsFormGlobal = {
    name: '',
    object: autocompleteFieldDefault,
    files: [],
    square: 0,
    deleted: false,
}
export const roomFormHeaderInitialState: RoomFormHeader = {
    name: '',
}
export const roomFormBodyInitialState: RoomFormBody = {
    object: autocompleteFieldDefault,
    files: [],
    square: 0,
    deleted: false,
}

// *
// Input processing functions
// *


export type AutocompleteObjectsSubmitData = {
    value: string
    label: string
}

export const autocompleteObjectsSubmitArg = (data: AutocompleteObjectsSubmitData) => ({
    name: data.label,
    data: { id: data.value, name: data.label },
})

export const onRoomsGlobalAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<RoomsAutocompleteKey, RoomsFormGlobal>) =>
    (data: AutocompleteObjectsSubmitData) => {
        setFormValue(key, autocompleteObjectsSubmitArg(data))
    }
export const onRoomsGlobalAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<RoomsAutocompleteKey, RoomsFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.data?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }

export const onRoomBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<RoomsAutocompleteKey, RoomFormBody>) =>
    (data: AutocompleteObjectsSubmitData) => {
        setFormValue(key, autocompleteObjectsSubmitArg(data))
    }
export const onRoomBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<RoomsAutocompleteKey, RoomFormBody>) =>
    (label: string) => {
        if (formData[key]?.data?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
export const onRoomBodyNumberInputChange = ({ formData, key, setFormValue }: InputChangeArg<RoomBodyNumberFieldKey, RoomFormBody>) =>
    (label: string) => {
        const numLabel = +label
        if (!isNaN(numLabel) && formData[key] !== numLabel) {
            setFormValue(key, numLabel)
        }
    }

export  const optionsFilterObjects =  (value: string, item: ObjectOptionType): boolean => item.label?.toLowerCase().includes(value.toLowerCase().trim())















