import { FindTaskResponseDto, UpdateTaskRequestDto } from 'store/api/tasks'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'

import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs.tsx'
import {
    EditTaskStepDtoCustom,
    FindTaskResponseDtoCustom,
    StepFormHeader,
    TaskFormBody,
    TaskFormHeader,
    TasksAutocompleteKey,
    TasksFormGlobal,
    TasksPercentNumberKey,
    TaskTabs,
    TaskTabsPath
} from './Tasks.types'
import { FindTaskSnippetResponseDto } from 'store/api/taskSnippets'
import { CustomStepSnippet } from './PageBody/Steps/StepSnippets/StepSnippets.types'


// *
// DATA CONVERT FUNCS
// *

export const convertTaskStepDtoToFormBody = (step: EditTaskStepDtoCustom): EditTaskStepDtoCustom => ({
    id: step.id,
    name: step.name,
    snippets: step.snippets,
    taskId: step.taskId,
    toValidate: step.toValidate,
    sort: step.sort,
})

export const convertTaskDtoToFormBody = (task: FindTaskResponseDtoCustom): TaskFormBody => ({
    previous: task?.previous && { name: task.previous?.name, data: { name: task.previous?.name, id: task.previous?.id } } || taskFormBodyInitialState.previous,
    workTime: task?.workTime || taskFormBodyInitialState.workTime,
    // specialization: task?.specialization && { name: task.specialization?.name, data: { name: task.specialization?.name, id: task.specialization?.id } } || taskFormBodyInitialState.specialization,
    job: task?.job && { name: task.job?.name, data: { name: task.job?.name, id: task.job?.id } } || taskFormBodyInitialState.job,
    steps: task?.steps || taskFormBodyInitialState.steps,
    links: task?.links || taskFormBodyInitialState.links,
    files: task?.files || taskFormBodyInitialState.files,
    documents: task?.documents || taskFormBodyInitialState.documents,
    copiedTaskId: task.copiedTaskId || taskFormBodyInitialState.copiedTaskId,
    copiedMaterialsIds: task.copiedMaterialsIds || taskFormBodyInitialState.copiedMaterialsIds,
    description: task?.description || taskFormBodyInitialState.description,
})
export const convertTaskDtoToFormHeader = (task: FindTaskResponseDto): TaskFormHeader => ({
    name: task?.name || taskFormHeaderInitialState.name,
})
export const convertTaskFormToRequestDto = (
    data: Partial<TasksFormGlobal>
): UpdateTaskRequestDto => ({
    name: data?.name || taskFormHeaderInitialState.name,
    workTime: data?.workTime ? data?.workTime : 0,
    description: data?.description || taskFormBodyInitialState.description,
    previousId:
            data?.previous?.data?.id ||
            data?.previous?.name ||
            taskFormBodyInitialState.previous.data?.id,
    // specializationId:
    //         data?.specialization?.data?.id ||
    //         data?.specialization?.name ||
    //         taskFormBodyInitialState.specialization.data?.id,
    jobId:
            data?.job?.data?.id ||
            data?.job?.name ||
            taskFormBodyInitialState.job.data?.id,
})

export const convertSnippetDtoToFormBody = ({ snippet, stepId }: { snippet: FindTaskSnippetResponseDto, stepId: string }): CustomStepSnippet => ({
    ...snippet,
    stepId,
})

// *
// STORE
// *

export const tasksFormGlobalDefaultValues: TasksFormGlobal = {
    name: '',
    previous: autocompleteFieldDefault,
    // specialization: autocompleteFieldDefault,
    job: autocompleteFieldDefault,
    workTime: 0,
    steps: [],
    notSavedNewSteps: [],
    copiedTaskId: null,
    copiedMaterialsIds: [],
    description: '',
}
export const taskFormHeaderInitialState: TaskFormHeader = {
    name: '',
}
export const taskFormBodyInitialState: TaskFormBody = {
    previous: autocompleteFieldDefault,
    // specialization: autocompleteFieldDefault,
    job: autocompleteFieldDefault,
    workTime: 0,
    steps: [],
    notSavedNewSteps: [],
    copiedTaskId: null,
    copiedMaterialsIds: [],
    description: '',
    documents: []
}

export const stepFormHeaderInitialState: StepFormHeader = {
    name: '',
    toValidate: false,
}


// *
// TABS FUNCS
// *

export const taskTabs: TaskTabs = {
    params: 'Параметры задачи',
    materials: 'Материалы',
    info: 'Общая информация',
    steps: 'Шаги',
}
export const getTaskActiveTab = (pathname: string): TaskTabsPath => pathname.includes('/params')
    ? 'params'
    :pathname.includes('/info')
        ? 'info'
        :pathname.includes('/materials')
            ? 'materials'
            :pathname.includes('/steps')
                ? 'steps'
                :'params'

// *
// INPUT PROCESSING FUNCS
// *

// global filter funcs
export const onTaskGlobalAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<TasksAutocompleteKey, TasksFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onTaskGlobalAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<TasksAutocompleteKey, TasksFormGlobal>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

// edit form funcs
export const onTaskBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<TasksAutocompleteKey, TaskFormBody>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onTaskBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<TasksAutocompleteKey, TaskFormBody>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
export const onTaskBodyPercentChange = ({ formData, key, setFormValue }: InputChangeArg<TasksPercentNumberKey, TaskFormBody>) => (label: number) => {
    if (formData[key]!==label) {
        setFormValue(key, label)
    }
}


// *
// PARAMS (FORMULAS)
// *
