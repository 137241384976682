import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['ProjectTasks'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            projectTasksFind: build.query<
                ProjectTasksFindApiResponse,
                ProjectTasksFindApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/task',
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ProjectTasks'],
            }),
            projectTaskCreate: build.mutation<
                ProjectTaskCreateApiResponse,
                ProjectTaskCreateApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/task',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectTasks'],
            }),
            projectTasksFindByProjectId: build.query<
                ProjectTasksFindByProjectIdApiResponse,
                ProjectTasksFindByProjectIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task/project/${queryArg.projectId}`,
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ProjectTasks'],
            }),
            projectTasksTreeFindByJobId: build.query<
                ProjectTasksTreeFindByJobIdApiResponse,
                ProjectTasksTreeFindByJobIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task/work/${queryArg}/tree`,
                }),
                providesTags: ['ProjectTasks'],
            }),
            projectTasksFindOne: build.query<
                ProjectTasksFindOneApiResponse,
                ProjectTasksFindOneApiArg
            >({
                query: (queryArg) => ({ url: `/api/v1/projects/task/${queryArg}` }),
                providesTags: ['ProjectTasks'],
            }),
            projectTaskUpdate: build.mutation<
                ProjectTaskUpdateApiResponse,
                ProjectTaskUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.updateProjectTaskRequestDto,
                }),
                invalidatesTags: ['ProjectTasks'],
            }),
            projectTaskRemove: build.mutation<
                ProjectTaskRemoveApiResponse,
                ProjectTaskRemoveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task/${queryArg}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ProjectTasks'],
            }),
            projectTaskProblemCreate: build.mutation<
                ProjectTaskProblemCreateApiResponse,
                ProjectTaskProblemCreateApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/task/problem',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectTasks'],
            }),
            projectTaskCreateCustom: build.mutation<
                ProjectTaskCreateCustomApiResponse,
                ProjectTaskCreateCustomApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/task/custom',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectTasks'],
            }),
            projectTaskCalculateCustom: build.mutation<
                ProjectTaskCalculateCustomApiResponse,
                ProjectTaskCalculateCustomApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/task/calculate-custom',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectTasks'],
            }),
            projectTaskProblemUpdate: build.mutation<
                ProjectTaskProblemUpdateApiResponse,
                ProjectTaskProblemUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task/solve-problem/comment/${queryArg}`,
                    method: 'PUT',
                }),
                invalidatesTags: ['ProjectTasks'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as projectTasksApi };
export type ProjectTasksFindApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindProjectTaskResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type ProjectTasksFindApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ProjectTaskCreateApiResponse =
  /** status 201  */ FindProjectTaskResponseDto;
export type ProjectTaskCreateApiArg = CreateProjectTaskRequestDto;
export type ProjectTasksFindByProjectIdApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindProjectTaskResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type ProjectTasksFindByProjectIdApiArg = {
    projectId: string;
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ProjectTasksTreeFindByJobIdApiResponse = /** status 200  */ {
    meta?: ResponseMetadata;
    data?: FindProjectTaskResponseDto[][];
};
export type ProjectTasksTreeFindByJobIdApiArg = string;
export type ProjectTasksFindOneApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindProjectTaskResponseDto;
};
export type ProjectTasksFindOneApiArg = string;
export type ProjectTaskUpdateApiResponse =
  /** status 200  */ FindProjectTaskResponseDto;
export type ProjectTaskUpdateApiArg = {
    id: string;
    updateProjectTaskRequestDto: UpdateProjectTaskRequestDto;
};
export type ProjectTaskRemoveApiResponse = /** status 200  */ void;
export type ProjectTaskRemoveApiArg = string;
export type ProjectTaskProblemCreateApiResponse = /** status 200  */ void;
export type ProjectTaskProblemCreateApiArg = CreateProjectTaskProblemRequestDto;
export type ProjectTaskCreateCustomApiResponse =
  /** status 201  */ FindWorkResponseDto;
export type ProjectTaskCreateCustomApiArg = CreateProjectCustomTaskRequestDto;
export type ProjectTaskCalculateCustomApiResponse =
  /** status 200  */ FindProjectTaskCalculateCustomResponseDto;
export type ProjectTaskCalculateCustomApiArg =
  CalculateProjectCustomTaskRequestDto;
export type ProjectTaskProblemUpdateApiResponse = /** status 200  */ void;
export type ProjectTaskProblemUpdateApiArg = string;
export type FindProjectTaskStatusResponseDto = {
    id: string;
    name: string;
    description: string;
    type:
    | 'PREPARATION'
    | 'WAIT'
    | 'DONE'
    | 'PROBLEM'
    | 'CONTROL'
    | 'REVISION'
    | 'PAID';
};
export type FindWorkPartResponseDto = {
    from: string;
    to: string;
    id: string;
    size: number;
    row?: number;
    isResized?: boolean | null;
    isMoved?: number | null;
};
export type JobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindMaterialTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    jobType: JobTypeResponseDto;
};
export type FindJobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    materialType: FindMaterialTypeResponseDto;
};
export type FindPackageResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindJobViewResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindWorkStageResponseDto = {
    id: string;
    name: string;
    description: string;
    sort: number;
};
export type FindJobSurfaceResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindClientKindResponseDto = {
    id: string;
    name: string;
};
export type FindClientTaxationSystemResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindDepartmentResponseDto = {
    id: string;
    name: string;
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindRolePermissionDto = {
    id: string;
    name: string;
    description: string;
    allow: boolean;
};
export type FindRolePermissionGroupDto = {
    name: string;
    permissions: FindRolePermissionDto[];
};
export type FindRoleResponseDto = {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
    type: 'MANAGER' | 'WORKER';
    organization: FindOrganizationResponseDto;
    permissions: FindRolePermissionGroupDto[];
};
export type FindUserResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    email: string;
    phone: string | null;
    job: string | null;
    chatId: number | null;
    deleted: boolean;
    compensationPercent: number;
    compensationPercentUpdatedAt: string | null;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    department: FindDepartmentResponseDto;
    specializations: FindSpecializationResponseDto[];
    avatar: FindFileResponseDto;
    roles: FindRoleResponseDto[];
    organizations: FindOrganizationResponseDto[];
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    workingDays: (number[][] | (string | null))[];
};
export type FindClientContactResponseDto = {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    position: string;
    phone: string | null;
    email: string | null;
    website: string | null;
    company: string | null;
    note: string | null;
};
export type FindClientBankResponseDto = {
    id: string;
    name: string;
    abbreviatedName: string;
    bik: string;
    ks?: string | null;
    index?: string | null;
    city?: string | null;
    address?: string | null;
    phone?: string | null;
    okato?: string | null;
    okpo?: string | null;
    regNum?: string | null;
};
export type FindAccountItemResponseDto = {
    id: string;
    number: string;
    bank: FindClientBankResponseDto;
};
export type FindClientTypeResponseDto = {
    id: string;
    name: string;
};
export type FindClientResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    name: string;
    middleName: string | null;
    registrationAddress: string | null;
    birthDate: string | null;
    passportNumber: number | null;
    passportIssuer: string | null;
    passportIssueDate: string | null;
    passportIssuingUnitCode: string | null;
    fullName: string | null;
    abbreviatedName: string | null;
    legalAddress: string | null;
    inn: string;
    kpp: string | null;
    ogrn: string | null;
    okved: string | null;
    okpo: string | null;
    okato: string | null;
    deleted: boolean;
    kind: FindClientKindResponseDto;
    taxationSystem: FindClientTaxationSystemResponseDto;
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    contact: FindClientContactResponseDto;
    account: FindAccountItemResponseDto;
    types: FindClientTypeResponseDto[];
    organization: FindOrganizationResponseDto;
    contacts: FindClientContactResponseDto[];
    accounts: FindAccountItemResponseDto[];
};
export type FindObjectResponseDto = {
    id: string;
    name: string;
    deleted: boolean;
    address: string;
    fullAddress: string;
    city: string;
    street: string;
    corpus: string;
    building: string;
    house: string;
    entrance: number;
    floor: number;
    apartment: number;
    intercomCode: string;
    comment: string;
    longitude: number;
    latitude: number;
    client: FindClientResponseDto;
};
export type FindRoomResponseDto = {
    id: string;
    name: string;
    square: number;
    deleted: boolean;
    object: FindObjectResponseDto;
    files: FindFileResponseDto[];
};
export type FormulaCalculateRequestDto = {
    id: string;
    name: string;
    colour: string;
    operator?: string | null;
    type?:
    | (
        | 'PLUS'
        | 'MINUS'
        | 'MULTIPLY'
        | 'DIVIDE'
        | 'MATH_FLOOR'
        | 'MATH_CEIL'
        | 'MATH_ROUND'
        | 'MATH_SQRT'
        | 'NUMBER_SQUARED'
        | 'NUMBER_POWER'
        | 'CONDITION'
        | 'LEFT_PARENTHESIS'
        | 'RIGHT_PARENTHESIS'
        | 'MORE'
        | 'LESS'
      )
    | null;
    value?:
    | (
        | number
        | FormulaCalculateRequestDto
        | (number | FormulaCalculateRequestDto)[]
        | (number | FormulaCalculateRequestDto)[][]
      )
    | null;
};
export type WorkFormulaCalculateRequestDto = {
    id: string;
    name: string;
    colour: string;
    operator?: string | null;
    type?:
    | (
        | 'PLUS'
        | 'MINUS'
        | 'MULTIPLY'
        | 'DIVIDE'
        | 'MATH_FLOOR'
        | 'MATH_CEIL'
        | 'MATH_ROUND'
        | 'MATH_SQRT'
        | 'NUMBER_SQUARED'
        | 'NUMBER_POWER'
        | 'CONDITION'
        | 'LEFT_PARENTHESIS'
        | 'RIGHT_PARENTHESIS'
        | 'MORE'
        | 'LESS'
      )
    | null;
    value?:
    | (
        | number
        | FormulaCalculateRequestDto
        | (number | FormulaCalculateRequestDto)[]
        | (number | FormulaCalculateRequestDto)[][]
      )
    | null;
};
export type FindFormulaTypeResponseDto = {
    id: string;
    name: string;
    colour: string;
    type:
    | 'CONSTANT'
    | 'MATERIAL_PARAMETER'
    | 'MATERIAL_COUNT'
    | 'SPECIAL'
    | 'OPERATION'
    | 'LEGKOREM';
    description: string;
};
export type FindMaterialStocksResponseDto = {
    id: string;
    title: string;
    remainsAmount: number;
};
export type FindCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
};
export type FindStoreResponseDto = {
    id: string;
    name: string;
    url: string | null;
};
export type FindStoreCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
    store: FindStoreResponseDto;
};
export type FindBrandResponseDto = {
    id: string;
    name: string;
    url: string | null;
    image: FindFileResponseDto | null;
};
export type FindInventoryTypeResponseDto = {
    id: string;
    name: string;
    type: 'MATERIAL' | 'PRODUCT';
};
export type FindMaterialPackResponseDto = {
    id: string;
    packing: number;
    unitMeasure: string;
    price: number;
    tradePrice: number;
    default: boolean;
    proportion: number;
};
export type FindMaterialResponseDto = {
    id: string;
    updatedAt: string;
    sku: string | null;
    name: string;
    description: string;
    url: string;
    price: number;
    discountPrice: number | null;
    isChecked: boolean;
    isAvailable: boolean;
    images: string[];
    isAccountable: boolean;
    stocks: FindMaterialStocksResponseDto[];
    category: FindCategoryResponseDto;
    storeCategory: FindStoreCategoryResponseDto;
    store: FindStoreResponseDto;
    brand: FindBrandResponseDto;
    type: FindMaterialTypeResponseDto;
    package: FindPackageResponseDto;
    inventoryType: FindInventoryTypeResponseDto;
    packs: FindMaterialPackResponseDto[];
};
export type FindUnitsResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindWorkFormulaResponseDto = {
    id: string;
    name: string;
    value: (WorkFormulaCalculateRequestDto | number)[];
    type: FindFormulaTypeResponseDto;
    work?: FindWorkResponseDto;
    material: FindMaterialResponseDto;
    units: FindUnitsResponseDto;
};
export type FindProjectStatusResponseDto = {
    id: string;
    name: string;
    description: string;
    order: number;
    type:
    | 'PRESALE'
    | 'PLANNING'
    | 'REALIZATION'
    | 'PAUSE'
    | 'DELIVERY'
    | 'STOPPED'
    | 'WARRANTY'
    | 'DONE';
};
export type FindGroupResponseDto = {
    id: string;
    name: string;
};
export type FindProjectResponsibleResponseDto = {
    id: string;
    type: 'MANAGER' | 'FOREMAN';
    user: FindUserResponseDto;
    userGroup: FindGroupResponseDto;
};
export type FindProjectResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    from: string;
    to: string;
    workingHours: number[][];
    overhead: number;
    transportCosts: number;
    emergencyPhoneNumber: string;
    status: FindProjectStatusResponseDto;
    client: FindClientResponseDto;
    organization: FindOrganizationResponseDto;
    object: FindObjectResponseDto;
    responsibles: FindProjectResponsibleResponseDto[];
    images: FindFileResponseDto[];
};
export type FindJobStageResponseDto = {
    id: string;
    name: string;
    description: string;
    sort: number;
};
export type FindJobResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    insideDescription?: string | null;
    clientDescription?: string | null;
    materialsCount: number;
    isNoisiness: boolean;
    isInvisible: boolean;
    type: FindJobTypeResponseDto;
    package?: FindPackageResponseDto | null;
    view?: FindJobViewResponseDto | null;
    stage: FindJobStageResponseDto;
    surfaces?: FindJobSurfaceResponseDto[] | null;
    specializations: FindSpecializationResponseDto[];
    organization: FindOrganizationResponseDto;
    groups: FindJobGroupResponseDto[];
};
export type FindJobGroupResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    jobs: FindJobResponseDto[];
};
export type FindWorkResponseDto = {
    id: string;
    jobId?: string | null;
    name: string;
    insideDescription: string;
    clientDescription: string;
    materialsCount: number;
    isNoisiness: boolean;
    isInvisible: boolean;
    duration: number;
    from?: string | null;
    to?: string | null;
    fact?: string | null;
    onControl?: string | null;
    factLag?: number | null;
    model?: ('RESOURCE' | 'CUSTOM') | null;
    status?:
    | (
        | 'PREPARATION'
        | 'WAIT'
        | 'DONE'
        | 'PROBLEM'
        | 'CONTROL'
        | 'REVISION'
        | 'PAID'
      )
    | null;
    money?: number | null;
    lag?: number | null;
    workingHours: number[][];
    parts?: FindWorkPartResponseDto[] | null;
    type: FindJobTypeResponseDto;
    package: FindPackageResponseDto;
    view: FindJobViewResponseDto;
    stage: FindWorkStageResponseDto;
    surfaces: FindJobSurfaceResponseDto[];
    organization: FindOrganizationResponseDto;
    room: FindRoomResponseDto;
    formulas: FindWorkFormulaResponseDto[];
    tasks: FindProjectTaskResponseDto[];
    specializations: FindSpecializationResponseDto[];
    performer: FindUserResponseDto | null;
    performers: FindUserResponseDto[];
    project: FindProjectResponseDto;
    jobGroup: FindJobGroupResponseDto;
};
export type FindProjectTaskMaterialResponseDto = {
    id: string;
    price: number;
    discountPrice?: number | null;
    material: FindMaterialResponseDto;
};
export type FindProjectTaskResponseDto = {
    id: string;
    updatedAt: string;
    name: string;
    description: string;
    workTime: number;
    status: FindProjectTaskStatusResponseDto;
    work?: FindWorkResponseDto | null;
    taskMaterials: FindProjectTaskMaterialResponseDto[];
    previous?: FindProjectTaskResponseDto | null;
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type CreateProjectTaskRequestDto = {
    /** Название задачи */
    name: string;
    /** Описание задачи */
    description?: string | null;
    /** Время от работы в задаче */
    workTime: number;
    /** Идентификатор предыдущей задачи */
    previousId?: string | null;
    /** Идентификатор работы */
    workId?: string | null;
};
export type ResponseMetadata = {
    /** Идентификатор данных */
    id: string;
    /** Права доступа */
    permissions: boolean;
};
export type UpdateProjectTaskRequestDto = {
    /** Название задачи */
    name: string;
    /** Описание задачи */
    description?: string | null;
    /** Время от работы в задаче */
    workTime: number;
    /** Идентификатор предыдущей задачи */
    previousId?: string | null;
    /** Идентификатор работы */
    workId?: string | null;
};
export type CreateProjectTaskProblemRequestDto = {
    /** Идентификатор задачи */
    taskId: string;
    /** Комментарий к проблеме */
    comment: string;
};
export type CreateProjectCustomTaskRequestDto = {
    /** Название задачи */
    name: string;
    /** Описание задачи */
    description: string;
    /** Начало работы */
    from: string;
    /** Тариф штат */
    tariffStaff: number;
    /** Объем */
    volume: number;
    /** Еденицы измерения объема */
    unitsId: string;
    /** Файлы */
    fileIds?: string[] | null;
    /** Идентификатор проекта */
    projectId: string;
    /** Идентификатор помещения */
    roomId: string;
    /** Идентификатор этапа */
    stageId: string;
    /** Идентификатор ответственного работы */
    performerId?: string | null;
    /** Идентификаторы соисполнителей работы */
    performersIds?: string[];
};
export type FindProjectTaskCalculateCustomResponseDto = {
    costStaff: number;
    costIP: number;
    costCash: number;
};
export type CalculateProjectCustomTaskRequestDto = {
    /** Тариф штат */
    tariffStaff: number;
    /** Объем */
    volume: number;
};
export const {
    useProjectTasksFindQuery,
    useLazyProjectTasksFindQuery,
    useProjectTaskCreateMutation,
    useProjectTasksFindByProjectIdQuery,
    useLazyProjectTasksFindByProjectIdQuery,
    useProjectTasksTreeFindByJobIdQuery,
    useLazyProjectTasksTreeFindByJobIdQuery,
    useProjectTasksFindOneQuery,
    useLazyProjectTasksFindOneQuery,
    useProjectTaskUpdateMutation,
    useProjectTaskRemoveMutation,
    useProjectTaskProblemCreateMutation,
    useProjectTaskCreateCustomMutation,
    useProjectTaskCalculateCustomMutation,
    useProjectTaskProblemUpdateMutation,
} = injectedRtkApi;
