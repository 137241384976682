import { Button, Flex, Modal, Title } from '@mantine/core';
import {
    useForm, useWatch,
} from 'react-hook-form';
import { userValidation } from 'utils/validation.ts';
import { TextInput } from 'react-hook-form-mantine';
import { ChangePasswordFormBody } from 'components/AbstractComponents/ModalChangePassword/ModalChangePassword.types.tsx';
import {
    changePasswordFormBodyInitialState
} from 'components/AbstractComponents/ModalChangePassword/ModalChangePasword.utils.ts';
import { useDebounce } from 'hooks/useDebounce.ts';
import { UserFormBody } from 'components/Organizations/NestedPages/Users/Users.types.ts';
import { useAppDispatch } from 'hooks/redux.ts';
import { notificationsSlice } from 'store/slices/notificationsSlice.ts';
import { useUserUpdatePasswordMutation } from 'store/api/user';

interface ModalHelpProps {
    opened: boolean,
    close: () => void,
}

export const ModalChangePassword = ({ opened, close }: ModalHelpProps) => {

    const dispatch = useAppDispatch()

    const { setApiError } = notificationsSlice.actions
    
    const {
        control,
        formState: { errors: formErrors },
        reset,
    } = useForm<ChangePasswordFormBody>({
        defaultValues: changePasswordFormBodyInitialState,
        mode: 'onChange',
    })

    const [
        updatePassword,
        {
            isLoading: isUpdatePasswordLoading,
        },
    ] = useUserUpdatePasswordMutation()

    const formData = useDebounce(useWatch({ control }), 500) as Partial<UserFormBody>

    const handleClose = () => {
        reset()
        close()
    }

    const handleSubmit = () => {

        if(!formData.password || formErrors.password) {
            dispatch(setApiError({
                error: 'Bad Request',
                message: ['Некорректный пароль'],
                status: 400
            }))
            return
        }

        if(formData.password !== formData.repeatPassword) {
            dispatch(setApiError({
                error: 'Bad Request',
                message: ['Пароли не совпадают'],
                status: 400
            }))
            return
        }

        if(!formData.repeatPassword || formErrors.repeatPassword) {
            dispatch(setApiError({
                error: 'Bad Request',
                message: ['Некорректный пароль'],
                status: 400
            }))
            return
        }

        updatePassword({
            password: formData.password
        })
            .unwrap()
            .then(() => {
                handleClose()
            })
            .catch((err) => dispatch(setApiError(err.data)))
    }

    return <Modal.Root opened={opened} onClose={close} centered size={550}>
        <Modal.Overlay/>
        <Modal.Content>
            <Modal.Body>
                <Flex direction={'column'} gap={32}>
                    <Flex direction={'column'} gap={24}>
                        <Title order={3}>Сменить пароль</Title>
                        <TextInput
                            withAsterisk
                            name="password"
                            variant="page"
                            control={ control }
                            label="Пароль"
                            rules={ {
                                pattern: userValidation?.password?.pattern,
                                required: true,
                            } }
                            error={
                                formErrors.password && userValidation.password?.message
                            }
                        />
                        <TextInput
                            withAsterisk
                            name="repeatPassword"
                            variant="page"
                            control={ control }
                            label="Повторите пароль"
                            rules={ {
                                pattern: userValidation?.password?.pattern,
                                required: true,
                            } }
                            error={
                                formData.password !== formData.repeatPassword && 'Пароли не совпадают'
                            }
                        />
                    </Flex>
                    <Flex justify={'space-between'} gap={24}>
                        <Button variant={'secondaryGray'} radius={8} onClick={handleClose}>Отменить</Button>
                        <Button variant={'yellow'} radius={8} onClick={handleSubmit} disabled={isUpdatePasswordLoading}>Сменить</Button>
                    </Flex>
                </Flex>
            </Modal.Body>
        </Modal.Content>
    </Modal.Root>
}
